import React, { memo } from "react";
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";
import DashboardCard from "./DashboardCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBriefcaseMedical } from "@fortawesome/free-solid-svg-icons";
import { BdoCard } from "./SourceProgess";

const DailyStatistics = memo(({ totalCases, totalHires, totalPI, totalRepair }) => {
  return (
    <Card>
      <CardBody>
        <CardTitle tag="h5">Daily Statistics</CardTitle>

        <Row>
          <Col sm="3">
            <BdoCard title="Cases" value={totalCases} />
          </Col>
          <Col sm="3">
            <BdoCard title="Personal Injury" value={totalPI} />
          </Col>
          <Col sm="3">
            <BdoCard title="Repair Cases" value={totalRepair} />
          </Col>
          <Col sm="3">
            <BdoCard title="Hire Cases" value={totalHires} />
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
});

export default DailyStatistics;
