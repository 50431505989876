export const validateEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

export const validateNumericInput = (input) => {
  const numericRegex = /^[0-9]+$/;
  return numericRegex.test(input);
};

export const validateDecimalInput = (input) => {
  const decimalRegex = /^[0-9]+(\.[0-9]+)?$/;
  return decimalRegex.test(input);
};

export function validateAlphaNumeric(input) {
  var alphanumericRegex = /^[a-zA-Z0-9\s]+$/;
  return alphanumericRegex.test(input);
}

export const validatePhoneNumber = (phoneNumber) => {
  return !isNaN(phoneNumber);
};
