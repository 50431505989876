import { toastr } from "react-redux-toastr";
import useLogout from "./useLogout";

const useError = () => {
  const { logout } = useLogout();

  const handleError = (error) => {
    if (error?.response?.status === 401) {
      toastr.error("Error", error?.response?.data?.error || "You need to login again");
      logout(); // if user unauthorize then loguout the user
    } else {
      toastr.error("Error", error?.response?.data?.error || "Something went wrong");
    }
  };

  return { handleError };
};

export default useError;
