import React, { memo, useState } from "react";
import { Card, CardBody, CardTitle, Col, Progress, Row } from "reactstrap";
// import MonthPicker from "../UI/forms/MonthPicker";
import ReactApexChart from "react-apexcharts";
import { Doughnut } from "react-chartjs-2";

// const MONTHS = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

// const MonthlyProgess = memo(({ title, value, total }) => {
//   return (
//     <div className="mt-4">
//       <div className="d-flex justify-content-between align-items-center">
//         <h6>{title}</h6>
//         <h4>{value}</h4>
//       </div>
//       <Progress color="primary" value={total} />
//     </div>
//   );
// });

const MonthlyProgessFeed = memo(({ pi, hire, repair, month, year }) => {
  const data = {
    labels: ["Personal Injury", "Repair Cases", "Hire Cases"],
    datasets: [
      {
        data: [pi, repair, hire],
        backgroundColor: ["#153d77", "#3B7DDD", "#E8EAED"],
        borderColor: "transparent",
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    legend: {
      position: "top",
      labels: {
        usePointStyle: true,
      },
    },
    tooltips: {
      intersect: false,
    },
  };

  return (
    <Card className="border">
      <CardBody>
        <CardTitle tag="h5">
          {month} {year} <span className="text-muted">Monthly Records</span>
        </CardTitle>

        <div className="mt-2">
          <Doughnut options={options} data={data} height={296} />
        </div>
      </CardBody>
    </Card>
  );
});

// const TopSourceItem = memo(({ title, value, pi, hire, repair }) => {
//   return (
//     <Card className="border">
//       <CardBody>
//         <Row>
//           <Col sm="6">
//             <h6>{title}</h6>
//             <h4>{value}</h4>
//           </Col>

//           <Col sm="6">
//             <Row>
//               <Col sm="4" className="border-right">
//                 <h6>PI</h6>
//                 <h4>{pi}</h4>
//               </Col>
//               <Col sm="4" className="border-right">
//                 <h6>Hire</h6>
//                 <h4>{hire}</h4>
//               </Col>
//               <Col sm="4">
//                 <h6>Repair</h6>
//                 <h4>{repair}</h4>
//               </Col>
//             </Row>
//           </Col>
//         </Row>
//       </CardBody>
//     </Card>
//   );
// });

const TopTenSources = memo(({ topTen, month, year }) => {
  const series = [
    {
      name: "PI",
      data: topTen?.map((item) => +item.total_pi) || [],
    },
    {
      name: "Hire",
      data: topTen?.map((item) => +item.total_hire) || [],
    },
    {
      name: "Repair",
      data: topTen?.map((item) => +item.total_repair) || [],
    },
  ];

  const options = {
    chart: {
      type: "bar",
      height: 200,
      stacked: true,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            position: "bottom",
            offsetX: -10,
            offsetY: 0,
          },
        },
      },
    ],
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 10,
        dataLabels: {
          total: {
            enabled: true,
            style: {
              fontSize: "13px",
              fontWeight: 900,
            },
          },
        },
      },
    },
    legend: {
      position: "top",
      offsetY: 40,
    },
    fill: {
      opacity: 1,
    },
    xaxis: {
      categories: topTen?.map((item) => item.source) || [],
    },
  };

  return (
    <Card className="border">
      <CardBody className="pb-0">
        <CardTitle tag="h5">
          Top 10 sources - {month} {year}
        </CardTitle>
        {/* <TopSourceItem title="Total Claims Solutions" value="3" pi="89" hire="58" repair="25" /> */}
        {/* <TopSourceItem title="admin" value="3" pi="89" hire="58" repair="25" /> */}

        <ReactApexChart options={options} series={series} type="bar" height={300} />
      </CardBody>
    </Card>
  );
});

const MonthlyStatistics = memo(({ pi, hire, repair, topTen, month, year }) => {
  return (
    <Card>
      <CardBody>
        <CardTitle tag="h5">Monthly Statistics</CardTitle>

        <Row>
          <Col sm="4">
            <MonthlyProgessFeed pi={pi} hire={hire} repair={repair} month={month} year={year} />
          </Col>
          <Col sm="8">
            <TopTenSources topTen={topTen} month={month} year={year} />
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
});

export default MonthlyStatistics;
