import React, { memo } from "react";

const EmailLink = memo(({ children }) => {
  return (
    <a href={`mailto:${children}`} className="custom-link">
      {children}
    </a>
  );
});

export default EmailLink;
