import React, { memo } from "react";
import { Line } from "react-chartjs-2";
import { connect } from "react-redux";

const LineChart = memo(({ theme, title, labels, data: _data }) => {
  const data = {
    labels,
    datasets: [
      {
        label: title,
        fill: true,
        backgroundColor: "transparent",
        borderColor: theme.primary,
        data: _data,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    tooltips: {
      intersect: false,
    },
    hover: {
      intersect: true,
    },
    plugins: {
      filler: {
        propagate: false,
      },
    },
    scales: {
      xAxes: [
        {
          reverse: true,
          gridLines: {
            color: "rgba(0,0,0,0.05)",
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            stepSize: 500,
          },
          display: true,
          borderDash: [5, 5],
          gridLines: {
            color: "rgba(0,0,0,0)",
            fontColor: "#fff",
          },
        },
      ],
    },
  };

  return (
    <div className="chart">
      <Line data={data} options={options} />
    </div>
  );
});

export default connect((store) => ({
  theme: store.theme.currentTheme,
}))(LineChart);
