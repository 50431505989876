import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { toastr } from "react-redux-toastr";
import CustomDate from "../../UI/forms/CustomDate";
import { useParams, Link } from "react-router-dom";
import { useRepairContext } from "../../../context/administration/updateForm/repair";
import useError from "../../../hooks/useError";
import { useUpdateFormContext } from "../../../context/administration/updateForm";
import { Col, Form, FormGroup, Input, Label, Row, Button } from "reactstrap";
import { AsyncPaginate } from "react-select-async-paginate";
import ReactSelect from "react-select";
import Notes from "./Notes";
import LoadingButton from "../../UI/LoadingButton";
import { toDateObj, toYYYYMMDD } from "../../../utils/forms";
import instance from "../../../axiosInstance";
import ParamsLink from "../../UI/links/ParamsLink";
import { validatePhoneNumber } from "../../../utils/validations";

const yesOrNoOptions = [
  { value: "Yes", label: "Yes" },
  { value: "No", label: "No" },
];

let loadedRepairStatus = [];
const loadRepairStatus = async (search) => {
  try {
    if (search && loadedRepairStatus.length) {
      const filteredOptions = loadedRepairStatus.filter((option) => option.label.toLowerCase().includes(search.toLowerCase()));
      return {
        options: filteredOptions,
        hasMore: false,
      };
    }

    const res = await instance.get("/lookups/repairStatus");
    const { data } = res.data;
    const options = data.map((status) => ({
      value: status,
      label: status,
    }));

    loadedRepairStatus = options;

    return {
      options,
      hasMore: false,
    };
  } catch (error) {
    return {
      options: [],
      hasMore: false,
    };
  }
};

export const loadRepairAuthorizeOptions = async (search, loadedOptions, { page }) => {
  try {
    const res = await instance.get("/lookups/authorizeUsers", {
      params: { search, page, limit: 10 },
    });

    const { data } = res.data;
    const options = data.data.map((role) => ({
      value: role.user_id,
      label: role.full_name,
    }));

    return {
      options,
      hasMore: page < data.total_pages,
      additional: {
        page: page + 1,
      },
    };
  } catch (error) {
    return {
      options: [],
      hasMore: false,
    };
  }
};

export const loadGarageOptions = async (search, loadedOptions, { page }) => {
  try {
    const res = await instance.get("/lookups/garages", {
      params: { search, page, limit: 10 },
    });

    const { data } = res.data;
    const options = data.data.map((role) => ({
      value: role.user_id,
      label: role.full_name,
    }));

    return {
      options,
      hasMore: page < data.total_pages,
      additional: {
        page: page + 1,
      },
    };
  } catch (error) {
    return {
      options: [],
      hasMore: false,
    };
  }
};

const loadLeadSourceOptions = async (search, loadedOptions, { page }) => {
  try {
    const res = await instance.get("/lookups/leadSources", {
      params: { search, page, limit: 10 },
    });

    const { data } = res.data;
    const options = data.data.map((role) => ({
      value: role.user_id,
      label: role.full_name,
    }));

    return {
      options,
      hasMore: page < data.total_pages,
      additional: {
        page: page + 1,
      },
    };
  } catch (error) {
    return {
      options: [],
      hasMore: false,
    };
  }
};

const Repair = () => {
  const { formId } = useParams();

  const {
    userType, 
    setUserType,
    authorizedBy,
    setAuthorizedBy,
    repairStatus,
    setRepairStatus,
    authorizeDate,
    setAuthorizeDate,
    garageName,
    setGarageName,
    repairRefNo,
    setRepairRefNo,
    repairStartDate,
    setRepairStartDate,
    repairCompletionDate,
    setRepairCompletionDate,
    sourceRepairerPaidDate,
    setSourceRepairerPaidDate,
    sourceRepairerPaidStatus,
    setSourceRepairerPaidStatus,
    sourceRepairerPaidAmount,
    setSourceRepairerPaidAmount,
    naslPaidDate,
    setNaslPaidDate,
    naslRepairerPaidStatus,
    setNaslRepairerPaidStatus,
    naslRepairPaidAmount,
    setNaslRepairPaidAmount,
    repairLeadSource,
    setRepairLeadSource,
    repairsAuthorize,
    setRepairsAuthorize,
    repairOutlay,
    setRepairOutlay,

    // -----note's values-------
    isAddNotActive,
    setIsAddNotActive,
    isAddAsReminderActive,
    setIsAddAsReminderActive,
    isSendEmailActive,
    setIsSendEmailActive,
    isSendSMSActive,
    setIsSendSMSActive,

    noteDescription,
    setNoteDescription,
    addNoteTo,
    setAddNoteTo,
    addAsReminderDate,
    setAddAsReminderDate,
    sendEmailTo,
    setSendEmailTo,
    sendSMSTo,
    setSendSMSTo,
    sendSMSMobile,
    setSendSMSMobile,
  } = useRepairContext();

  const [validationErrors, setValidationErrors] = useState({
    mobileNumber: "",
    clientNumber: "",
  });

  const clearNoteFields = () => {
    setIsAddNotActive(false);
    setIsAddAsReminderActive(false);
    setIsSendEmailActive(false);
    setIsSendSMSActive(false);
    setNoteDescription("");
    setAddNoteTo("");
    setAddAsReminderDate(null);
    setSendEmailTo("");
    setSendSMSTo("");
    setSendSMSMobile({
      clientNumber: "",
      mobileNumber: "",
    });
  };

  const { handleError } = useError();
  const [isUpdating, setIsUpdating] = useState(false);

  const { formData, getNotes } = useUpdateFormContext();

  const setFields = () => {
    if (!formData || !formData.hire) return;
    const { repair } = formData;
    const { user } = formData;

    setUserType(user.user_type)

    setRepairOutlay({ value: repair.outlay, label: repair.outlay });
    setAuthorizedBy({ value: repair.authorize_by_id, label: repair.authorize_by_name });
    setRepairStatus({ value: repair.authorize_status, label: repair.authorize_status });
    setAuthorizeDate(toDateObj(repair.authorize_date));
    setRepairsAuthorize(repair.authorize_status);
    setGarageName({ value: repair.garage_id, label: repair.garage_name });
    setRepairRefNo(repair.reference_no);
    setRepairStartDate(toDateObj(repair.start_date));
    setRepairCompletionDate(toDateObj(repair.completed_date));
    setSourceRepairerPaidAmount(repair.source_paid_amount);
    setSourceRepairerPaidDate(toDateObj(repair.source_paid_date));
    setSourceRepairerPaidStatus(repair.source_paid_status === "Yes");
    setNaslPaidDate(toDateObj(repair.nasl_paid_date));
    setNaslRepairerPaidStatus(repair.nasl_paid_status);
    setNaslRepairPaidAmount(repair.nasl_paid_amount);
    setRepairLeadSource({ value: repair.lead_source_id, label: repair.lead_source_name });
    setRepairStatus({ value: repair.repair_status, label: repair.repair_status });
  };

  useEffect(() => {
    setFields();
  }, [formData]);

  const updateHandler = async () => {
    let isInvalid = false;

    if (!validatePhoneNumber(sendSMSMobile.clientNumber)) {
      setValidationErrors((prevErrors) => ({ ...prevErrors, clientNumber: "Invalid client number" }));
      isInvalid = true;
    } else {
      setValidationErrors((prevErrors) => ({ ...prevErrors, clientNumber: "" }));
    }

    if (!validatePhoneNumber(sendSMSMobile.mobileNumber)) {
      setValidationErrors((prevErrors) => ({ ...prevErrors, mobileNumber: "Invalid mobile number" }));
      isInvalid = true;
    } else {
      setValidationErrors((prevErrors) => ({ ...prevErrors, mobileNumber: "" }));
    }

    if (isInvalid) return;

    try {
      setIsUpdating(true);
      const payload = {
        authorize_status: repairsAuthorize,
        authorize_date: toYYYYMMDD(authorizeDate),
        reference_no: repairRefNo,
        outlay: repairOutlay.value,
        start_date: toYYYYMMDD(repairStartDate),
        completed_date: toYYYYMMDD(repairCompletionDate),
        source_paid_date: toYYYYMMDD(sourceRepairerPaidDate),
        source_paid_status: sourceRepairerPaidStatus ? "Yes" : "No",
        source_paid_amount: sourceRepairerPaidAmount,
        nasl_paid_date: toYYYYMMDD(naslPaidDate),
        nasl_paid_status: naslRepairerPaidStatus ? "Yes" : "No",
        nasl_paid_amount: naslRepairPaidAmount,
        lead_source_id: repairLeadSource?.value || "",
        repair_status: repairStatus?.value || "",
        note: {
          note_type: "Repair",
          description: noteDescription,
          display_to_deo: addNoteTo.displayToDEO ? 1 : 0,
          display_to_client: addNoteTo.displayToClient ? 1 : 0,
          reminder_date: toYYYYMMDD(addAsReminderDate),
          send_email_source: sendEmailTo.source ? 1 : 0,
          send_email_client: sendEmailTo.client ? 1 : 0,
          send_sms_to: sendSMSTo,
          send_sms_mobile: sendSMSTo === "client" ? sendSMSMobile.clientNumber : sendSMSMobile.mobileNumber,
        },
      };

      if (authorizedBy) payload.authorize_by_id = +authorizedBy.value;
      if (garageName) payload.garage_id = +garageName.value;

      await instance.put(`/case/updateRepair/${formId}`, payload);
      toastr.success("Success", "Repair updated successfully");
      clearNoteFields();
      getNotes();
    } catch (error) {
      handleError(error);
    } finally {
      setIsUpdating(false);
    }
  };

  // console.log(sourceRepairerPaidStatus);

  return (
    <Form>

      <div className="position-absolute text-center" style={{ top: '0.5%', right: '1.7%' }}>
        {sourceRepairerPaidStatus == 1 ? (
          <h4 className="text-success">PAID</h4>
        ) : (
          <h4 className="text-danger">UNPAID</h4>
        )}
      </div>

      <Row>
        <Col md={4}>
          <FormGroup>
            <Label for="authorizedBy">Authorized By</Label>
            <AsyncPaginate
              className="basic-single"
              classNamePrefix="select"
              isSearchable={true}
              name="authorizedBy"
              id="authorizedBy"
              additional={{
                page: 1,
              }}
              debounceTimeout={300}
              loadOptions={loadRepairAuthorizeOptions}
              value={authorizedBy}
              onChange={(selected) => setAuthorizedBy(selected)}
            />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="repairOutlay">Repair Outlay</Label>
            <ReactSelect
              className="basic-single"
              classNamePrefix="select"
              name="repairOutlay"
              value={repairOutlay}
              options={yesOrNoOptions}
              onChange={(selectedOption) => setRepairOutlay(selectedOption)}
            />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="repairStatus">Repair Status</Label>
            <AsyncPaginate
              className="basic-single"
              classNamePrefix="select"
              isSearchable={true}
              name="repairStatus"
              id="repairStatus"
              loadOptions={loadRepairStatus}
              value={repairStatus}
              onChange={(selected) => setRepairStatus(selected)}
            />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="authorizeDate">Authorize Date</Label>
            <Col md={6} className="p-0">
              <CustomDate startDate={authorizeDate} setStartDate={setAuthorizeDate} id={"authorizeDate"} />
              {/* <DatePicker selected={authorizeDate} onChange={(date) => setAuthorizeDate(date)} dateFormat="dd-MM-yyyy" placeholderText="Select a date" className="form-control" id="authorizeDate" /> */}
            </Col>
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="garageName">Garage Name</Label>
            <AsyncPaginate
              className="basic-single"
              classNamePrefix="select"
              isSearchable={true}
              name="garageName"
              id="garageName"
              loadOptions={loadGarageOptions}
              additional={{
                page: 1,
              }}
              value={garageName}
              debounceTimeout={300}
              onChange={(selected) => setGarageName(selected)}
            />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="repairReferenceNo">Repair Reference #</Label>
            <Input type="text" id="repairReferenceNo" value={repairRefNo} onChange={(e) => setRepairRefNo(e.target.value)} />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="repairStartDate">Repair Start Date</Label>
            <Col md={6} className="p-0">
              <CustomDate startDate={repairStartDate} setStartDate={setRepairStartDate} id={"repairStartDate"} />
              {/* <DatePicker
                selected={repairStartDate}
                onChange={(date) => setRepairStartDate(date)}
                dateFormat="dd-MM-yyyy"
                placeholderText="Select a date"
                className="form-control"
                id="repairStartDate"
              /> */}
            </Col>
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="repairCompletionDate">Repair Completion Date</Label>
            <Col md={6} className="p-0">
              <CustomDate startDate={repairCompletionDate} setStartDate={setRepairCompletionDate} id={"repairCompletionDate"} />
              {/* <DatePicker
                selected={repairCompletionDate}
                onChange={(date) => setRepairCompletionDate(date)}
                dateFormat="dd-MM-yyyy"
                placeholderText="Select a date"
                className="form-control"
                id="repairCompletionDate"
              /> */}
            </Col>
          </FormGroup>
        </Col>

        {userType === "Super Admin" || userType === "Account" ? (
          <>
            <Col md={4}>
              <FormGroup>
                <Label for="sourceRepairerPaidDate">Source Repairer Paid Date</Label>
                <Col md={6} className="p-0">
                  <CustomDate startDate={sourceRepairerPaidDate} setStartDate={setSourceRepairerPaidDate} id={"sourceRepairerPaidDate"} />
                </Col>
                <Label check className="ml-4">
                  <Input type="checkbox" checked={sourceRepairerPaidStatus} onChange={(e) => setSourceRepairerPaidStatus(e.target.checked)} />
                  Source Repairer Paid Status
                </Label>
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label for="sourceRepairerPaidAmount">Source Repairer Paid Amount</Label>
                <Input type="number" id="sourceRepairerPaidAmount" value={sourceRepairerPaidAmount} onChange={(e) => setSourceRepairerPaidAmount(e.target.value)} />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label for="naslRepairPaidDate">NASL Repair Paid Date</Label>
                <Col md={6} className="p-0">
                  <CustomDate startDate={naslPaidDate} setStartDate={setNaslPaidDate} id={"naslPaidDate"} />
                </Col>
                <Label check className="ml-4">
                  <Input type="checkbox" checked={naslRepairerPaidStatus} onChange={(e) => setNaslRepairerPaidStatus(e.target.checked)} />
                  NASL Repairer Paid Status
                </Label>
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label for="naslRepairPaidAmount">NASL Repair Paid Amount</Label>
                <Input type="number" id="naslRepairPaidAmount" value={naslRepairPaidAmount} onChange={(e) => setNaslRepairPaidAmount(e.target.value)} />
              </FormGroup>
            </Col>
          </>
        ) : (
          <>
            <Col md={4}>
              <FormGroup>
                <Label for="sourceRepairerPaidDate">Source Repairer Paid Date</Label>
                <Col md={6} className="p-0">
                  <CustomDate startDate={sourceRepairerPaidDate} id={"sourceRepairerPaidDate"} disabled/>
                </Col>
              </FormGroup>
            </Col>
          </>
        )}
        
        <Col md={4}>
          <FormGroup>
            <Label for="repairLeadSource">Repair Lead Source</Label>
            <AsyncPaginate
              className="basic-single"
              classNamePrefix="select"
              isSearchable={true}
              name="repairLeadSource"
              id="repairLeadSource"
              loadOptions={loadLeadSourceOptions}
              additional={{
                page: 1,
              }}
              value={repairLeadSource}
              debounceTimeout={300}
              onChange={(selected) => setRepairLeadSource(selected)}
            />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label>Repairs Authorize</Label>

            <Row className="pl-4">
              <Col>
                <Label check>
                  <Input type="radio" name="repairsAuthorize" checked={repairsAuthorize === "Pending"} onChange={() => setRepairsAuthorize("Pending")} />
                  Pending
                </Label>
              </Col>
              <Col>
                <Label check>
                  <Input type="radio" name="repairsAuthorize" checked={repairsAuthorize === "Yes"} onChange={() => setRepairsAuthorize("Yes")} />
                  Yes
                </Label>
              </Col>
              <Col>
                <Label check>
                  <Input type="radio" name="repairsAuthorize" checked={repairsAuthorize === "No"} onChange={() => setRepairsAuthorize("No")} />
                  No
                </Label>
              </Col>
            </Row>
          </FormGroup>
        </Col>
      </Row>

      <Notes
        noteDescription={noteDescription}
        setNoteDescription={setNoteDescription}
        sendSMSTo={sendSMSTo}
        setSendSMSTo={setSendSMSTo}
        addAsReminderDate={addAsReminderDate}
        setAddAsReminderDate={setAddAsReminderDate}
        sendEmailTo={sendEmailTo}
        setSendEmailTo={setSendEmailTo}
        addNoteTo={addNoteTo}
        setAddNoteTo={setAddNoteTo}
        sendSMSMobile={sendSMSMobile}
        setSendSMSMobile={setSendSMSMobile}
        isAddNotActive={isAddNotActive}
        setIsAddNotActive={setIsAddNotActive}
        isAddAsReminderActive={isAddAsReminderActive}
        setIsAddAsReminderActive={setIsAddAsReminderActive}
        isSendEmailActive={isSendEmailActive}
        setIsSendEmailActive={setIsSendEmailActive}
        isSendSMSActive={isSendSMSActive}
        setIsSendSMSActive={setIsSendSMSActive}
        validationErrors={validationErrors}
        setValidationErrors={setValidationErrors}
      />

      <div className="d-flex justify-content-end">
        <ParamsLink to="/administration/cases" className="mr-2">
          <Button color="primary">Exit</Button>
        </ParamsLink>
        <LoadingButton onClick={updateHandler} isLoading={isUpdating}>
          Update
        </LoadingButton>
      </div>
    </Form>
  );
};

export default Repair;
