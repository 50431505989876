import React, { memo, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, CardHeader, CardTitle, Col, Row, UncontrolledCollapse, FormGroup, Input } from "reactstrap";
import { useRoleContext } from "../../../context/security/roles";
import RoleFormItem from "./RoleFormItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

const RoleCollapsibleSection = memo(({ id, name, subSections = [], isDefaultOpen = false }) => {
  const [isOpen, setIsOpen] = useState(isDefaultOpen);
  id = id.replace(/ /g, "-");

  // Accessing the context for managing role permissions
  const { permissionsGroup, setPermissionsGroup } = useRoleContext();

  // State to store transformed subsections and checkAll state
  const [transformedSubsections, setTransformedSubsections] = useState([]);
  const [checkAll, setCheckAll] = useState(false);

  useEffect(() => {
    // Transforming subSections into an array of objects for easier mapping
    setTransformedSubsections(Object.entries(subSections).map(([name, value]) => ({ name, value })));
  }, [subSections]);

  // Handler for checkAll checkbox change
  const handleCheckAllChange = (e) => {
    // Creating a copy of permissions for modification
    const updatedPermissions = { ...permissionsGroup };

    // Toggling the permissions based on the checkAll state
    transformedSubsections.forEach(({ value }) => {
      value.forEach(({ route, permission_id }) => {
        updatedPermissions[route][permission_id] = !checkAll ? 1 : 0;
      });
    });

    // Updating the context with the modified permissions
    setPermissionsGroup(updatedPermissions);

    // Toggling the checkAll state
    setCheckAll(!checkAll);
  };

  // Function to check if all checkboxes are checked
  const areAllCheckboxesChecked = () => transformedSubsections.every(({ value }) => value.every(({ route, permission_id }) => permissionsGroup[route][permission_id] === 1));

  useEffect(() => {
    // Update the checkAll state based on the state of all checkboxes
    setCheckAll(areAllCheckboxesChecked());
  }, [permissionsGroup]);

  // Update checkAll state if all checkboxes are initially checked or unchecked
  useEffect(() => {
    setCheckAll(areAllCheckboxesChecked());
  }, [transformedSubsections]);

  return (
    <Card>
      <CardHeader>
        <CardTitle tag="h5" className="my-2">
          <FormGroup check className="mb-2">
            <div check className="d-flex align-items-center">
              <Input type="checkbox" id={`check-all-${id}`} checked={checkAll} onChange={handleCheckAllChange} className="mb-2" />
              <Link id={id} to={`#${id}`} onClick={() => setIsOpen((pre) => !pre)}>
                {name}
              </Link>
              <FontAwesomeIcon icon={faArrowRight} className={`ml-2 ${isOpen ? "rotate-90" : "rotate-0"}`} />
            </div>
          </FormGroup>
        </CardTitle>
      </CardHeader>

      {/* Collapsible content */}
      <UncontrolledCollapse toggler={`#${id}`} defaultOpen={isDefaultOpen}>
        <CardBody>
          {/* Rendering subsections */}
          <Row>
            {transformedSubsections.map(({ name, value }) => (
              <Col md={4} sm={6} key={name}>
                <RoleFormItem name={name} values={value} />
              </Col>
            ))}
          </Row>
        </CardBody>
      </UncontrolledCollapse>
    </Card>
  );
});

export default RoleCollapsibleSection;
