import React, { memo } from "react";
import DatePicker from "react-datepicker";
import Select from "react-select";

export function range(start, stop, step = 1) {
  const result = [];
  for (let i = start; i < stop; i += step) {
    result.push(i);
  }
  return result;
}

export const getYear = (date) => date.getFullYear();
export const getMonth = (date) => date.getMonth();

export const years = range(1900, getYear(new Date()) + 1, 1).reverse();
export const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

const CustomDate = memo(({ startDate = null, setStartDate, id = "", className = "", disabled = false, dateFormat = "dd-MM-yyyy", placeholder, ...props }) => {
  return (
    <DatePicker
      todayButton="Today"
      dateFormat={dateFormat}
      id={id}
      autoComplete="off"
      disabled={disabled}
      className={`form-control ${className}`}
      placeholderText={disabled ? "" : placeholder || "Select a date"}
      renderCustomHeader={({ date, changeYear, changeMonth }) => (
        <div
          style={{
            margin: 10,
            display: "flex",
            justifyContent: "center",
            gap: "5px",
          }}
        >
          <Select
            className="basic-single w-100"
            classNamePrefix="select"
            value={{ value: getYear(date), label: getYear(date) }}
            onChange={(selectedOption) => changeYear(selectedOption.value)}
            options={years.map((year) => ({ value: year, label: year }))}
          />

          <Select
            className="basic-single w-100"
            classNamePrefix="select"
            value={{ value: months[getMonth(date)], label: months[getMonth(date)] }}
            onChange={(selectedOption) => changeMonth(months.indexOf(selectedOption.value))}
            options={months.map((month) => ({ value: month, label: month }))}
          />
        </div>
      )}
      selected={startDate}
      onChange={(date) => setStartDate(date)}
      {...props}
    />
  );
});

export default CustomDate;
