import React, { memo } from "react";
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";

export const BdoCard = ({ title, value }) => {
  return (
    <div className="bdo-card">
      <h6>{title}</h6>
      <h4>{value}</h4>
    </div>
  );
};

const SourceProgess = memo(({ totalSources, activeSources }) => {
  return (
    <Card>
      <CardBody>
        <CardTitle tag="h5">Source Progress</CardTitle>

        <Row>
          <Col sm="6">
            <BdoCard title="Total Sources" value={totalSources} />
          </Col>
          <Col sm="6">
            <BdoCard title="Active Sources" value={activeSources} />
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
});

export default SourceProgess;
