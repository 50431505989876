import React, { createContext, useContext, useState } from "react";
import instance from "../../../axiosInstance";
import { useParams } from "react-router-dom";
import { toastr } from "react-redux-toastr";
import useError from "../../../hooks/useError";
import { validateAlphaNumeric, validateDecimalInput, validateEmail, validatePhoneNumber } from "../../../utils/validations";
import { toTimeFormat, toYYYYMMDD } from "../../../utils/forms";

export const claimFormContext = createContext({});
export const useClaimFormContext = () => useContext(claimFormContext);

const ClaimFormContextProvider = ({ children }) => {
  const { formId } = useParams();
  const [caseId, setCaseId] = useState(formId);

  const { handleError } = useError();

  // client details
  const [case_type, setCaseType] = useState("");
  const [occupant_type, setOccupantType] = useState(null);
  const [title, setTitle] = useState(null);
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [phone_no, setPhoneNo] = useState("");
  const [emergency_no, setEmergencyNo] = useState("");
  const [postal_address, setPostalAddress] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [postal_code, setPostalCode] = useState("");
  const [email_address, setEmailAddress] = useState("");
  const [date_of_birth, setDateOfBirth] = useState(null);
  const [ni_number, setNiNumber] = useState("");
  const [preferred_language, setPreferredLanguage] = useState(null);
  const [translator_name, setTranslatorName] = useState("");
  const [translator_phone, setTranslatorPhone] = useState("");
  const [translator_relation, setTranslatorRelationship] = useState("");
  const [translator_password, setTranslatorPassword] = useState("");
  const [employment_status, setEmploymentStatus] = useState(null);
  const [employment_status_remarks, setEmploymentStatusRemarks] = useState("");
  const [file_handler, setFileHandler] = useState("");
  const [hire_file_handler, setHireFileHandler] = useState("");
  const [tenancy_date, setTenancyStartDate] = useState(null);
  const [tenancy_name, setTenancyName] = useState("");
  const [occupant_relation, setOccupantRelation] = useState("None");
  const [tenancy_agreement_available, setTenancyAgreementAvailable] = useState();
  const [arrears_with_landlord, setArrearsWithLandlord] = useState();
  const [fileHandlerOptions, setFileHandlerOptions] = useState(null);
  const [insurer, setInsurer] = useState("");
  const [policy_number, set_policy_number] = useState("");
    

  // client details validations
  const [clientDetailsValidation, setClientDetailsValidation] = useState({
    postCode: "",
    emailAddress: "",
    telephone: "",
    emergencyContactNo: "",
    translatorTelephone: "",
  });

  const validateClientDetailsErrors = () => {
    const errors = {
      postCode: "",
      emailAddress: "",
      telephone: "",
      emergencyContactNo: "",
      translatorTelephone: "",
    };

    if (postal_code && !validateAlphaNumeric(postal_code)) errors.postCode = "Invalid postal code";
    if (email_address && !validateEmail(email_address)) errors.emailAddress = "Invalid email address";
    if (phone_no && !validatePhoneNumber(phone_no)) errors.telephone = "Invalid phone number";
    if (emergency_no && !validatePhoneNumber(emergency_no)) errors.emergencyContactNo = "Invalid contact number";
    if (translator_phone && !validatePhoneNumber(translator_phone)) errors.translatorTelephone = "Invalid phone number";

    setClientDetailsValidation(errors);
    return Object.values(errors).every((error) => error === "");
  };

  // accident/injury
  const [accident_type, setAccidentType] = useState();
  const [accident_date, setAccidentDate] = useState();
  const [accident_time, setAccidentTime] = useState();
  const [accident_location, setAccidentLocation] = useState("");
  const [accident_description, setAccidentDescription] = useState("");
  const [injury_description, setInjuryDescription] = useState("");
  const [wearing_seat_belt, setWearingSeatBelt] = useState("");
  const [google_lat_lon, setGoogleLatLon] = useState("");
  const [suffering_time, setSufferingTime] = useState("");
  const [medical_attention, setMedicalAttention] = useState("");
  const [previous_accidents, setPreviousAccidents] = useState("");
  const [why_you_not_claim, setWhyYouNotClaim] = useState("");
  const [sent_for_medical, setSentForMedical] = useState("");
  const [did_any_paperwork, setDidAnyPaperwork] = useState("");
  const [reported_to_police, setReportedToPolice] = useState("");
  const [police_report, setPoliceReport] = useState("");
  const [were_there_witness, setWereThereWitness] = useState("");
  const [witness_info, setWitnessInfo] = useState("");
  const [witness_title, setWitnessTitle] = useState("");
  const [witness_first_name, setWitnessFirstName] = useState("");
  const [witness_last_name, setWitnessLastName] = useState("");
  const [witness_contact, setWitnessContact] = useState("");
  const [were_there_passenger, setWereTherePassenger] = useState("");
  const [passenger_info, setPassengerInfo] = useState("");
  const [liability_terms, setLiabilityTerms] = useState("");
  const [tp_admit_liability, setTpAdmitLiability] = useState("");
  const [tp_offered_service, setTpOfferedService] = useState("");
  const [offered_service_detail, setOfferedServiceDetail] = useState("");
  const [country_of_accident, setCountryOfAccident] = useState();

  const [type_of_accident, set_type_of_accident] = useState();
  const [passengers_in_vehicle, set_passengers_in_vehicle] = useState();
  const [third_party_driver_description, set_third_party_driver_description] = useState();
  const [driver_gender, set_driver_gender] = useState();
  const [driver_age_approx, set_driver_age_approx] = useState();
  const [third_party_driver_insurance_details, set_third_party_driver_insurance_details] = useState();
  const [details_obtained_from, set_details_obtained_from] = useState();
  const [time_off_work_taken_due_to_injury, set_time_off_work_taken_due_to_injury] = useState();
  const [client_currently_off_work, set_client_currently_off_work] = useState();
  const [total_days_off_work, set_total_days_off_work] = useState();
  const [medical_attention_sought, set_medical_attention_sought] = useState();
  const [medical_professional_consulted, set_medical_professional_consulted] = useState();
  const [recommended_followup_treatment, set_recommended_followup_treatment] = useState();
  const [hospital_attended, set_hospital_attended] = useState();
  const [hospital_name, set_hospital_name] = useState();
  const [hospital_stay_nights, set_hospital_stay_nights] = useState();
  const [claim_for_losses_required, set_claim_for_losses_required] = useState();

  const [updated_at, setUpdatedAt] = useState();
  const [updated_by_id, setUpdatedById] = useState();
  const [updated_by, setUpdatedBy] = useState();
  const [updated_from_ip, setUpdatedFromIp] = useState();

  const [accidentValidation, setAccidentValidation] = useState({
    accidentType: "",
    accidentDate: "",
    accidentTime: "",
    witnessContact: "",
  });

  const validateAccidentErrors = () => {
    const errors = {
      accidentType: "",
      accidentDate: "",
      accidentTime: "",
      witnessContact: "",
    };

    if (!accident_type) errors.accidentType = "Accident type is required";
    if (!accident_date) errors.accidentDate = "Accident date is required";
    if (!accident_time) errors.accidentTime = "Accident time is required";
    if (witness_contact && !validatePhoneNumber(witness_contact)) errors.witnessContact = "Invalid contact number";

    setAccidentValidation(errors);
    return Object.values(errors).every((error) => error === "");
  };

  // defendent
  const [defendant_title, setDefendantTitle] = useState("mr.");
  const [defendant_first_name, setDefendantFirstName] = useState("");
  const [defendant_last_name, setDefendantLastName] = useState("");
  const [defendant_email, setDefendantEmail] = useState("");
  const [defendant_registration_number, setDefendantRegistrationNumber] = useState("");
  const [defendant_policy_number, setDefendantPolicyNumber] = useState("");
  const [defendant_address, setDefendantAddress] = useState("");
  const [defendant_post_code, setDefendantPostCode] = useState("");
  const [defendant_make_model, setDefendantMakeModel] = useState("");
  const [defendant_color, setDefendantColor] = useState("");
  const [defendant_contact_no, setDefendantContactNo] = useState("");
  const [defendant_insurance, setDefendantInsurance] = useState({ label: "No", value: "No" });
  const [defendant_insurer, setDefendantInsurer] = useState("");

  // defendent validations
  const [defendantValidation, setDefendantValidation] = useState({
    emailAddress: "",
    postCode: "",
    contactNo: "",
  });

  const validateDefendantDetailsErrors = () => {
    const errors = {
      postCode: "",
      emailAddress: "",
      contactNo: "",
    };

    if (defendant_post_code && !validateAlphaNumeric(defendant_post_code)) errors.postCode = "Invalid postal code";
    if (defendant_email && !validateEmail(defendant_email)) errors.emailAddress = "Invalid email address";
    if (defendant_contact_no && !validatePhoneNumber(defendant_contact_no)) errors.contactNo = "Invalid contact number";

    setDefendantValidation(errors);
    return Object.values(errors).every((error) => error === "");
  };

  // client vehicle
  const [registration_name, setRegistrationName] = useState("");
  const [registration_no, setRegistrationNo] = useState("");
  const [damage_detail, setDamageDetail] = useState("");
  const [make_model, setMakeModel] = useState("");
  const [color, setColor] = useState("");
  const [insurance, setInsurance] = useState("");
  const [road_worthy, setRoadWorthy] = useState({ label: "No", value: "No" });
  const [location, setLocation] = useState("");
  const [recovered_date, setRecoveredDate] = useState("");
  const [insurance_type, setInsuranceType] = useState();
  const [vehicle_condition, setVehicleCondition] = useState("");
  const [registration_contact, setRegistrationContact] = useState("");
  const [v5_available, setV5Available] = useState({ label: "No", value: "No" });
  const [insurance_available, setInsuranceAvailable] = useState({ label: "No", value: "No" });
  const [driving_license_available, setDrivingLicenseAvailable] = useState({ label: "No", value: "No" });
  const [require_rack_box, setRequireRackBox] = useState({ label: "No", value: "No" });
  const [require_top_box, setRequireTopBox] = useState({ label: "No", value: "No" });
  const [top_box_type, setTopBoxType] = useState();
  const [helmet_useable, setHelmetUseable] = useState({ label: "No", value: "No" });
  const [using_accessories, setUsingAccessories] = useState({ label: "No", value: "No" });
  const [can_transfer_accessories, setCanTransferAccessories] = useState({ label: "No", value: "No" });
  const [hot_grips_amount, setHotGripsAmount] = useState(0);
  const [wind_screen_amount, setWindScreenAmount] = useState(0);
  const [muffs_amount, setMuffsAmount] = useState(0);
  const [leg_cover_amount, setLegCoverAmount] = useState(0);
  const [mobile_charger_amount, setMobileChargerAmount] = useState(0);
  const [collect_bike_from_fenchurch, setCollectBikeFromFenchurch] = useState({ label: "No", value: "No" });
  const [collect_125cc_from_fenchurch, setCollect125ccFromFenchurch] = useState({ label: "No", value: "No" });
  const [rk_title, set_rk_title] = useState(null);
  const [rk_first_name, set_rk_firstName] = useState("");
  const [rk_middle_name, set_rk_middleName] = useState("");
  const [rk_last_name, set_rk_lastName] = useState("");
  const [rk_postal_code, set_rk_postalCode] = useState("");
  const [rk_email_address, set_rk_emailAddress] = useState("");
  const [rk_date_of_birth, set_rk_dateOfBirth] = useState(null);
  const [rk_ni_number, set_rk_niNumber] = useState("");
  const [rk_occupation, set_rk_occupation] = useState("");


  const [clientVehicleValidation, setClientVehicleValidation] = useState({
    hotGripsAmount: "",
    windScreenAmount: "",
    muffsAmount: "",
    legCoverAmount: "",
    mobileChargerAmount: "",
    registeredKeeperContact: "",
  });

  const validateClientVehicleDetailsErrors = () => {
    const errors = {
      hotGripsAmount: "",
      windScreenAmount: "",
      muffsAmount: "",
      legCoverAmount: "",
      mobileChargerAmount: "",
      registeredKeeperContact: "",
    };

    if (hot_grips_amount && !validateDecimalInput(hot_grips_amount)) errors.hotGripsAmount = "Invalid amount";
    if (wind_screen_amount && !validateDecimalInput(wind_screen_amount)) errors.windScreenAmount = "Invalid amount";
    if (muffs_amount && !validateDecimalInput(muffs_amount)) errors.muffsAmount = "Invalid amount";
    if (leg_cover_amount && !validateDecimalInput(leg_cover_amount)) errors.legCoverAmount = "Invalid amount";
    if (mobile_charger_amount && !validateDecimalInput(mobile_charger_amount)) errors.mobileChargerAmount = "Invalid amount";
    if (registration_contact && !validatePhoneNumber(registration_contact)) errors.registeredKeeperContact = "Invalid contact number";

    setClientVehicleValidation(errors);
    return Object.values(errors).every((error) => error === "");
  };

  // hire vehicle
  const [hire_vehicle_registration, set_hire_vehicle_registration] = useState("");
  const [mileage_delivery, set_mileage_delivery] = useState("");
  const [makeModel, set_makeModel] = useState("");
  const [mileage_collection, set_mileage_collection] = useState("");
  const [vehicle_color, set_vehicle_color] = useState("");
  const [hire_start_date, set_hire_start_date] = useState(null);
  const [hire_end_date, set_hire_end_date] = useState(null);

  const [accessories_provided, set_accessories_provided] = useState("No");
  const [hot_grips_provided, set_hot_grips_provided] = useState("No");
  const [muffs_provided, set_muffs_provided] = useState("No");
  const [cradle_charger_provided, set_cradle_charger_provided] = useState("No");
  const [windscreen_provided, set_windscreen_provided] = useState("No");
  const [log_cover_provided, set_log_cover_provided] = useState("No");
  const [top_pizza_box_provided, set_top_pizza_box_provided] = useState("No");
                
  const [bike_service, set_bike_service] = useState("No");
  const [oil_changed, set_oil_changed] = useState("No");
  const [front_brake, set_front_brake] = useState("No");
  const [rear_brake, set_rear_brake] = useState("No");
  const [front_tyre, set_front_tyre] = useState("No");
  const [rear_tyre, set_rear_tyre] = useState("No");
  const [bike_revision, set_bike_revision] = useState("No");

  const [vehicle_swap, set_vehicle_swap] = useState("No");

  //insurance
  const [broker_reference, set_broker_reference] = useState("");
  const [convictions, set_convictions] = useState("");
  const [engine_size, set_engine_size] = useState("");
  const [make_year, set_make_year] = useState("");
  const [value, set_value] = useState("");
  const [inception_date, set_inception_date] = useState("");
  const [compulsory_excess, set_compulsory_excess] = useState("");
  const [voluntary_excess, set_voluntary_excess] = useState("");
  const [glass_excess, set_glass_excess] = useState("");
  const [driver1_title, set_driver1_title] = useState("");
  const [driver1_first_name, set_driver1_first_name] = useState("");
  const [driver1_last_name, set_driver1_last_name] = useState("");
  const [driver1_dob, set_driver1_dob] = useState("");
  const [driver1_convictions, set_driver1_convictions] = useState("");
  const [claim_type, set_claim_type] = useState("");
  const [driver2_title, set_driver2_title] = useState("");
  const [driver2_first_name, set_driver2_first_name] = useState("");
  const [driver2_last_name, set_driver2_last_name] = useState("");
  const [driver2_dob, set_driver2_dob] = useState("");
  const [driver2_convictions, set_driver2_convictions] = useState("");

  // additional
  const [additional_information, setAdditionalInformation] = useState("");
  const [about_nasl, setAboutNasl] = useState("");
  const [can_transfer_solicitor, setCanTransferSolicitor] = useState({ label: "No", value: "No" });
  const [can_share_detail, setCanShareDetail] = useState({ label: "No", value: "No" });

  // attachment
  const [attachment_description, setAttachmentDescription] = useState("");
  const [attachment_file, setAttachmentFile] = useState("");
  const [attachments, setAttachments] = useState([]);

  //HandleFunctions
  const clientDetailHandleSubmit = async () => {
    if (!validateClientDetailsErrors()) return;
    try {
      const formData = {
        // case_type,
        occupant_type: occupant_type?.label,
        phone_no,
        emergency_no,
        postal_address,
        postal_code,
        email_address,
        date_of_birth: toYYYYMMDD(date_of_birth),
        ni_number,
        preferred_language: preferred_language?.label,
        translator_name,
        translator_phone,
        translator_relation,
        translator_password,
        employment_status: employment_status?.label,
        employment_status_remarks,
        tenancy_date: toYYYYMMDD(tenancy_date),
        tenancy_name,
        occupant_relation,
        tenancy_agreement_available: tenancy_agreement_available?.value,
        arrears_with_landlord: arrears_with_landlord?.value,
        insurer,
        policy_number,
      };

      const response = await instance.put(`case/updateClientDetail/${caseId}`, formData);
      toastr.success("Success", "Form Submitted Successfully");
      return true;
    } catch (err) {
      handleError(err);
    }
  };

  const injuryDetailsHandleSubmit = async () => {
    if (!validateAccidentErrors()) return;

    try {
      const formData = {
        accident_type: accident_type?.label,
        accident_date: toYYYYMMDD(accident_date),
        accident_time: toTimeFormat(accident_time),
        accident_location,
        accident_description,
        injury_description,
        wearing_seat_belt: wearing_seat_belt?.label,
        google_lat_lon,
        suffering_time,
        medical_attention,
        previous_accidents,
        why_you_not_claim,
        sent_for_medical: sent_for_medical?.label,
        did_any_paperwork: did_any_paperwork?.label,
        reported_to_police: reported_to_police?.label,
        police_report,
        were_there_witness: were_there_witness?.label,
        witness_info,
        witness_title: witness_title?.label,
        witness_first_name,
        witness_last_name,
        witness_contact,
        were_there_passenger: were_there_passenger?.label,
        passenger_info,
        liability_terms,
        tp_admit_liability: tp_admit_liability?.label,
        tp_offered_service: tp_offered_service?.label,
        offered_service_detail,
        country_of_accident: country_of_accident?.label,

        type_of_accident: type_of_accident.label,
        passengers_in_vehicle: passengers_in_vehicle.label,
        third_party_driver_description, 
        driver_gender: driver_gender.label, 
        driver_age_approx, 
        third_party_driver_insurance_details: third_party_driver_insurance_details.label, 
        details_obtained_from, 
        time_off_work_taken_due_to_injury: time_off_work_taken_due_to_injury.label, 
        client_currently_off_work: client_currently_off_work.label, 
        total_days_off_work, 
        medical_attention_sought: medical_attention_sought.label, 
        medical_professional_consulted: medical_professional_consulted.label, 
        recommended_followup_treatment: recommended_followup_treatment.label, 
        hospital_attended: hospital_attended.label, 
        hospital_name, 
        hospital_stay_nights, 
        claim_for_losses_required: claim_for_losses_required.label,
      };

      const response = await instance.put(`case/updateAccidentDetail/${caseId}`, formData);
      toastr.success("Success", "Form Submitted Successfully");
      return true;
    } catch (err) {
      handleError(err);
    }
  };

  const defendantDetailHandleSubmit = async () => {
    if (!validateDefendantDetailsErrors()) return;
    try {
      const formData = {
        defendant_title,
        defendant_first_name,
        defendant_last_name,
        defendant_email,
        defendant_registration_number,
        defendant_policy_number,
        defendant_address,
        defendant_post_code,
        defendant_make_model,
        defendant_color,
        defendant_contact_no,
        defendant_insurance: defendant_insurance?.label,
        defendant_insurer,
      };

      const response = await instance.put(`case/updateDefendantDetail/${caseId}`, formData);
      toastr.success("Success", "Form Submitted Successfully");
      return true;
    } catch (err) {
      handleError(err);
    }
  };

  const vehicleDetailHandleSubmit = async () => {
    if (!validateClientVehicleDetailsErrors()) return;
    try {
      const formData = {
        registered_keeper: registration_name?.label,
        registration_no,
        damage_detail,
        make_model,
        color,
        insurance,
        road_worthy: road_worthy?.label,
        location,
        recovered_date: recovered_date ? toYYYYMMDD(recovered_date) : null,
        insurance_type: insurance_type?.label,
        vehicle_condition,
        registration_contact,
        v5_available: v5_available?.label,
        insurance_available: insurance_available?.label,
        driving_license_available: driving_license_available?.label,
        require_rack_box: require_rack_box?.label,
        require_top_box: require_top_box?.label,
        top_box_type: top_box_type?.label,
        helmet_useable: helmet_useable?.label,
        using_accessories: using_accessories?.label,
        can_transfer_accessories: can_transfer_accessories?.label,
        collect_bike_from_fenchurch: collect_bike_from_fenchurch?.label,
        collect_125cc_from_fenchurch: collect_125cc_from_fenchurch?.label, 
        client_title: rk_title?.label, 
        client_first_name: rk_first_name, 
        client_middle_name: rk_middle_name,
        client_last_name: rk_last_name,
        postal_code: rk_postal_code,
        email_address: rk_email_address,
        date_of_birth: rk_date_of_birth ? toYYYYMMDD(rk_date_of_birth) : null,
        ni_number: rk_ni_number,
        occupation: rk_occupation,
      };

      if (hot_grips_amount) formData.hot_grips_amount = hot_grips_amount;
      if (wind_screen_amount) formData.wind_screen_amount = wind_screen_amount;
      if (muffs_amount) formData.muffs_amount = muffs_amount;
      if (leg_cover_amount) formData.leg_cover_amount = leg_cover_amount;
      if (mobile_charger_amount) formData.mobile_charger_amount = mobile_charger_amount;

      const response = await instance.put(`case/updateClientVehicle/${caseId}`, formData);
      toastr.success("Success", "Form Submitted Successfully");
      return true;
    } catch (err) {
      handleError(err);
    }
  };

  const insuranceDetailHandleSubmit = async () => {
    try {
      const formData = {
        broker_reference,
        convictions,
        engine_size,
        make_year,
        value,
        inception_date: inception_date ? toYYYYMMDD(inception_date) : null,
        compulsory_excess,
        voluntary_excess,
        glass_excess,
        driver1_title: driver1_title ? driver1_title.label : null,
        driver1_first_name,
        driver1_last_name,
        driver1_dob: driver1_dob ? toYYYYMMDD(driver1_dob) : null,
        driver1_convictions,
        claim_type: claim_type ? claim_type.label : null,
        driver2_title: driver2_title ? driver2_title.label : null,
        driver2_first_name,
        driver2_last_name,
        driver2_dob: driver2_dob ? toYYYYMMDD(driver2_dob) : null,
        driver2_convictions, 
      };

      
      const response = await instance.put(`case/updateInsuranceDetail/${caseId}`, formData);
      toastr.success("Success", "Form Submitted Successfully");
      return true;
    } catch (err) {
      handleError(err);
    }
  };

  const additionalDetailHandleSubmit = async () => {
    try {
      const formData = {
        additional_information,
        about_nasl: about_nasl?.label,
        can_transfer_solicitor: can_transfer_solicitor?.label,
        can_share_detail: can_share_detail?.label,
      };

      const response = await instance.put(`case/updateAdditionalDetail/${caseId}`, formData);
      toastr.success("Success", "Form Submitted Successfully");
      return true;
    } catch (err) {
      handleError(err);
    }
  };

  const values = {
    caseId,
    setCaseId,

    // client details
    case_type,
    setCaseType,
    occupant_type,
    setOccupantType,
    title,
    setTitle,
    first_name,
    setFirstName,
    last_name,
    setLastName,
    phone_no,
    setPhoneNo,
    emergency_no,
    setEmergencyNo,
    postal_address,
    setPostalAddress,
    address1,
    setAddress1,
    address2,
    setAddress2,
    postal_code,
    setPostalCode,
    email_address,
    setEmailAddress,
    date_of_birth,
    setDateOfBirth,
    ni_number,
    setNiNumber,
    preferred_language,
    setPreferredLanguage,
    translator_name,
    setTranslatorName,
    translator_phone,
    setTranslatorPhone,
    translator_relation,
    setTranslatorRelationship,
    translator_password,
    setTranslatorPassword,
    employment_status,
    setEmploymentStatus,
    employment_status_remarks,
    setEmploymentStatusRemarks,
    file_handler,
    setFileHandler,
    hire_file_handler,
    setHireFileHandler,
    tenancy_date,
    setTenancyStartDate,
    tenancy_name,
    setTenancyName,
    occupant_relation,
    setOccupantRelation,
    tenancy_agreement_available,
    setTenancyAgreementAvailable,
    arrears_with_landlord,
    setArrearsWithLandlord,
    fileHandlerOptions,
    setFileHandlerOptions,
    insurer,
    setInsurer,
    policy_number,
    set_policy_number,

    // accident/injury
    accident_type,
    setAccidentType,
    accident_date,
    setAccidentDate,
    accident_time,
    setAccidentTime,
    accident_location,
    setAccidentLocation,
    accident_description,
    setAccidentDescription,
    injury_description,
    setInjuryDescription,
    wearing_seat_belt,
    setWearingSeatBelt,
    google_lat_lon,
    setGoogleLatLon,
    suffering_time,
    setSufferingTime,
    medical_attention,
    setMedicalAttention,
    previous_accidents,
    setPreviousAccidents,
    why_you_not_claim,
    setWhyYouNotClaim,
    sent_for_medical,
    setSentForMedical,
    did_any_paperwork,
    setDidAnyPaperwork,
    reported_to_police,
    setReportedToPolice,
    police_report,
    setPoliceReport,
    were_there_witness,
    setWereThereWitness,
    witness_info,
    setWitnessInfo,
    witness_title,
    setWitnessTitle,
    witness_first_name,
    setWitnessFirstName,
    witness_last_name,
    setWitnessLastName,
    witness_contact,
    setWitnessContact,
    were_there_passenger,
    setWereTherePassenger,
    passenger_info,
    setPassengerInfo,
    liability_terms,
    setLiabilityTerms,
    tp_admit_liability,
    setTpAdmitLiability,
    tp_offered_service,
    setTpOfferedService,
    offered_service_detail,
    setOfferedServiceDetail,
    country_of_accident,
    setCountryOfAccident,
    updated_at,
    setUpdatedAt,
    updated_by_id,
    setUpdatedById,
    updated_by,
    setUpdatedBy,
    updated_from_ip,
    setUpdatedFromIp,

    type_of_accident, 
    set_type_of_accident,
    passengers_in_vehicle, 
    set_passengers_in_vehicle,
    third_party_driver_description, 
    set_third_party_driver_description,
    driver_gender, 
    set_driver_gender,
    driver_age_approx, 
    set_driver_age_approx,
    third_party_driver_insurance_details, 
    set_third_party_driver_insurance_details,
    details_obtained_from, 
    set_details_obtained_from,
    time_off_work_taken_due_to_injury, 
    set_time_off_work_taken_due_to_injury,
    client_currently_off_work, 
    set_client_currently_off_work,
    total_days_off_work, 
    set_total_days_off_work,
    medical_attention_sought, 
    set_medical_attention_sought,
    medical_professional_consulted, 
    set_medical_professional_consulted,
    recommended_followup_treatment, 
    set_recommended_followup_treatment,
    hospital_attended, 
    set_hospital_attended,
    hospital_name, 
    set_hospital_name,
    hospital_stay_nights, 
    set_hospital_stay_nights,
    claim_for_losses_required, 
    set_claim_for_losses_required,

    // defendent
    defendant_title,
    setDefendantTitle,
    defendant_first_name,
    setDefendantFirstName,
    defendant_last_name,
    setDefendantLastName,
    defendant_email,
    setDefendantEmail,
    defendant_registration_number,
    setDefendantRegistrationNumber,
    defendant_policy_number,
    setDefendantPolicyNumber,
    defendant_address,
    setDefendantAddress,
    defendant_post_code,
    setDefendantPostCode,
    defendant_make_model,
    setDefendantMakeModel,
    defendant_color,
    setDefendantColor,
    defendant_contact_no,
    setDefendantContactNo,
    defendant_insurance,
    setDefendantInsurance,
    defendant_insurer,
    setDefendantInsurer,

    // client vehicle
    registration_name,
    setRegistrationName,
    registration_no,
    setRegistrationNo,
    damage_detail,
    setDamageDetail,
    make_model,
    setMakeModel,
    color,
    setColor,
    insurance,
    setInsurance,
    road_worthy,
    setRoadWorthy,
    location,
    setLocation,
    recovered_date,
    setRecoveredDate,
    insurance_type,
    setInsuranceType,
    vehicle_condition,
    setVehicleCondition,
    registration_contact,
    setRegistrationContact,
    v5_available,
    setV5Available,
    insurance_available,
    setInsuranceAvailable,
    driving_license_available,
    setDrivingLicenseAvailable,
    require_rack_box,
    setRequireRackBox,
    require_top_box,
    setRequireTopBox,
    top_box_type,
    setTopBoxType,
    helmet_useable,
    setHelmetUseable,
    using_accessories,
    setUsingAccessories,
    can_transfer_accessories,
    setCanTransferAccessories,
    hot_grips_amount,
    setHotGripsAmount,
    wind_screen_amount,
    setWindScreenAmount,
    muffs_amount,
    setMuffsAmount,
    leg_cover_amount,
    setLegCoverAmount,
    mobile_charger_amount,
    setMobileChargerAmount,
    collect_bike_from_fenchurch,
    setCollectBikeFromFenchurch,
    collect_125cc_from_fenchurch,
    setCollect125ccFromFenchurch,
    rk_title, 
    set_rk_title,
    rk_first_name, 
    set_rk_firstName,
    rk_middle_name, 
    set_rk_middleName,
    rk_last_name, 
    set_rk_lastName,
    rk_postal_code, 
    set_rk_postalCode,
    rk_email_address, 
    set_rk_emailAddress,
    rk_date_of_birth, 
    set_rk_dateOfBirth,
    rk_ni_number, 
    set_rk_niNumber,
    rk_occupation, 
    set_rk_occupation,

    //hire_vehicle
    hire_vehicle_registration, 
    set_hire_vehicle_registration,
    mileage_delivery, 
    set_mileage_delivery,
    makeModel, 
    set_makeModel,
    mileage_collection, 
    set_mileage_collection,
    vehicle_color, 
    set_vehicle_color,
    hire_start_date, 
    set_hire_start_date,
    hire_end_date, 
    set_hire_end_date,
    accessories_provided, 
    set_accessories_provided,
    hot_grips_provided, 
    set_hot_grips_provided,
    muffs_provided, 
    set_muffs_provided,
    cradle_charger_provided, 
    set_cradle_charger_provided,
    windscreen_provided, 
    set_windscreen_provided,
    log_cover_provided, 
    set_log_cover_provided,
    top_pizza_box_provided, 
    set_top_pizza_box_provided,
    bike_service, 
    set_bike_service,
    oil_changed, 
    set_oil_changed,
    front_brake, 
    set_front_brake,
    rear_brake, 
    set_rear_brake,
    front_tyre, 
    set_front_tyre,
    rear_tyre, 
    set_rear_tyre,
    bike_revision, 
    set_bike_revision,
    vehicle_swap, 
    set_vehicle_swap,

    //insurance
    broker_reference, 
    set_broker_reference,
    convictions, 
    set_convictions,
    engine_size, 
    set_engine_size,
    make_year, 
    set_make_year,
    value, 
    set_value,
    inception_date, 
    set_inception_date,
    compulsory_excess, 
    set_compulsory_excess,
    voluntary_excess, 
    set_voluntary_excess,
    glass_excess, 
    set_glass_excess,
    driver1_title, 
    set_driver1_title,
    driver1_first_name, 
    set_driver1_first_name,
    driver1_last_name, 
    set_driver1_last_name,
    driver1_dob, 
    set_driver1_dob,
    driver1_convictions, 
    set_driver1_convictions,
    claim_type, 
    set_claim_type,
    driver2_title, 
    set_driver2_title,
    driver2_first_name, 
    set_driver2_first_name,
    driver2_last_name, 
    set_driver2_last_name,
    driver2_dob, 
    set_driver2_dob,
    driver2_convictions, 
    set_driver2_convictions,

    // additional
    additional_information,
    setAdditionalInformation,
    about_nasl,
    setAboutNasl,
    can_transfer_solicitor,
    setCanTransferSolicitor,
    can_share_detail,
    setCanShareDetail,

    // attachment
    attachment_description,
    setAttachmentDescription,
    attachment_file,
    setAttachmentFile,
    attachments,
    setAttachments,

    // handlers
    clientDetailHandleSubmit,
    injuryDetailsHandleSubmit,
    defendantDetailHandleSubmit,
    vehicleDetailHandleSubmit,
    insuranceDetailHandleSubmit,
    additionalDetailHandleSubmit,

    // validations
    clientDetailsValidation,
    setClientDetailsValidation,
    defendantValidation,
    setDefendantValidation,
    clientVehicleValidation,
    setClientVehicleValidation,
    accidentValidation,
    setAccidentValidation,
  };

  return <claimFormContext.Provider value={values}>{children}</claimFormContext.Provider>;
};

export default ClaimFormContextProvider;
