import React, { memo } from "react";
import { Button, Card, Col, CustomInput, FormGroup, Input, Label, Row, Spinner } from "reactstrap";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import RoleCollapsibleSection from "./RoleCollapsibleSection";
import RequiredLabel from "../../UI/forms/RequiredLabel";
import ParamsLink from "../../UI/links/ParamsLink";
import { useProfileContext } from "../../../context/profile";

const schema = yup.object().shape({
  roleName: yup.string().trim().required("Role Name is required"),
  // roleDescription: yup.string().trim().required("Role Description is required"),
});

const RoleForm = memo(({ permissions, isSubmitting, onSubmit, roleName = "", roleDescription = "", activeStatus }) => {
  const { profile } = useProfileContext();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      roleName,
      roleDescription,
      activeStatus: activeStatus,
    },
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)} onKeyPress={(e) => e.key === "Enter" && e.preventDefault()}>
      <Card className="p-3">
        <Row>
          <Col md="4">
            <FormGroup>
              <RequiredLabel htmlFor="roleName">Role Name</RequiredLabel>
              <Controller
                name="roleName"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <>
                    <Input {...field} id="roleName" autoComplete="off" className={errors.roleName ? "is-invalid" : ""} />
                    <small className="text-danger">{errors.roleName?.message}</small>
                  </>
                )}
              />
            </FormGroup>
          </Col>
          <Col md="4">
            <FormGroup>
              <Label for="roleDescription">Role Description</Label>
              <Controller
                name="roleDescription"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <>
                    <Input id="roleDescription" {...field} autoComplete="off"/>
                    {/* <small className="text-danger">{errors.roleDescription?.message}</small> */}
                  </>
                )}
              />
            </FormGroup>
          </Col>
          <Col md="4">
            <FormGroup>
              <Label for="activeStatus">Active Status</Label>
              <Controller
                render={({ field }) => (
                  <CustomInput
                    type="switch"
                    id="activeStatus"
                    name="activeStatus"
                    label={field.value ? "Active" : "Inactive"}
                    checked={field.value}
                    onChange={(e) => field.onChange(e.target.checked)}
                  />
                )}
                control={control}
                name="activeStatus"
              />
            </FormGroup>
          </Col>
        </Row>
      </Card>

      <div className="accordion">
        {permissions.map((permission, index) => (
          <RoleCollapsibleSection key={permission.name} id={permission.name} name={permission.name} isDefaultOpen={index === 0} subSections={permission.value} />
        ))}
      </div>

      <div className="d-flex justify-content-end">
        <ParamsLink to="/security/roles">
          <Button color="primary" type="button" className="mr-2">
            Exit
          </Button>
        </ParamsLink>

        {!profile?.permission?.roles?.edit ? null : (
          <Button color="primary" disabled={isSubmitting} type="submit">
            {isSubmitting && <Spinner size="sm" color="#fff" className="mr-2 mb-1" />}
            <span>Save</span>
          </Button>
        )}
      </div>
    </form>
  );
});

export default RoleForm;
