import React, { createContext, useContext, useState } from "react";

export const personalInjuryContext = createContext({});
export const usePersonalInjuryContext = () => useContext(personalInjuryContext);

const PersonalInjuryContextProvider = ({ children }) => {
  const[userType, setUserType] = useState(null)
  const [caseType, setCaseType] = useState({
    seriousInjury: false,
    catastrophicInjury: false,
  });
  const [leadSource, setLeadSource] = useState(null);
  const [panel, setPanel] = useState(null);
  const [solicitorRefNo, setSolicitorRefNo] = useState("");
  const [currentStatus, setCurrentStatus] = useState(null);
  const [rejectReason, setRejectReason] = useState(null);
  const [rollbackReason, setRollbackReason] = useState(null);
  const [possibleClawBack, setPossibleClawBack] = useState(false);
  const [datePaid, setDatePaid] = useState("");
  const [paidStatus, setPaidStatus] = useState(false);
  const [buyPrice, setBuyPrice] = useState("");
  const [sellingPrice, setSellingPrice] = useState("");
  const [transferDate, setTransferDate] = useState("");
  const [dateOpened, setDateOpened] = useState("");
  const [clawbackDate, setClawbackDate] = useState("");
  const [clawbackClaim, setClawbackClaim] = useState(false);
  const [clawbackExtensionDate, setClawbackExtensionDate] = useState("");
  const [sourcePaidDate, setSourcePaidDate] = useState("");
  const [sourcePaidStatus, setSourcePaidStatus] = useState(false);
  const [sourcePaidStatusTo, setSourcePaidStatusTo] = useState("");
  const [rollbackDate, setRollbackDate] = useState("");
  const [signupDate, setSignupdate] = useState("");
  const [retainedDate, setRetainedDate] = useState("");
  const [atePaidDate, setAtePaidDate] = useState("");
  const [ateProvider, setAteProvider] = useState(null);
  const [atePaidAmount, setAtePaidAmount] = useState("");
  const [introducerPaidDate, setIntroducerPaidDate] = useState("");
  const [introducerPaidAmount, setIntroducerPaidAmount] = useState("");
  const [packDate, setPackDate] = useState("");
  const [acceptDate, setAcceptDate] = useState("");
  const [rejectDate, setRejectDate] = useState("");
  const [paymentAuthorization, setPaymentAuthorization] = useState(null);
  const [paymentNotAuthorizedReason, setPaymentNotAuthorizedReason] = useState(null);
  const [PIVDOutlay, setPIVDOutlay] = useState("");
  const [chase, setChase] = useState(false);
  const [chaseDate, setChaseDate] = useState("");
  const [chaseTime, setChaseTime] = useState("");
  const [brChase, setBrChase] = useState(false);
  const [brChaseDate, setBrChaseDate] = useState("");
  const [brChaseHandler, setBrChaseHandler] = useState(null);

  const [isAddNotActive, setIsAddNotActive] = useState(false);
  const [isAddAsReminderActive, setIsAddAsReminderActive] = useState(false);
  const [isSendEmailActive, setIsSendEmailActive] = useState(false);
  const [isSendSMSActive, setIsSendSMSActive] = useState(false);
  const [noteDescription, setNoteDescription] = useState("");
  const [addNoteTo, setAddNoteTo] = useState({ displayToDEO: false, displayToClient: false });
  const [addAsReminderDate, setAddAsReminderDate] = useState("");
  const [sendEmailTo, setSendEmailTo] = useState({ source: false, client: false });
  const [sendSMSTo, setSendSMSTo] = useState("");
  const [sendSMSMobile, setSendSMSMobile] = useState({ clientNumber: "", mobileNumber: "" });

  const values = {
    chase,
    setChase,
    brChase,
    setBrChase,
    sourcePaidStatus,
    setSourcePaidStatus,
    userType, 
    setUserType,
    caseType,
    setCaseType,
    leadSource,
    setLeadSource,
    panel,
    setPanel,
    solicitorRefNo,
    setSolicitorRefNo,
    currentStatus,
    setCurrentStatus,
    rejectReason,
    setRejectReason,
    rollbackReason,
    setRollbackReason,
    possibleClawBack,
    setPossibleClawBack,
    datePaid,
    setDatePaid,
    paidStatus,
    setPaidStatus,
    buyPrice,
    setBuyPrice,
    sellingPrice,
    setSellingPrice,
    transferDate,
    setTransferDate,
    dateOpened,
    setDateOpened,
    clawbackDate,
    setClawbackDate,
    clawbackClaim,
    setClawbackClaim,
    clawbackExtensionDate,
    setClawbackExtensionDate,
    sourcePaidDate,
    setSourcePaidDate,
    sourcePaidStatusTo,
    setSourcePaidStatusTo,
    rollbackDate,
    setRollbackDate,
    signupDate,
    setSignupdate,
    retainedDate,
    setRetainedDate,
    atePaidDate,
    setAtePaidDate,
    ateProvider,
    setAteProvider,
    atePaidAmount,
    setAtePaidAmount,
    introducerPaidDate,
    setIntroducerPaidDate,
    introducerPaidAmount,
    setIntroducerPaidAmount,
    packDate,
    setPackDate,
    acceptDate,
    setAcceptDate,
    rejectDate,
    setRejectDate,
    paymentAuthorization,
    setPaymentAuthorization,
    paymentNotAuthorizedReason,
    setPaymentNotAuthorizedReason,
    PIVDOutlay,
    setPIVDOutlay,
    chaseDate,
    setChaseDate,
    chaseTime,
    setChaseTime,
    brChaseDate,
    setBrChaseDate,
    brChaseHandler,
    setBrChaseHandler,
    
    isAddNotActive, 
    setIsAddNotActive, 
    isAddAsReminderActive, 
    setIsAddAsReminderActive,
    isSendEmailActive, 
    setIsSendEmailActive,
    isSendSMSActive, 
    setIsSendSMSActive,
    
    noteDescription, 
    setNoteDescription,
    addNoteTo,
    setAddNoteTo,
    addAsReminderDate,
    setAddAsReminderDate,
    sendEmailTo,
    setSendEmailTo,
    sendSMSTo,
    setSendSMSTo,
    sendSMSMobile, 
    setSendSMSMobile,
  };

  return <personalInjuryContext.Provider value={values}>{children}</personalInjuryContext.Provider>;
};

export default PersonalInjuryContextProvider;
