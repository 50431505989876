import React, { memo } from "react";
import { Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { useUserContext } from "../../../context/security/users";
import { validateAlphaNumeric, validateNumericInput } from "../../../utils/validations";

const BankingDetails = memo(() => {
  const {
    // Banking Details
    bankName,
    setBankName,
    accountNumber,
    setAccountNumber,
    cardName,
    setCardName,
    bankingPostCode,
    setBankingPostCode,

    // Repairer Banking Details
    repairerBankName,
    setRepairerBankName,
    repairerCardName,
    setRepairerCardName,
    repairerAccountNumber,
    setRepairerAccountNumber,
    repairerPostCode,
    setRepairerPostCode,

    // errors
    bankingDetailsErrors,
    setBankingDetailsErrors,
    validateBankingDetailsErrors,
  } = useUserContext();

  return (
    <Form>
      <h4 className="mb-2">Banking Details</h4>
      <Row>
        <Col md={6}>
          <FormGroup>
            <Label for="bankName">Bank Name</Label>
            <Input type="text" autoComplete="off" name="bankName" id="bankName" value={bankName} onChange={(e) => setBankName(e.target.value)} />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for="accountNumber">Account Number</Label>
            <Input
              type="text"
              autoComplete="off"
              name="accountNumber"
              id="accountNumber"
              value={accountNumber}
              // className={`${bankingDetailsErrors.hireAccountNumber && "is-invalid"}`}
              onChange={(e) => {
                const value = e.target.value;
                setAccountNumber(value);
                // setBankingDetailsErrors((pre) => ({
                //   ...pre,
                //   hireAccountNumber: value && !validateNumericInput(value) ? "Invalid account number!" : "",
                // }));
              }}
            />
            {/* <small className="text-danger">{bankingDetailsErrors.hireAccountNumber}</small> */}
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <FormGroup>
            <Label for="postCode">Sort Code</Label>
            <Input
              type="text"
              autoComplete="off"
              name="postCode"
              id="postCode"
              // className={`${bankingDetailsErrors.hirePostCode && "is-invalid"}`}
              value={bankingPostCode}
              onChange={(e) => {
                const value = e.target.value;
                setBankingPostCode(value);
                // setBankingDetailsErrors((pre) => ({
                //   ...pre,
                //   hirePostCode: value && !validateAlphaNumeric(value) ? "Invalid post code!" : "",
                // }));
              }}
            />
            {/* <small className="text-danger">{bankingDetailsErrors.hirePostCode}</small> */}
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for="cardName">Name on card</Label>
            <Input type="text" autoComplete="off" name="cardName" id="cardName" value={cardName} onChange={(e) => setCardName(e.target.value)} />
          </FormGroup>
        </Col>
      </Row>

      <h4 className="my-4">Repairer Banking Details</h4>

      <Row>
        <Col md={6}>
          <FormGroup>
            <Label for="repairBankName">Bank Name</Label>
            <Input type="text" autoComplete="off" name="repairBankName" id="repairBankName" value={repairerBankName} onChange={(e) => setRepairerBankName(e.target.value)} />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for="repairAccountNumber">Account Number</Label>
            <Input
              type="text"
              autoComplete="off"
              name="repairAccountNumber"
              id="repairAccountNumber"
              // className={`${bankingDetailsErrors.repairerAccountNumber && "is-invalid"}`}
              value={repairerAccountNumber}
              onChange={(e) => {
                const value = e.target.value;
                setRepairerAccountNumber(value);
                // setBankingDetailsErrors((pre) => ({
                //   ...pre,
                //   repairerAccountNumber: value && !validateNumericInput(value) ? "Invalid account number!" : "",
                // }));
              }}
            />
            {/* <small className="text-danger">{bankingDetailsErrors.repairerAccountNumber}</small> */}
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <FormGroup>
            <Label for="repairSortOrder">Sort Code</Label>
            <Input
              type="text"
              autoComplete="off"
              name="repairSortOrder"
              id="repairSortOrder"
              value={repairerPostCode}
              // className={`${bankingDetailsErrors.repairerPostCode && "is-invalid"}`}
              onChange={(e) => {
                const value = e.target.value;
                setRepairerPostCode(value);
                // setBankingDetailsErrors((pre) => ({
                //   ...pre,
                //   repairerPostCode: value && !validateAlphaNumeric(value) ? "Invalid post code!" : "",
                // }));
              }}
            />
            {/* <small className="text-danger">{bankingDetailsErrors.repairerPostCode}</small> */}
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for="repairCardName">Name on card</Label>
            <Input type="text" autoComplete="off" name="repairCardName" id="repairCardName" value={repairerCardName} onChange={(e) => setRepairerCardName(e.target.value)} />
          </FormGroup>
        </Col>
      </Row>
    </Form>
  );
});

export default BankingDetails;
