import React, { useEffect, useState } from "react";
import instance from "../../axiosInstance";
import { useProfileContext } from "../../context/profile";
import useError from "../../hooks/useError";
import { toggleSplashState } from "../../utils/UI";
import Chat from "../Dashboards/Chat";

const Content = ({ children }) => {
  const { handleError } = useError();
  const { profile, setProfile } = useProfileContext();
  const [isLoading, setIsLoading] = useState(false);

  const getProfile = async () => {
    try {
      toggleSplashState();
      setIsLoading(true);
      const { data } = await instance.get("/user/profile");
      setProfile(data.data);
    } catch (error) {
      handleError(error);
    } finally {
      toggleSplashState();
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!profile) {
      getProfile();
    }
  }, []);

  return (
    !isLoading && (
      <div className="content">
        {children}
        <Chat />
      </div>
    )
  );
};

export default Content;
