import React, { memo } from "react";
import { Label } from "reactstrap";
import { faAsterisk } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const RequiredLabel = memo(({ children, htmlFor }) => {
  return (
    <Label for={htmlFor} className="d-flex align-items-center">
      <FontAwesomeIcon icon={faAsterisk} size="xs" color="red" /> <span className="ml-1">{children}</span>
    </Label>
  );
});

export default RequiredLabel;
