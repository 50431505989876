import React, { memo, useEffect, useState } from "react";
import { CardText, Col, Label, Input } from "reactstrap";
import { useRoleContext } from "../../../context/security/roles";

const RoleFormItem = memo(({ name, values }) => {
  const { permissionsGroup, setPermissionsGroup } = useRoleContext();
  const [checkAll, setCheckAll] = useState(false);

  const handleCheckboxChange = (route, permissionId) => {
    const updatedPermissionsGroup = { ...permissionsGroup };
    updatedPermissionsGroup[route][permissionId] = updatedPermissionsGroup[route][permissionId] === 1 ? 0 : 1;
    setPermissionsGroup(updatedPermissionsGroup);
  };

  const handleCheckAllChange = () => {
    const updatedPermissionsGroup = { ...permissionsGroup };
    values.forEach((value) => {
      updatedPermissionsGroup[value.route][value.permission_id] = !checkAll ? 1 : 0;
    });
    setPermissionsGroup(updatedPermissionsGroup);
    setCheckAll(!checkAll);
  };

  const areAllCheckboxesChecked = () => values.every((value) => permissionsGroup[value.route][value.permission_id] === 1);

  useEffect(() => {
    setCheckAll(areAllCheckboxesChecked());
  }, [permissionsGroup]);

  return (
    <div className="border mb-2">
      <CardText tag="h5" className="p-2 border-bottom">
        <Label check className="d-flex align-items-center ml-4">
          <Input type="checkbox" checked={checkAll} onChange={handleCheckAllChange} />
          <span className="mt-1">{name}</span>
        </Label>
      </CardText>

      <div className="ml-4 p-2 h-150 overflow-auto">
        {values.map((value) => (
          <Col className="mb-2" key={value.control_access_id}>
            <Label check>
              <Input type="checkbox" checked={permissionsGroup[value.route][value.permission_id] === 1} onChange={() => handleCheckboxChange(value.route, value.permission_id)} />
              {value.permission_name}
            </Label>
          </Col>
        ))}
      </div>
    </div>
  );
});

export default RoleFormItem;
