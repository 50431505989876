import React, { createContext, useContext, useState } from "react";

export const addNewContext = createContext({});
export const useAddNewContext = () => useContext(addNewContext);

const AddNewCaseContextProvider = ({ children }) => {
  const [title, setTitle] = useState("");
  const [first_name, setFirstName] = useState("");
  const [middle_name, setMiddleName] = useState("");
  const [last_name, setLastName] = useState("");
  const [mobile_no, setMobilePhone] = useState("");
  const [phone_no, setPhoneNo] = useState("");
  const [case_type, setCaseType] = useState(null);
  const [service_type_personal_injury, setService_type_personal_injury] = useState(0);
  const [service_type_hire, setService_type_hire] = useState(0);
  const [service_type_repair, setService_type_repair] = useState(0);
  const [service_type_storage, setService_type_storage] = useState(0);
  const [service_type_recovery, setService_type_recovery] = useState(0);
  const [service_type_inspection, setService_type_inspection] = useState(0);
  const [service_type_outlay, setService_type_outlay] = useState(0);

  const values = {
    title,
    setTitle,
    first_name,
    setFirstName,
    middle_name,
    setMiddleName,
    last_name,
    setLastName,
    mobile_no,
    setMobilePhone,
    phone_no, 
    setPhoneNo,
    case_type,
    setCaseType,
    service_type_personal_injury,
    setService_type_personal_injury,
    service_type_hire,
    setService_type_hire,
    service_type_repair,
    setService_type_repair,
    service_type_storage,
    setService_type_storage,
    service_type_recovery,
    setService_type_recovery,
    service_type_inspection,
    setService_type_inspection,
    service_type_outlay,
    setService_type_outlay,
  };

  return <addNewContext.Provider value={values}>{children}</addNewContext.Provider>;
};

export default AddNewCaseContextProvider;
