import React, { memo } from "react";
import ReactPaginate from "react-paginate";
import { Button, UncontrolledTooltip } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";

const PaginationWithState = memo(({ paginationInfo, page, setPage, hideInfo = false }) => {
  const { currentPage, totalPages, totalRecords, limit } = paginationInfo;
  const startIndex = (currentPage - 1) * limit + 1;
  const endIndex = Math.min(currentPage * limit, totalRecords);

  const handlePageChange = (selectedPage) => {
    setPage(selectedPage.selected + 1);
  };

  return (
    <div className={"d-flex justify-content-center align-items-center"}>
      {hideInfo ? null : (
        <h6>
          Showing {startIndex} to {endIndex} of {totalRecords} entries
        </h6>
      )}

      <ReactPaginate
        previousLabel={
          <Button color="primary" id="previousPage">
            <FontAwesomeIcon icon={faAngleLeft} />
            <UncontrolledTooltip target="previousPage">Previous</UncontrolledTooltip>
          </Button>
        }
        nextLabel={
          <Button color="primary" id="nextPage">
            <FontAwesomeIcon icon={faAngleRight} />
            <UncontrolledTooltip target="nextPage">Next</UncontrolledTooltip>
          </Button>
        }
        breakLabel={"..."}
        pageCount={totalPages}
        forcePage={page - 1}
        marginPagesDisplayed={2}
        pageRangeDisplayed={2}
        onPageChange={handlePageChange}
        containerClassName={"pagination justify-content-center"}
        activeClassName={"active"}
        previousClassName={currentPage === 0 ? "previous disabled" : "previous"}
        nextClassName={currentPage === totalPages ? "previous disabled" : "next"}
        disabledClassName="disabled"
      />
    </div>
  );
});

export default PaginationWithState;
