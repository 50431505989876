import React, { memo } from "react";

const TableFeedback = memo(({ message, colSpan }) => {
  return (
    <tr>
      <td colSpan={colSpan}>
        <div className="h-130 d-flex justify-content-center align-items-center">
          <h4>{message}</h4>
        </div>
      </td>
    </tr>
  );
});

export default TableFeedback;
