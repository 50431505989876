import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { NavLink, useHistory, withRouter, Link } from "react-router-dom";

import { Badge, Collapse } from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import IosPulse from "react-ionicons/lib/IosPulse";

import { faChartLine } from "@fortawesome/free-solid-svg-icons";
import { useProfileContext } from "../../context/profile";
import Dashboard from "../../pages/Dashboard";
import routes from "../../routes/index";
import PlaceholderImage from "../../assets/img/avatars/loginPlaceholder.jfif";

const initOpenRoutes = (location) => {
  /* Open collapse element that matches current url */
  const pathName = location.pathname;

  let _routes = {};

  routes.forEach((route, index) => {
    const isActive = pathName.indexOf(route.path) === 0;
    const isOpen = route.open;
    const isHome = route.containsHome && pathName === "/" ? true : false;

    _routes = Object.assign({}, _routes, {
      [index]: isActive || isOpen || isHome,
    });
  });

  return _routes;
};

const SidebarCategory = withRouter(({ name, badgeColor, badgeText, icon, isOpen, children, onClick, location, to }) => {
  const getSidebarItemClass = (path) => {
    return location.pathname.indexOf(path) !== -1 || (location.pathname === "/" && path === "/dashboard") ? "active" : "";
  };

  return (
    <li className={"sidebar-item " + getSidebarItemClass(to)}>
      <span data-toggle="collapse" className={"sidebar-link " + (!isOpen ? "collapsed" : "")} onClick={onClick} aria-expanded={isOpen ? "true" : "false"}>
        <FontAwesomeIcon icon={icon} fixedWidth className="align-middle mr-2" /> <span className="align-middle">{name}</span>
        {badgeColor && badgeText ? (
          <Badge color={badgeColor} size={18} pill className="sidebar-badge">
            {badgeText}
          </Badge>
        ) : null}
      </span>
      <Collapse isOpen={isOpen}>
        <ul id="item" className={"sidebar-dropdown list-unstyled"}>
          {children}
        </ul>
      </Collapse>
    </li>
  );
});

const SidebarItem = withRouter(({ name, badgeColor, badgeText, icon, location, to }) => {
  const getSidebarItemClass = (path) => {
    return location.pathname === path ? "active" : "";
  };

  return (
    <li className={"sidebar-item " + getSidebarItemClass(to)}>
      <NavLink to={to} className="sidebar-link" activeClassName="active">
        {icon ? (
          <React.Fragment>
            <FontAwesomeIcon icon={icon} fixedWidth className="align-middle mr-2" /> <span className="align-middle">{name}</span>
          </React.Fragment>
        ) : (
          name
        )}{" "}
        {badgeColor && badgeText ? (
          <Badge color={badgeColor} size={18} pill className="sidebar-badge">
            {badgeText}
          </Badge>
        ) : null}
      </NavLink>
    </li>
  );
});

const Sidebar = ({ location, sidebar }) => {
  const { profile } = useProfileContext();
  const [openRoutes, setOpenRoutes] = useState(profile ? initOpenRoutes(location) : "");
  const history = useHistory();
  const [permissionRoutes, setPermissionRoutes] = useState([
    {
      path: "/",
      name: "Dashboard",
      icon: faChartLine,
      component: Dashboard,
    },
  ]);

  const toggle = (index) => {
    // Collapse all elements
    Object.keys(openRoutes).forEach((item) => openRoutes[index] || setOpenRoutes((openRoutes) => Object.assign({}, openRoutes, { [item]: false })));

    // Toggle selected element
    setOpenRoutes((openRoutes) => Object.assign({}, openRoutes, { [index]: !openRoutes[index] }));
  };

  useEffect(() => {
    if (profile) {
      if (!profile.permission) {
        return history.push("/");
      }
      const copyRoutes = JSON.parse(JSON.stringify(routes));
      const transformRoutes = copyRoutes.filter((route) => {
        if (route.path === "/security") {
          if (!profile.permission?.roles?.lists && !profile.permission?.user?.lists && !profile.permission?.IPGroups?.lists) {
            return false;
          }

          route.children = route.children.filter((child) => {
            const { roles, IPGroups, user } = profile?.permission;

            if (child.path === "/security/roles" && !roles?.lists) {
              return false;
            }

            if (child.path === "/security/users" && !user?.lists) {
              return false;
            }

            if (child.path === "/security/IPGroups" && !IPGroups?.lists) {
              return false;
            }

            return true;
          });
        }

        if (route.path === "/administration") {
          if (!profile.permission?.case?.lists && !profile.permission?.case?.add && !profile.permission?.selfBilling?.lists) {
            return false;
          }

          route.children = route.children.filter((child) => {
            const { case: cases } = profile.permission;

            if (child.path === "/administration/cases" && !cases?.lists) {
              return false;
            }

            if (child.path === "/administration/addNewCase" && !cases?.add) {
              return false;
            }

            if (child.path === "/administration/selfBilling" && !profile.permission?.selfBilling?.lists) {
              return false;
            }

            return true;
          });
        }

        if (route.path === "/reports") {
          if (!profile.permission?.report) {
            return false;
          }

          const { report } = profile.permission;

          if (
            !report?.chase &&
            !report?.rollback &&
            !report?.outlay &&
            !report?.deligance &&
            !report?.accessory &&
            !report?.pi &&
            !report?.hire &&
            !report?.repair &&
            !report?.storage &&
            !report?.recovery &&
            !report?.user
          ) {
            return false;
          }

          route.children = route.children.filter((child) => {
            if (child.path === "/reports/chaseReport" && !report?.chase) {
              return false;
            }
            if (child.path === "/reports/rollbackReport" && !report?.rollback) {
              return false;
            }
            if (child.path === "/reports/outlayReport" && !report?.outlay) {
              return false;
            }
            if (child.path === "/reports/dueDiligenceReport" && !report?.deligance) {
              return false;
            }
            if (child.path === "/reports/accessoryReport" && !report?.accessory) {
              return false;
            }

            if (child.path === "/reports/PIreport" && !report?.pi) {
              return false;
            }

            if (child.path === "/reports/hireReport" && !report?.hire) {
              return false;
            }

            if (child.path === "/reports/repairReport" && !report?.repair) {
              return false;
            }

            if (child.path === "/reports/storageReport" && !report?.storage) {
              return false;
            }

            if (child.path === "/reports/recoveryReport" && !report?.recovery) {
              return false;
            }

            if (child.path === "/reports/usersList" && !report?.user) {
              return false;
            }

            return true;
          });
        }

        if (route.path === "/insurance") {
          if (!profile.permission?.insurance) return false;

          if (!profile.permission?.insurance?.upload && !profile.permission?.insurance?.lists && !profile.permission?.insurance?.addCase) {
            return false;
          }

          route.children = route.children.filter((child) => {
            const { insurance } = profile.permission;

            if (child.path === "/insurance/uploadData" && !insurance?.upload) {
              return false;
            }

            if (child.path === "/insurance/list" && !insurance?.lists) {
              return false;
            }

            if (child.path === "/insurance/newInsurance" && !insurance?.addCase) {
              return false;
            }

            if (child.path === "/insurance/dailyReport" && !insurance?.dailyReport) {
              return false;
            }

            if (child.path === "/insurance/hireReport" && !insurance?.hireReport) {
              return false;
            }

            return true;
          });
        }

        if (route.path === "/workProvider") {
          if (!profile.permission?.leaadSource?.lists && !profile.permission?.leaadSource?.caseLists) {
            return false;
          }

          route.children = route.children.filter((child) => {
            if (child.path === "/workProvider/leadsource" && !profile.permission?.leaadSource?.lists) {
              return false;
            }

            if (child.path === "/workProvider/sourcecases" && !profile.permission?.leaadSource?.caseLists) {
              return false;
            }

            return true;
          });
        }

        if (route.path === "/setup") {
          if (!profile.permission?.city?.lists && !profile.permission?.borough?.lists && !profile.permission?.language?.lists) {
            return false;
          }

          route.children = route.children.filter((child) => {
            if (child.path === "/setup/cities" && !profile.permission?.city?.lists) {
              return false;
            }

            if (child.path === "/setup/boroughs" && !profile.permission?.borough?.lists) {
              return false;
            }

            if (child.path === "/setup/languages" && !profile.permission?.language?.lists) {
              return false;
            }

            return true;
          });
        }

        if (route.path === "/financialAnalysis") {
          if (!profile.permission?.report?.financialReview && !profile.permission?.report?.financialBDM) {
            return false;
          }

          route.children = route.children.filter((child) => {
            if (child.path === "/financialAnalysis/financialReview" && !profile.permission?.report?.financialReview) {
              return false;
            }

            if (child.path === "/financialAnalysis/financialAnalysisBDM" && !profile.permission?.report?.financialBDM) {
              return false;
            }

            return true;
          });
        }

        if (route.path === "/paymentReports") {
          if (
            !profile.permission?.report?.paymentAccessory &&
            !profile.permission?.report?.paymentNotPaid &&
            !profile.permission?.report?.paymentReport &&
            !profile.permission?.report?.paymentAuthorization
          ) {
            return false;
          }

          route.children = route.children.filter((child) => {
            if (child.path === "/paymentReports/accessoryReport" && !profile.permission?.report?.paymentAccessory) {
              return false;
            }

            if (child.path === "/paymentReports/notPaidReport" && !profile.permission?.report?.paymentNotPaid) {
              return false;
            }

            if (child.path === "/paymentReports/paymentReport" && !profile.permission?.report?.paymentReport) {
              return false;
            }

            if (child.path === "/paymentReports/paymentAuthorisation" && !profile.permission?.report?.paymentAuthorization) {
              return false;
            }

            return true;
          });
        }

        if (route.path === "/progressReports") {
          if (
            !profile.permission?.report?.progressSolicitor &&
            !profile.permission?.report?.progressHireSupplier
          ) {
            return false;
          }

          route.children = route.children.filter((child) => {
            if (child.path === "/progressReports/solicitorReport" && !profile.permission?.report?.progressSolicitor) {
              return false;
            }

            if (child.path === "/progressReports/hireReport" && !profile.permission?.report?.progressHireSupplier) {
              return false;
            }

            return true;
          });
        }

        return true;
      });

      setPermissionRoutes(transformRoutes);
    }
  }, [profile]);

  return (
    <nav className={classNames("sidebar", sidebar.isOpen || "toggled", !sidebar.isOnRight || "sidebar-right")}>
      <div className="sidebar-content">
        <Link className={classNames("sidebar-brand", !sidebar.isOnRight || "text-right")} to="/">
          {sidebar.isOnRight || <IosPulse />} <span className="align-middle">NASL</span> {!sidebar.isOnRight || <IosPulse />}
        </Link>

        <div className="sidebar-user">
          <img src={profile?.href_user_image || PlaceholderImage} loading="lazy" className="img-fluid rounded-circle mb-2" alt="User Profile" />
          <div className="font-weight-bold">{profile?.full_name}</div>
          <small>{profile?.user_role}</small>
        </div>
        <ul className="sidebar-nav">
          {permissionRoutes.map((category, index) => {
            return (
              <React.Fragment key={index}>
                {category.header ? <li className="sidebar-header">{category.header}</li> : null}

                {profile?.permission && category.children ? (
                  <SidebarCategory
                    name={category.name}
                    badgeColor={category.badgeColor}
                    badgeText={category.badgeText}
                    icon={category.icon}
                    to={category.path}
                    isOpen={openRoutes[index]}
                    onClick={() => toggle(index)}
                  >
                    {category.children.map((route, index) =>
                      route.name ? <SidebarItem key={index} name={route.name} to={route.path} badgeColor={route.badgeColor} badgeText={route.badgeText} /> : null
                    )}
                  </SidebarCategory>
                ) : !profile?.permission && category.path !== "/" ? (
                  <>{category.path}</>
                ) : (
                  <SidebarItem name={category.name} to={category.path} icon={category.icon} badgeColor={category.badgeColor} badgeText={category.badgeText} />
                )}
              </React.Fragment>
            );
          })}
        </ul>
      </div>
    </nav>
  );
};

export default withRouter(
  connect((store) => ({
    sidebar: store.sidebar,
  }))(Sidebar)
);
