import React, { memo, useEffect, useState } from "react";
import LineChart from "./LineChart";
import { Card, CardBody, CardHeader, CardTitle } from "reactstrap";
import { useDeoContext } from "../../context/dashboard/DeoDashboardContext";
import Select from "react-select";

const monthOptions = [
  {
    value: "months_3",
    label: "3 Months",
  },
  {
    value: "months_6",
    label: "6 Months",
  },
  {
    value: "months_9",
    label: "9 Months",
  },
  {
    value: "months_12",
    label: "12 Months",
  },
];

const MonthsCart = memo(() => {
  const { deo } = useDeoContext();
  const [chartData, setChartData] = useState({
    labels: [],
    data: [],
  });

  const handleMonthsChange = (selected) => {
    if (deo) {
      const labels = Object.keys(deo[selected.value]);
      const data = Object.values(deo[selected.value]);
      setChartData({
        labels,
        data,
      });
    }
  };

  useEffect(() => {
    if (deo) {
      const labels = Object.keys(deo.months_12);
      const data = Object.values(deo.months_12);

      setChartData({
        labels,
        data,
      });
    }
  }, [deo?.total_cases]);

  return (
    <Card>
      <CardHeader>
        <div className="d-flex justify-content-between align-items-center">
          <CardTitle tag="h5">Monthly Chart</CardTitle>
          <div className="w-180">
            <Select className="basic-single" classNamePrefix="select" defaultValue={monthOptions[3]} options={monthOptions} onChange={handleMonthsChange} />
          </div>
        </div>
      </CardHeader>
      <CardBody>
        <LineChart title="Cases" labels={chartData.labels} data={chartData.data} />
      </CardBody>
    </Card>
  );
});

export default MonthsCart;
