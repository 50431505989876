import React from "react";
import Select from "react-select";
import { AsyncPaginate } from "react-select-async-paginate";
import { Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import instance from "../../../axiosInstance";
import { useClaimFormContext } from "../../../context/administration/claimForm";
import { validateDecimalInput } from "../../../utils/validations";
import ContactInput from "../../UI/forms/ContactInput";
import CustomDate from "../../UI/forms/CustomDate";
import { booleanOptions } from "./ClientDetails";
import { titleOptions } from "../AddNewCase/AddNewCaseForm";

export const registrationKeeperOptions = [
  { value: "Client", label: "Client" },
  { value: "ALL Lead Source", label: "ALL Lead Source" },
  { value: "Other", label: "Other" },
];

const ClientVehicle = ({ caseId, caseType }) => {
  const {
    registration_name,
    setRegistrationName,
    registration_no,
    setRegistrationNo,
    damage_detail,
    setDamageDetail,
    make_model,
    setMakeModel,
    color,
    setColor,
    insurance,
    setInsurance,
    road_worthy,
    setRoadWorthy,
    location,
    setLocation,
    recovered_date,
    setRecoveredDate,
    insurance_type,
    setInsuranceType,
    vehicle_condition,
    setVehicleCondition,
    registration_contact,
    setRegistrationContact,
    v5_available,
    setV5Available,
    insurance_available,
    setInsuranceAvailable,
    driving_license_available,
    setDrivingLicenseAvailable,
    require_rack_box,
    setRequireRackBox,
    require_top_box,
    setRequireTopBox,
    top_box_type,
    setTopBoxType,
    helmet_useable,
    setHelmetUseable,
    using_accessories,
    setUsingAccessories,
    can_transfer_accessories,
    setCanTransferAccessories,
    hot_grips_amount,
    setHotGripsAmount,
    wind_screen_amount,
    setWindScreenAmount,
    muffs_amount,
    setMuffsAmount,
    leg_cover_amount,
    setLegCoverAmount,
    mobile_charger_amount,
    setMobileChargerAmount,
    collect_bike_from_fenchurch,
    setCollectBikeFromFenchurch,
    collect_125cc_from_fenchurch,
    setCollect125ccFromFenchurch,
    rk_title, 
    set_rk_title,
    rk_first_name, 
    set_rk_firstName,
    rk_middle_name, 
    set_rk_middleName,
    rk_last_name, 
    set_rk_lastName,
    rk_postal_code, 
    set_rk_postalCode,
    rk_email_address, 
    set_rk_emailAddress,
    rk_date_of_birth, 
    set_rk_dateOfBirth,
    rk_ni_number, 
    set_rk_niNumber,
    rk_occupation, 
    set_rk_occupation,
    clientVehicleValidation,
    setClientVehicleValidation,
  } = useClaimFormContext();

  //fetching Combos/ Dropdown Options
  let fetchedInsuranceTypesOptions = [];
  const fetchInsuranceTypesOptions = async (search) => {
    try {
      if (search && fetchedInsuranceTypesOptions.length) {
        const filteredOptions = fetchedInsuranceTypesOptions.filter((option) => option.label.toLowerCase().includes(search.toLowerCase()));
        return {
          options: filteredOptions,
          hasMore: false,
        };
      }

      const res = await instance.get("/lookups/insuranceTypes");
      const { data } = res.data;
      const options = data.map((status) => ({
        value: status,
        label: status,
      }));

      fetchedInsuranceTypesOptions = options;

      return {
        options,
        hasMore: false,
      };
    } catch (error) {
      return {
        options: [],
        hasMore: false,
      };
    }
  };

  let fetchedBoxTypesOptions = [];
  const fetchBoxTypesOptions = async (search) => {
    try {
      if (search && fetchedBoxTypesOptions.length) {
        const filteredOptions = fetchedBoxTypesOptions.filter((option) => option.label.toLowerCase().includes(search.toLowerCase()));
        return {
          options: filteredOptions,
          hasMore: false,
        };
      }

      const res = await instance.get("/lookups/boxTypes");
      const { data } = res.data;
      const options = data.map((status) => ({
        value: status,
        label: status,
      }));

      fetchedBoxTypesOptions = options;

      return {
        options,
        hasMore: false,
      };
    } catch (error) {
      return {
        options: [],
        hasMore: false,
      };
    }
  };

  return (
    <Form>
      <h4>Client Vehicle Details</h4>
      <Row>
        <Col md={4}>
          <FormGroup>
            <Label for="registrationName">Registered Keeper</Label>
            <Select id="registrationName" value={registration_name} onChange={(value) => setRegistrationName(value)} className="basic-single" classNamePrefix="select" options={registrationKeeperOptions} />
            {/* <Input type="text" autoComplete="off" id="registrationName" value={registration_name} onChange={(e) => setRegistrationName(e.target.value)} /> */}
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="registrationNo">Registration Number</Label>
            <Input type="text" autoComplete="off" id="registrationNo" value={registration_no} onChange={(e) => setRegistrationNo(e.target.value)} />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="damageDetails">Damage to Vehicle</Label>
            <Input type="textarea" autoComplete="off" rows="1" id="damageDetails" value={damage_detail} onChange={(e) => setDamageDetail(e.target.value)} />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="makeModel">Make/Model</Label>
            <Input type="text" autoComplete="off" id="makeModel" value={make_model} onChange={(e) => setMakeModel(e.target.value)} />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="color">Color</Label>
            <Input type="text" autoComplete="off" id="color" value={color} onChange={(e) => setColor(e.target.value)} />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="insurance">Insurance</Label>
            <Input type="text" autoComplete="off" id="insurance" value={insurance} onChange={(e) => setInsurance(e.target.value)} />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="roadWorthy">Is the vehicle Roadworthy?</Label>
            <Select id="roadWorthy" value={road_worthy} onChange={(value) => setRoadWorthy(value)} className="basic-single" classNamePrefix="select" options={booleanOptions} />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="location"> Where is the vehicle at the moment? </Label>
            <Input type="text" autoComplete="off" id="location" value={location} onChange={(e) => setLocation(e.target.value)} />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="recoveredDate">Vehicle Recovered Date</Label>
            <Col md={6} className="p-0">
              <CustomDate startDate={recovered_date} setStartDate={setRecoveredDate} id="recoveredDate" />
            </Col>
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="insurancetype"> Insurance Type </Label>
            <AsyncPaginate
              className="basic-single"
              classNamePrefix="select"
              isSearchable
              name="insurancetype"
              loadOptions={fetchInsuranceTypesOptions}
              additional={{
                page: 1,
              }}
              debounceTimeout={300}
              value={insurance_type}
              onChange={(value) => setInsuranceType(value)}
            />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="vehicleCondition"> How did the bike leave the scene of the accident? </Label>
            <Input type="textarea" autoComplete="off" rows="1" id="vehicleCondition" value={vehicle_condition} onChange={(e) => setVehicleCondition(e.target.value)} />
          </FormGroup>
        </Col>
        <Col md={4}>
          <ContactInput
            label="Registered Keeper Contact"
            name="registrationContact"
            value={registration_contact}
            onChange={setRegistrationContact}
            isInvalid={clientVehicleValidation.registeredKeeperContact}
            setError={[setClientVehicleValidation, "registeredKeeperContact"]}
          />
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="v5Available">Do you have a hard copy of your V5?</Label>
            <Select id="v5Available" className="basic-single" classNamePrefix="select" value={v5_available} onChange={(value) => setV5Available(value)} options={booleanOptions} />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="insuranceAvailable"> Do you have the hard copy of your insurance certificate? </Label>
            <Select id="insuranceAvailable" className="basic-single" classNamePrefix="select" value={insurance_available} onChange={(value) => setInsuranceAvailable(value)} options={booleanOptions} />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="drivingLicenseAvailable"> Do you have a hard copy of your driving license? </Label>
            <Select
              id="drivingLicenseAvailable"
              className="basic-single"
              classNamePrefix="select"
              value={driving_license_available}
              onChange={(value) => setDrivingLicenseAvailable(value)}
              options={booleanOptions}
            /> 
          </FormGroup>
        </Col>

        {registration_name?.label === "Other" && (
          <>
            <Col md={4}>
              <FormGroup>
                <Label for="rk_title">Title</Label>
                <Select
                  className={`basic-single`}
                  classNamePrefix="select"
                  id="rk_title"
                  name="rk_title"
                  value={rk_title}
                  options={titleOptions}
                  onChange={(value) => {
                    set_rk_title(value);
                  }}
                />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label for="rk_first_name">First Name</Label>
                <Input type="text" id="rk_first_name" autoComplete="off" value={rk_first_name} onChange={(e) => set_rk_firstName(e.target.value)} />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label for="rk_middle_name">Middle Name</Label>
                <Input type="text" id="rk_middle_name" autoComplete="off" value={rk_middle_name} onChange={(e) => set_rk_middleName(e.target.value)} />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label for="rk_last_name">Last Name</Label>
                <Input type="text" id="rk_last_name" autoComplete="off" value={rk_last_name} onChange={(e) => set_rk_lastName(e.target.value)} />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label for="rk_postal_code">Post Code</Label>
                <Input type="text" id="rk_postal_code" autoComplete="off" value={rk_postal_code} onChange={(e) => set_rk_postalCode(e.target.value)} />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label for="rk_email_address">Email Address</Label>
                <Input type="email" id="rk_email_address" autoComplete="off" value={rk_email_address} onChange={(e) => set_rk_emailAddress(e.target.value)} />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label for="rk_date_of_birth">Date of Birth</Label> <br />
                <CustomDate id="rk_date_of_birth" startDate={rk_date_of_birth} setStartDate={set_rk_dateOfBirth} />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label for="rk_ni_number">NI Number</Label>
                <Input type="text" id="rk_ni_number" autoComplete="off" value={rk_ni_number} onChange={(e) => set_rk_niNumber(e.target.value)} />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label for="rk_occupation">Occupation</Label>
                <Input type="text" id="rk_occupation" autoComplete="off" value={rk_occupation} onChange={(e) => set_rk_occupation(e.target.value)} />
              </FormGroup>
            </Col>
          </>
        )}

        {caseType === "RTA Motorcycle" && (
          <>
            <Col md={4}>
              <FormGroup>
                <Label for="requireRackBox"> Do you require a rack for a box? </Label>
                <Select id="requireRackBox" className="basic-single" classNamePrefix="select" value={require_rack_box} onChange={(value) => setRequireRackBox(value)} options={booleanOptions} />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label for="requireTopBox"> Do you require a top box or pizza box? </Label>
                <Select id="requireTopBox" className="basic-single" classNamePrefix="select" value={require_top_box} onChange={(value) => setRequireTopBox(value)} options={booleanOptions} />
              </FormGroup>
            </Col>
            {require_top_box.value === "Yes" && (
              <Col md={4}>
                <FormGroup>
                  <Label for="topBoxType"> Select Box Type </Label>
                  <AsyncPaginate
                    className="basic-single"
                    classNamePrefix="select"
                    isSearchable
                    name="topBoxType"
                    loadOptions={fetchBoxTypesOptions}
                    additional={{
                      page: 1,
                    }}
                    debounceTimeout={300}
                    value={top_box_type}
                    onChange={(value) => setTopBoxType(value)}
                  />
                </FormGroup>
              </Col>
            )}
            <Col md={4}>
              <FormGroup>
                <Label for="helmetUseable"> Are you able to use your helmet? </Label>
                <Select id="helmetUseable" className="basic-single" classNamePrefix="select" value={helmet_useable} onChange={(value) => setHelmetUseable(value)} options={booleanOptions} />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label for="usingAccessories"> Are you using any accessories at? </Label>
                <Select id="usingAccessories" className="basic-single" classNamePrefix="select" value={using_accessories} onChange={(value) => setUsingAccessories(value)} options={booleanOptions} />
              </FormGroup>
            </Col>
            {using_accessories.value === "Yes" && (
              <>
                <Col md={4}>
                  <FormGroup>
                    <Label for="canTransferAccessories"> Are you able to transfer your own accessories to the hire bike? </Label>
                    <Select
                      id="canTransferAccessories"
                      className="basic-single"
                      classNamePrefix="select"
                      value={can_transfer_accessories}
                      onChange={(value) => setCanTransferAccessories(value)}
                      options={booleanOptions}
                    />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <Label for="hotGripsAmount"> Hot Grips </Label>
                    <Input
                      type="text"
                      autoComplete="off"
                      id="hotGripsAmount"
                      value={hot_grips_amount}
                      className={clientVehicleValidation.hotGripsAmount ? "is-invalid" : ""}
                      onChange={(e) => {
                        const { value } = e.target;
                        setHotGripsAmount(value);
                        setClientVehicleValidation({
                          ...clientVehicleValidation,
                          hotGripsAmount: !value || validateDecimalInput(value) ? "" : "Invalid amount",
                        });
                      }}
                    />
                    <small className="text-danger">{clientVehicleValidation.hotGripsAmount}</small>
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <Label for="windScreenAmount"> Windscreen </Label>
                    <Input
                      type="text"
                      autoComplete="off"
                      id="windScreenAmount"
                      value={wind_screen_amount}
                      className={clientVehicleValidation.windScreenAmount ? "is-invalid" : ""}
                      onChange={(e) => {
                        const { value } = e.target;
                        setWindScreenAmount(value);
                        setClientVehicleValidation({
                          ...clientVehicleValidation,
                          windScreenAmount: !value || validateDecimalInput(value) ? "" : "Invalid amount",
                        });
                      }}
                    />
                    <small className="text-danger">{clientVehicleValidation.windScreenAmount}</small>
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <Label for="muffsAmount"> Muffs </Label>
                    <Input
                      type="text"
                      id="muffsAmount"
                      autoComplete="off"
                      value={muffs_amount}
                      className={clientVehicleValidation.muffsAmount ? "is-invalid" : ""}
                      onChange={(e) => {
                        const { value } = e.target;
                        setMuffsAmount(value);
                        setClientVehicleValidation({
                          ...clientVehicleValidation,
                          muffsAmount: !value || validateDecimalInput(value) ? "" : "Invalid amount",
                        });
                      }}
                    />
                    <small className="text-danger">{clientVehicleValidation.muffsAmount}</small>
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <Label for="legCoverAmount"> Leg Cover </Label>
                    <Input
                      type="text"
                      autoComplete="off"
                      id="legCoverAmount"
                      className={clientVehicleValidation.legCoverAmount ? "is-invalid" : ""}
                      value={leg_cover_amount}
                      onChange={(e) => {
                        const { value } = e.target;
                        setLegCoverAmount(value);
                        setClientVehicleValidation({
                          ...clientVehicleValidation,
                          legCoverAmount: !value || validateDecimalInput(value) ? "" : "Invalid amount",
                        });
                      }}
                    />
                    <small className="text-danger">{clientVehicleValidation.legCoverAmount}</small>
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <Label for="mobileChargerAmount"> Mobile Phone Charger </Label>
                    <Input
                      type="text"
                      autoComplete="off"
                      id="mobileChargerAmount"
                      value={mobile_charger_amount}
                      className={clientVehicleValidation.mobileChargerAmount ? "is-invalid" : ""}
                      onChange={(e) => {
                        const { value } = e.target;
                        setMobileChargerAmount(value);
                        setClientVehicleValidation({
                          ...clientVehicleValidation,
                          mobileChargerAmount: !value || validateDecimalInput(value) ? "" : "Invalid amount",
                        });
                      }}
                    />
                    <small className="text-danger">{clientVehicleValidation.mobileChargerAmount}</small>
                  </FormGroup>
                </Col>
              </>
            )}
            <Col md={4}>
              <FormGroup>
                <Label for="collectBikeFromFenchurch"> Are you happy to collect the bike from Fenchurch Street? </Label>
                <Select
                  id="collectBikeFromFenchurch"
                  className="basic-single"
                  classNamePrefix="select"
                  value={collect_bike_from_fenchurch}
                  onChange={(value) => setCollectBikeFromFenchurch(value)}
                  options={booleanOptions}
                />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label for="collect125ccFromFenchurch"> Are you happy to collect from fenchurch street? (IF 125CC) </Label>
                <Select
                  id="collect125ccFromFenchurch"
                  className="basic-single"
                  classNamePrefix="select"
                  value={collect_125cc_from_fenchurch}
                  onChange={(value) => setCollect125ccFromFenchurch(value)}
                  options={booleanOptions}
                />
              </FormGroup>
            </Col>
          </>
        )}

      </Row>
    </Form>
  );
};

export default ClientVehicle;
