import React, { createContext, useContext, useState } from "react";

const deoDashboardContext = createContext({});
export const useDeoContext = () => useContext(deoDashboardContext);

const DeoDashboardContextProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [deo, setDeo] = useState(null);

  return <deoDashboardContext.Provider value={{ deo, setDeo, isLoading, setIsLoading }}>{children}</deoDashboardContext.Provider>;
};

export default DeoDashboardContextProvider;
