import React from "react";
import DeoDashboard from "../../components/Dashboards/DeoDashboard";
import HireSupplierDashboard from "../../components/Dashboards/HireSupplierDashboard";
import { useProfileContext } from "../../context/profile";
import DeoDashboardContextProvider from "../../context/dashboard/DeoDashboardContext";
import AdminDashboard from "../../components/Dashboards/AdminDashboard";

export const isTypeAvailable = (type, equal) => {
  if (!type) return;
  return type.includes(",") ? type.split(",").includes(equal) : type === equal;
};

const Dashboard = () => {
  const { profile } = useProfileContext();

  if (isTypeAvailable(profile?.user_type, "Manager") || isTypeAvailable(profile?.user_type, "Admin") || isTypeAvailable(profile?.user_type, "Super Admin")) {
    return <AdminDashboard />;
  } else if (isTypeAvailable(profile?.user_type, "BDO")) {
    return <HireSupplierDashboard />;
  } else if (isTypeAvailable(profile?.user_type, "DEO")) {
    return (
      <DeoDashboardContextProvider>
        <DeoDashboard />
      </DeoDashboardContextProvider>
    );
  } else {
    return null;
  }
};

export default Dashboard;
