import React, { memo } from "react";
import DatePicker from "react-datepicker";
import Select from "react-select";

export function range(start, stop, step = 1) {
  const result = [];
  for (let i = start; i < stop; i += step) {
    result.push(i);
  }
  return result;
}

const getYear = (date) => date.getFullYear();

const MonthPicker = memo(({ startDate, setStartDate, id = "", className = "", placeholder }) => {
  const years = range(1990, getYear(new Date()) + 1, 1).reverse();
  const yearOptions = years.map((year) => ({ value: year, label: year }));

  return (
    <DatePicker
      dateFormat="MM-yyyy"
      id={id}
      className={`form-control ${className}`}
      placeholderText={placeholder || "Select Month"}
      renderCustomHeader={({ date, changeYear }) => (
        <div className="px-4">
          <Select
            className="basic-single w-100"
            classNamePrefix="select"
            value={{ value: getYear(date), label: getYear(date) }}
            onChange={(selectedOption) => changeYear(selectedOption.value)}
            options={yearOptions}
          />
        </div>
      )}
      selected={startDate}
      showMonthYearPicker
      onChange={(date) => setStartDate(date)}
    />
  );
});

export default MonthPicker;
