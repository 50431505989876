import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { toastr } from "react-redux-toastr";
import { Link, useParams } from "react-router-dom";
import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import instance from "../../../axiosInstance";
import { useUpdateFormContext } from "../../../context/administration/updateForm";
import { useStorageContext } from "../../../context/administration/updateForm/storage";
import useError from "../../../hooks/useError";
import { toDateObj, toYYYYMMDD } from "../../../utils/forms";
import LoadingButton from "../../UI/LoadingButton";
import CustomDate from "../../UI/forms/CustomDate";
import Notes from "./Notes";
import ParamsLink from "../../UI/links/ParamsLink";
import { validatePhoneNumber } from "../../../utils/validations";
import { AsyncPaginate } from "react-select-async-paginate";
import { loadLeadSourceOptions } from "./Hire";

const Storage = () => {
  const { formId } = useParams();
  const { formData, getNotes } = useUpdateFormContext();
  const [isUpdating, setIsUpdating] = useState(false);
  const { handleError } = useError();

  const {
    userType, 
    setUserType,
    source_id,
    setSourceId,
    checkin_date,
    setCheckInDate,
    storage_with,
    setStorageWith,
    nasl_paid_date,
    setNaslPaidDate,
    nasl_paid_status,
    setNaslPaidStatus,
    nasl_paid_amount,
    setNaslPaidAmount,
    source_paid_date,
    setSourcePaidDate,
    source_paid_status,
    setSourcePaidStatus,
    source_paid_amount,
    setSourcePaidAmount,
    completed_date,
    setCompleteDate,
    invoice_with,
    setInvoiceWith,

    // -----note's values-------
    isAddNotActive,
    setIsAddNotActive,
    isAddAsReminderActive,
    setIsAddAsReminderActive,
    isSendEmailActive,
    setIsSendEmailActive,
    isSendSMSActive,
    setIsSendSMSActive,

    noteDescription,
    setNoteDescription,
    addNoteTo,
    setAddNoteTo,
    addAsReminderDate,
    setAddAsReminderDate,
    sendEmailTo,
    setSendEmailTo,
    sendSMSTo,
    setSendSMSTo,
    sendSMSMobile,
    setSendSMSMobile,
  } = useStorageContext();

  const [validationErrors, setValidationErrors] = useState({
    mobileNumber: "",
    clientNumber: "",
  });

  const clearNoteFields = () => {
    setIsAddNotActive(false);
    setIsAddAsReminderActive(false);
    setIsSendEmailActive(false);
    setIsSendSMSActive(false);
    setNoteDescription("");
    setAddNoteTo("");
    setAddAsReminderDate(null);
    setSendEmailTo("");
    setSendSMSTo("");
    setSendSMSMobile({
      clientNumber: "",
      mobileNumber: "",
    });
  };

  //Setting Data
  useEffect(() => {
    const setFields = () => {
      if (!formData || !formData.storage) return;

      const { storage } = formData;
      const { user } = formData;

      setUserType(user.user_type)

      setSourceId({ label: storage.source_name, value: storage.source_id });
      setCheckInDate(toDateObj(storage.checkin_date));
      setStorageWith(storage.storage_with);
      setNaslPaidDate(toDateObj(storage.nasl_paid_date));
      setNaslPaidStatus(storage.nasl_paid_status === "Yes");
      setNaslPaidAmount(storage.nasl_paid_amount);
      setSourcePaidDate(toDateObj(storage.source_paid_date));
      setSourcePaidStatus(storage.source_paid_status === "Yes");
      setSourcePaidAmount(storage.source_paid_amount);
      setCompleteDate(toDateObj(storage.completed_date));
      setInvoiceWith(storage.invoice_with);
    };
    setFields();
  }, [formData]);

  //Updatting Data (put API)
  const updatingData = async () => {
    let isInvalid = false;

    if (!validatePhoneNumber(sendSMSMobile.clientNumber)) {
      setValidationErrors((prevErrors) => ({ ...prevErrors, clientNumber: "Invalid client number" }));
      isInvalid = true;
    } else {
      setValidationErrors((prevErrors) => ({ ...prevErrors, clientNumber: "" }));
    }

    if (!validatePhoneNumber(sendSMSMobile.mobileNumber)) {
      setValidationErrors((prevErrors) => ({ ...prevErrors, mobileNumber: "Invalid mobile number" }));
      isInvalid = true;
    } else {
      setValidationErrors((prevErrors) => ({ ...prevErrors, mobileNumber: "" }));
    }

    if (isInvalid) return;

    try {
      setIsUpdating(true);
      const data = {
        source_id: source_id.value,
        checkin_date: toYYYYMMDD(checkin_date),
        storage_with,
        nasl_paid_date: toYYYYMMDD(nasl_paid_date),
        nasl_paid_status: nasl_paid_status ? "Yes" : "No",
        nasl_paid_amount,
        source_paid_date: toYYYYMMDD(source_paid_date),
        source_paid_status: source_paid_status ? "Yes" : "No",
        source_paid_amount,
        completed_date: toYYYYMMDD(completed_date),
        invoice_with,
        note: {
          note_type: "Storage",
          description: noteDescription,
          display_to_deo: addNoteTo.displayToDEO ? 1 : 0,
          display_to_client: addNoteTo.displayToClient ? 1 : 0,
          reminder_date: toYYYYMMDD(addAsReminderDate),
          send_email_source: sendEmailTo.source ? 1 : 0,
          send_email_client: sendEmailTo.client ? 1 : 0,
          send_sms_to: sendSMSTo,
          send_sms_mobile: sendSMSTo === "client" ? sendSMSMobile.clientNumber : sendSMSMobile.mobileNumber,
        },
      };

      await instance.put(`/case/updateStorage/${formId}`, data);
      toastr.success("Success", "Storage updated successfully");
      clearNoteFields();
      getNotes();
    } catch (error) {
      handleError(error);
    } finally {
      setIsUpdating(false);
    }
  };

  // console.log(source_paid_status);

  return (
    <Form>
 
      <div className="position-absolute text-center" style={{ top: '0.5%', right: '1.7%' }}>
        {source_paid_status == 1 ? (
          <h4 className="text-success">PAID</h4>
        ) : (
          <h4 className="text-danger">UNPAID</h4>
        )}
      </div>

      <Row>
        <Col md={4}>
          <FormGroup>
            <Label for="sourceId">Source Name</Label>
            <AsyncPaginate
              className="basic-single"
              classNamePrefix="select"
              isSearchable={true}
              name="sourceId"
              id="sourceId"
              loadOptions={loadLeadSourceOptions}
              additional={{
                page: 1,
              }}
              value={source_id}
              debounceTimeout={300}
              onChange={(selected) => setSourceId(selected)}
            />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="checkInDate">Check In Date</Label>
            <Col md={6} className="p-0">
              <CustomDate startDate={checkin_date} setStartDate={setCheckInDate} id={"checkin_date"} />
            </Col>
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="storageWith">Storage With</Label>
            <Input type="text" id="storageWith" value={storage_with} onChange={(e) => setStorageWith(e.target.value)} />
          </FormGroup>
        </Col>

        {userType === "Super Admin" || userType === "Account" ? (
          <> 
            <Col md={4}>
              <FormGroup>
                <Label for="naslPaidDate">NASL Paid Date</Label>
                <Col md={6} className="p-0">
                  <CustomDate startDate={nasl_paid_date} setStartDate={setNaslPaidDate} id={"nasl_paid_date"} />
                </Col>
                <Label check className="ml-4">
                  <Input type="checkbox" checked={nasl_paid_status} onChange={(e) => setNaslPaidStatus(e.target.checked)} />
                  Storage Paid Status
                </Label>
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label for="naslPaidAmount">NASL Paid Amount</Label>
                <Input type="number" id="naslPaidAmount" value={nasl_paid_amount} onChange={(e) => setNaslPaidAmount(e.target.value)} />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label for="sourcePaidDate">Source Storage Paid Date</Label>
                <Col md={6} className="p-0">
                  <CustomDate startDate={source_paid_date} setStartDate={setSourcePaidDate} id={"source_paid_date"} />
                </Col>
                <Label check className="ml-4">
                  <Input type="checkbox" checked={source_paid_status} onChange={(e) => setSourcePaidStatus(e.target.checked)} />
                  Source Storage Paid Status
                </Label>
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label for="sourceStoragePaidAmount">Source Storage Paid Amount</Label>
                <Input type="number" id="sourceStoragePaidAmount" value={source_paid_amount} onChange={(e) => setSourcePaidAmount(e.target.value)} />
              </FormGroup>
            </Col>
          </>
        ) : (
          <>
            <Col md={4}>
              <FormGroup>
              <Label for="sourcePaidDate">Source Storage Paid Date</Label>
                <Col md={6} className="p-0">
                <CustomDate startDate={source_paid_date} id={"source_paid_date"} disabled/>
                </Col>
              </FormGroup>
            </Col>
          </>
        )}

        <Col md={4}>
          <FormGroup>
            <Label for="storageCompleteDate">Storage Complete Date</Label>
            <Col md={6} className="p-0">
              <CustomDate startDate={completed_date} setStartDate={setCompleteDate} id={"completed_date"} />
            </Col>
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="storageInvoiceWith">Storage Invoice With</Label>
            <Input type="text" id="storageInvoiceWith" value={invoice_with} onChange={(e) => setInvoiceWith(e.target.value)} />
          </FormGroup>
        </Col>
      </Row>

      <Notes
        noteDescription={noteDescription}
        setNoteDescription={setNoteDescription}
        sendSMSTo={sendSMSTo}
        setSendSMSTo={setSendSMSTo}
        addAsReminderDate={addAsReminderDate}
        setAddAsReminderDate={setAddAsReminderDate}
        sendEmailTo={sendEmailTo}
        setSendEmailTo={setSendEmailTo}
        addNoteTo={addNoteTo}
        setAddNoteTo={setAddNoteTo}
        sendSMSMobile={sendSMSMobile}
        setSendSMSMobile={setSendSMSMobile}
        isAddNotActive={isAddNotActive}
        setIsAddNotActive={setIsAddNotActive}
        isAddAsReminderActive={isAddAsReminderActive}
        setIsAddAsReminderActive={setIsAddAsReminderActive}
        isSendEmailActive={isSendEmailActive}
        setIsSendEmailActive={setIsSendEmailActive}
        isSendSMSActive={isSendSMSActive}
        setIsSendSMSActive={setIsSendSMSActive}
        validationErrors={validationErrors}
        setValidationErrors={setValidationErrors}
      />

      <div className="d-flex justify-content-end">
        <ParamsLink to="/administration/cases" className="mr-2">
          <Button color="primary">Exit</Button>
        </ParamsLink>
        <LoadingButton onClick={updatingData} isLoading={isUpdating}>
          Update
        </LoadingButton>
      </div>
    </Form>
  );
};

export default Storage;
