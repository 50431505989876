import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useProfileContext } from "../context/profile";

const useLogout = () => {
  const history = useHistory();
  const [isLoggedOut, setIsLoggedOut] = useState(false);
  const { setProfile } = useProfileContext();

  const logout = () => {
    localStorage.clear();

    setIsLoggedOut(true);
    setProfile(null);
    history.push("/auth/login");
  };

  useEffect(() => {
    if (isLoggedOut) {
      history.push("/auth/login");
    }
  }, [isLoggedOut, history]);

  return { logout };
};

export default useLogout;
