import React, { memo, useEffect, useState } from "react";
import { Card, CardBody, CardHeader, CardTitle } from "reactstrap";
import MonthPicker from "../UI/forms/MonthPicker";
import { Pie } from "react-chartjs-2";
import { connect } from "react-redux";
import useError from "../../hooks/useError";
import instance from "../../axiosInstance";
import { toDateObj } from "../../utils/forms";
import { months, years } from "../UI/forms/CustomDate";
import Select from "react-select";
import { selectHideArrowStyle } from "./AdminDashboard";

const MonthlyServiceChart = memo(({ theme }) => {
  const { handleError } = useError();
  const date = new Date();
  const [month, setMonth] = useState({ value: date.getMonth() + 1, label: months[date.getMonth()] });
  const [year, setYear] = useState({
    value: date.getFullYear(),
    label: date.getFullYear(),
  });
  const [chartData, setChartData] = useState({
    labels: [],
    data: [],
  });

  const data = {
    labels: chartData.labels,
    datasets: [
      {
        data: chartData.data,
        backgroundColor: [theme.primary, theme.warning, theme.danger],
        borderColor: "transparent",
      },
    ],
  };

  useEffect(() => {
    (async function () {
      const current_month = month?.value || null;
      const current_year = year?.value;

      try {
        const res = await instance.get("/dashboard/deoMonthlyServiceChart", {
          params: {
            current_month,
            current_year,
          },
        });
        const data = res.data.data.toSorted((a, b) => {
          return b.total - a.total;
        });

        const labels = data.map((item) => item.case_type);
        const values = data.map((item) => +item.total);

        setChartData({
          labels,
          data: values,
        });
      } catch (error) {
        handleError(error);
      }
    })();
  }, [month?.value, year?.value]);

  return (
    <Card>
      <CardHeader>
        <div className="d-flex justify-content-between align-items-center">
          <CardTitle tag="h5">Monthly Service Chart</CardTitle>
          <div className="d-flex align-items-center">
            {/* <MonthPicker startDate={monthAndYear} setStartDate={setMonthAndYear} placeholder="Select Month and Year" /> */}
            <Select
              className="basic-single w-120 mr-3"
              classNamePrefix="select"
              placeholder="Month"
              isClearable
              styles={selectHideArrowStyle}
              value={month}
              onChange={(selectedOption) => {
                setMonth(selectedOption);
              }}
              options={months.map((month, index) => ({ value: index + 1, label: month }))}
            />
            <Select
              className="basic-single w-120"
              classNamePrefix="select"
              placeholder="Year"
              styles={selectHideArrowStyle}
              value={year}
              onChange={(selectedOption) => {
                setYear(selectedOption);
              }}
              options={years.map((year) => ({ value: year, label: year }))}
            />
          </div>
        </div>
      </CardHeader>
      <CardBody>
        <div className="h-300">
          <Pie data={data} />
        </div>
      </CardBody>
    </Card>
  );
});

export default connect((store) => ({
  theme: store.theme.currentTheme,
}))(MonthlyServiceChart);
