import React, { useEffect } from "react";
import Select from "react-select";
import { Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { useDebounce } from "use-debounce";
import { useClaimFormContext } from "../../../context/administration/claimForm";
import { fetchAddressByPostcode } from "../../../utils/forms";
import { validateAlphaNumeric, validateEmail } from "../../../utils/validations";
import ContactInput from "../../UI/forms/ContactInput";
import { booleanOptions } from "../ClaimForm/ClientDetails";

const Defendent = ({ caseId }) => {
  const {
    defendant_title,
    setDefendantTitle,
    defendant_first_name,
    setDefendantFirstName,
    defendant_last_name,
    setDefendantLastName,
    defendant_email,
    setDefendantEmail,
    defendant_registration_number,
    setDefendantRegistrationNumber,
    defendant_policy_number,
    setDefendantPolicyNumber,
    defendant_address,
    setDefendantAddress,
    defendant_post_code,
    setDefendantPostCode,
    defendant_make_model,
    setDefendantMakeModel,
    defendant_color,
    setDefendantColor,
    defendant_contact_no,
    setDefendantContactNo,
    defendant_insurance,
    setDefendantInsurance,
    defendant_insurer,
    setDefendantInsurer,
    defendantValidation,
    setDefendantValidation,
  } = useClaimFormContext();

  const [debouncedPostcode] = useDebounce(defendant_post_code, 500);

  const fetchAddress = async (newPostCode) => {
    const newAddress = await fetchAddressByPostcode(newPostCode);

    if (newAddress !== 404) {
      setDefendantAddress(newAddress.address);
    }
  };

  useEffect(() => {
    if (debouncedPostcode?.trim() !== "") {
      fetchAddress(debouncedPostcode);
    }
  }, [debouncedPostcode]);

  return (
    <Form>
      <h4>Defendent Details</h4>

      <Row>
        <Col md={4}>
          <FormGroup>
            <Label for="title">Title</Label>
            <Input type="select" id="defendantTitle" name="defendantTitle" value={defendant_title} onChange={(e) => setDefendantTitle(e.target.value)}>
              <option value="mr.">Mr.</option>
              <option value="mrs.">Mrs.</option>
              <option value="ms.">Ms.</option>
              <option value="miss.">Miss.</option>
              <option value="mstr.">Mstr.</option>
            </Input>
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="defendantFirstName">First Name</Label>
            <Input type="text" id="defendantFirstName" autoComplete="off" value={defendant_first_name} onChange={(e) => setDefendantFirstName(e.target.value)} />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="defendantLastName">Last Name</Label>
            <Input type="text" id="defendantLastName" autoComplete="off" value={defendant_last_name} onChange={(e) => setDefendantLastName(e.target.value)} />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="defendantEmail">Email Address</Label>
            <Input
              type="email"
              id="defendantEmail"
              className={defendantValidation.emailAddress ? "is-invalid" : ""}
              value={defendant_email}
              autoComplete="off"
              onChange={(e) => {
                const { value } = e.target;
                setDefendantEmail(value);
                setDefendantValidation({ ...defendantValidation, emailAddress: !value || validateEmail(value) ? "" : "Invalid email address!" });
              }}
            />
            <small className="text-danger">{defendantValidation.emailAddress}</small>
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="defendantRegistrationNumber">Registration Number</Label>
            <Input type="text" id="defendantRegistrationNumber" autoComplete="off" value={defendant_registration_number} onChange={(e) => setDefendantRegistrationNumber(e.target.value)} />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="defendantPolicyNumber">Policy Number</Label>
            <Input type="text" id="defendantPolicyNumber" autoComplete="off" value={defendant_policy_number} onChange={(e) => setDefendantPolicyNumber(e.target.value)} />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="defendantPostCode">Post Code</Label>
            <Input
              type="text"
              id="defendantPostCode"
              autoComplete="off"
              className={defendantValidation.postCode ? "is-invalid" : ""}
              value={defendant_post_code}
              onChange={(e) => {
                const { value } = e.target;
                setDefendantPostCode(value);
                setDefendantValidation({
                  ...defendantValidation,
                  postCode: !value || validateAlphaNumeric(value) ? "" : "Invalid post code!",
                });
              }}
            />
            <small className="text-danger">{defendantValidation.postCode}</small>
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="defendantAddress">Address</Label>
            <Input type="text" autoComplete="off" id="defendantAddress" value={defendant_address} onChange={(e) => setDefendantAddress(e.target.value)} />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="defendantMakeModel">Make/ Model</Label>
            <Input type="text" autoComplete="off" id="defendantMakeModel" value={defendant_make_model} onChange={(e) => setDefendantMakeModel(e.target.value)} />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="defendantColor">Color</Label>
            <Input type="text" autoComplete="off" id="defendantColor" value={defendant_color} onChange={(e) => setDefendantColor(e.target.value)} />
          </FormGroup>
        </Col>
        <Col md={4}>
          <ContactInput
            name="defendantContactNo"
            label="Contact Number"
            value={defendant_contact_no}
            onChange={setDefendantContactNo}
            isInvalid={defendantValidation.contactNo}
            setError={[setDefendantValidation, "contactNo"]}
          />
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="defendantInsurance">Has Insurance?</Label>
            <Select id="defendantInsurance" className="basic-single" classNamePrefix="select" value={defendant_insurance} onChange={(value) => setDefendantInsurance(value)} options={booleanOptions} />
          </FormGroup>
        </Col>
        {defendant_insurance?.label === "Yes" && (
          <Col md={4}>
            <FormGroup>
              <Label for="defendantInsurer">Insurer Name</Label>
              <Input type="text" autoComplete="off" id="defendantInsurer" value={defendant_insurer} onChange={(e) => setDefendantInsurer(e.target.value)} />
            </FormGroup>
          </Col>
        )}
      </Row>
    </Form>
  );
};

export default Defendent;
