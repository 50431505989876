import React, { memo } from "react";

import { Card, CardBody, CardTitle, Col, Row, Spinner } from "reactstrap";

const DashboardCard = memo(({ title, value, icon, className = "", titleClass = "", valueClass = "", bottomMargin = "mb-3", isLoading = false, onClick }) => {
  return (
    <Card className={className} onClick={onClick}>
      <CardBody>
        <Row>
          <Col className="mt-0">
            <CardTitle tag="h5" className={titleClass}>
              {title}
            </CardTitle>
          </Col>

          {icon && (
            <Col className="col-auto">
              <div className="avatar">
                <div className="avatar-title rounded-circle bg-primary-dark">{icon}</div>
              </div>
            </Col>
          )}
        </Row>
        {isLoading ? (
          <div style={{ padding: "13px 0" }}>
            <Spinner color="#203A45" />
          </div>
        ) : (
          <h1 className={`display-5 mt-1 ${bottomMargin} ${valueClass}`}>{value}</h1>
        )}
      </CardBody>
    </Card>
  );
});

export default DashboardCard;
