import React, { createContext, useContext, useState } from "react";

export const storageContext = createContext({});
export const useStorageContext = () => useContext(storageContext);

const StorageContextProvider = ({ children }) => {
  const [userType, setUserType] = useState(null)
  const [source_id, setSourceId] = useState("");
  const [checkin_date, setCheckInDate] = useState("");
  const [storage_with, setStorageWith] = useState("");
  const [nasl_paid_date, setNaslPaidDate] = useState("");
  const [nasl_paid_status, setNaslPaidStatus] = useState(false);
  const [nasl_paid_amount, setNaslPaidAmount] = useState("");
  const [source_paid_date, setSourcePaidDate] = useState("");
  const [source_paid_status, setSourcePaidStatus] = useState(false);
  const [source_paid_amount, setSourcePaidAmount] = useState("");
  const [completed_date, setCompleteDate] = useState("");
  const [invoice_with, setInvoiceWith] = useState("");

  const [isAddNotActive, setIsAddNotActive] = useState(false);
  const [isAddAsReminderActive, setIsAddAsReminderActive] = useState(false);
  const [isSendEmailActive, setIsSendEmailActive] = useState(false);
  const [isSendSMSActive, setIsSendSMSActive] = useState(false);
  const [noteDescription, setNoteDescription] = useState("");
  const [addNoteTo, setAddNoteTo] = useState({ displayToDEO: false, displayToClient: false });
  const [addAsReminderDate, setAddAsReminderDate] = useState("");
  const [sendEmailTo, setSendEmailTo] = useState({ source: false, client: false });
  const [sendSMSTo, setSendSMSTo] = useState("");
  const [sendSMSMobile, setSendSMSMobile] = useState({ clientNumber: "", mobileNumber: "" });

  const values = {
    userType, 
    setUserType,
    source_id, 
    setSourceId,
    checkin_date,
    setCheckInDate,
    storage_with,
    setStorageWith,
    nasl_paid_date, 
    setNaslPaidDate,
    nasl_paid_status, 
    setNaslPaidStatus,
    nasl_paid_amount,
    setNaslPaidAmount,
    source_paid_date, 
    setSourcePaidDate,
    source_paid_status,
    setSourcePaidStatus,
    source_paid_amount,
    setSourcePaidAmount,
    completed_date,
    setCompleteDate,
    invoice_with,
    setInvoiceWith,

    isAddNotActive, 
    setIsAddNotActive, 
    isAddAsReminderActive, 
    setIsAddAsReminderActive,
    isSendEmailActive, 
    setIsSendEmailActive,
    isSendSMSActive, 
    setIsSendSMSActive,
    noteDescription, 
    setNoteDescription,
    addNoteTo,
    setAddNoteTo,
    addAsReminderDate,
    setAddAsReminderDate,
    sendEmailTo,
    setSendEmailTo,
    sendSMSTo,
    setSendSMSTo,
    sendSMSMobile, 
    setSendSMSMobile,
  };

  return <storageContext.Provider value={values}>{children}</storageContext.Provider>;
};

export default StorageContextProvider;
