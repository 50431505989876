import { faClose, faMessage, faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Timestamp, arrayUnion, doc, getDoc, increment, onSnapshot, serverTimestamp, setDoc, updateDoc } from "firebase/firestore";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { AsyncPaginate } from "react-select-async-paginate";
import { Badge, Button, Card, Col, Input, InputGroup, InputGroupAddon, ListGroupItem, Media, Modal, Row } from "reactstrap";
import { v4 as uuid } from "uuid";
import placeholderImage from "../../assets/img/avatars/loginPlaceholder.jfif";
import instance from "../../axiosInstance";
import { useProfileContext } from "../../context/profile";
import { db } from "../../firebase";
import useError from "../../hooks/useError";
import { isTypeAvailable } from "../../pages/Dashboard";
import FloatingActionButton from "../UI/FloatButton";
import { selectHideArrowStyle } from "./AdminDashboard";

function timestampToTime(timestamp) {
  const milliseconds = timestamp.seconds * 1000 + Math.round(timestamp.nanoseconds / 1e6);
  const messageDate = moment(milliseconds);

  const today = moment().startOf("day");
  const yesterday = moment().subtract(1, "days").startOf("day");
  const thisYear = moment().startOf("year");
  const thisMonth = moment().startOf("month");

  if (messageDate.isSame(today, "day")) {
    return messageDate.format("h:mm A");
  } else if (messageDate.isSame(yesterday, "day")) {
    return "Yesterday " + messageDate.format("h:mm A");
  } else if (messageDate.isSame(thisYear, "year")) {
    if (messageDate.isSame(thisMonth, "month")) {
      return messageDate.format("D, h:mm A"); // Only show day and time if it's current month
    } else {
      return messageDate.format("MMM D, h:mm A");
    }
  } else {
    return messageDate.format("MMM D, YYYY, h:mm A");
  }
}

const ChatMessage = ({ position, avatar, name, children, time }) => (
  <div className={`chat-message-${position} pb-4`}>
    <div>
      <img src={avatar} loading="lazy" className="rounded-circle mr-1" alt={name} width="30" height="30" />
    </div>
    <div className={`chat-text-${position} flex-shrink-1 rounded pt-2 px-3 ${position === "right" ? "mr-3" : "ml-3"}`}>
      {children}
      <div className={`text-muted small text-nowrap text-${position}`}>{time}</div>
    </div>
  </div>
);

const mobileWidth = window.innerWidth < 992 ? true : false;

const Chat = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const [selectedUser, setSelectedUser] = useState(null);
  const [message, setMessage] = useState("");

  const chatRef = useRef(null);

  const { handleError } = useError();
  const { profile } = useProfileContext();

  const getUserTypes = () => {
    if (isTypeAvailable(profile?.user_type, "Admin")) return "DEO,BDO,Sales Support";
    if (isTypeAvailable(profile?.user_type, "DEO")) return "BDO,Admin,Sales Support";
    if (isTypeAvailable(profile?.user_type, "BDO")) return "Admin,DEO,Sales Support";
  };

  const loadUsers = async (search, loadedOptions, { page }) => {
    try {
      const user_types = getUserTypes();
      if (!user_types) return { options: [], hasMore: false };

      const res = await instance.get("/user/lists", {
        params: { search, page, limit: 10, user_type: user_types },
      });

      const { data } = res.data;
      const options = data.data.map((user) => ({
        value: user.user_id,
        label: user.full_name,
        image: user.user_image,
      }));

      return {
        options,
        hasMore: page < data.total_pages,
        additional: {
          page: page + 1,
        },
      };
    } catch (error) {
      return {
        options: [],
        hasMore: false,
      };
    }
  };

  const [chats, setChats] = useState([]);

  const toggleModal = () => {
    setModalIsOpen(!modalIsOpen);
    setSelectedUser(null);
  };

  useEffect(() => {
    const getChats = () => {
      const unsub = onSnapshot(doc(db, "userChats", profile?.user_id), (doc) => {
        if (!doc.exists()) return;
        setChats(doc.data());
        // const userInfo = Object.entries(doc.data())?.sort((a, b) => b[1].date - a[1].date)[0][1].userInfo;
        // setSelectedUser(userInfo);
      });

      return () => {
        unsub();
      };
    };

    profile?.user_id && getChats();
  }, [profile?.user_id]);

  const getCombinedId = (first, second) => {
    if (!first || !second) return;
    return +first < +second ? `${first},${second}` : `${second},${first}`;
  };

  const updateUnread = async (chatId) => {
    await updateDoc(doc(db, "userChats", profile.user_id), {
      [chatId + ".unread"]: 0,
    });
  };

  const handleSelect = async (selected) => {
    //check whether the group(chats in firestore) exists, if not create
    const combinedId = getCombinedId(profile.user_id, selected.value);
    try {
      const res = await getDoc(doc(db, "chats", combinedId));
      const ifMyChatsExists = await getDoc(doc(db, "userChats", profile.user_id));
      const ifUserChatsExists = await getDoc(doc(db, "userChats", selected.value));

      let promises = [];

      if (!res.exists()) {
        //create a chat in chats collection
        promises.push(setDoc(doc(db, "chats", combinedId), { messages: [] }));
      }

      //create user chats
      const myData = {
        [combinedId]: {
          userInfo: {
            uid: selected.value,
            displayName: selected.label,
            photoURL: selected.image,
          },
          date: serverTimestamp(),
        },
      };
      if (!ifMyChatsExists.exists()) {
        promises.push(setDoc(doc(db, "userChats", profile.user_id), myData));
      } else {
        promises.push(updateDoc(doc(db, "userChats", profile.user_id), myData));
      }

      const userData = {
        [combinedId]: {
          userInfo: {
            uid: profile.user_id,
            displayName: profile.full_name,
            photoURL: profile.href_user_image,
          },
          date: serverTimestamp(),
        },
      };

      if (!ifUserChatsExists.exists()) {
        promises.push(setDoc(doc(db, "userChats", selected.value), userData));
      } else {
        promises.push(updateDoc(doc(db, "userChats", selected.value), userData));
      }

      // Wait for all promises to resolve
      await Promise.all(promises);

      setSelectedUser({
        uid: selected.value,
        displayName: selected.label,
        photoURL: selected.image,
      });
    } catch (err) {
      handleError(err);
    }
  };

  const [messages, setMessages] = useState([]);

  const updateUserInfo = async () => {
    try {
      const { data } = await instance.get(`/user/userImage/${selectedUser.uid}`);
      console.log(data);
      const fullName = data?.data?.full_name;
      const image = data?.data?.user_image;
      const combinedId = getCombinedId(profile.user_id, selectedUser.uid);
      const chatInfo = chats[combinedId];

      if (chatInfo.userInfo.displayName !== fullName || chatInfo.userInfo.photoURL !== image) {
        const userData = {
          [combinedId]: {
            userInfo: {
              uid: selectedUser.uid,
              displayName: fullName,
              photoURL: image,
            },
            date: chatInfo.date,
            unread: chatInfo?.unread || 0,
            lastMessage: chatInfo?.lastMessage || "",
          },
        };
        setSelectedUser({
          uid: selectedUser.uid,
          displayName: fullName,
          photoURL: image,
        });

        await updateDoc(doc(db, "userChats", profile.user_id), userData);
      }
    } catch (error) {
      handleError(error);
    }
  };

  useEffect(() => {
    if (!selectedUser) return;
    const chatId = getCombinedId(profile.user_id, selectedUser.uid);
    const unSub = onSnapshot(doc(db, "chats", chatId), (doc) => {
      if (doc.exists()) {
        setMessages(doc.data().messages);
        updateUnread(chatId);
      }
    });

    updateUserInfo();

    return () => {
      unSub();
    };
  }, [selectedUser?.uid]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (chatRef.current) {
        chatRef.current.scrollTop = chatRef.current.scrollHeight;
      }
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [messages.length]);

  const sendMessage = async (e) => {
    e.preventDefault();
    try {
      if (!selectedUser || !message.trim()) return;
      setMessage("");
      const chatId = getCombinedId(profile.user_id, selectedUser.uid);

      await updateDoc(doc(db, "userChats", selectedUser.uid), {
        [chatId + ".lastMessage"]: {
          message,
        },
        [chatId + ".date"]: serverTimestamp(),
        [chatId + ".unread"]: increment(1),
      });

      await updateDoc(doc(db, "userChats", profile.user_id), {
        [chatId + ".lastMessage"]: {
          message,
        },
        // [chatId + ".date"]: serverTimestamp(),
      });

      await updateDoc(doc(db, "chats", chatId), {
        messages: arrayUnion({
          id: uuid(),
          text: message,
          senderId: profile.user_id,
          date: Timestamp.now(),
        }),
      });

      if (chatRef.current) {
        chatRef.current.scrollTop = chatRef.current.scrollHeight;
      }
    } catch (error) {
      handleError(error);
    }
  };

  const totalUnread = Object.entries(chats).reduce((acc, chat) => acc + chat[1]?.unread || 0, 0);

  return (
    <>
      <Modal isOpen={modalIsOpen} toggle={toggleModal} centered size="lg">
        <Card className="m-0">
          <Row noGutters>
            {!mobileWidth || !selectedUser ? (
              <Col lg={5} xl={3} className={`border-right ${mobileWidth ? "h-369" : "h-495"} overflow-auto`}>
                <div className="px-4">
                  <Media className="align-items-center">
                    <Media body>
                      <div className="w-100 d-flex">
                        <AsyncPaginate
                          className="basic-single my-2 w-100"
                          classNamePrefix="select"
                          placeholder="Search..."
                          isSearchable={true}
                          loadOptions={loadUsers}
                          additional={{
                            page: 1,
                          }}
                          styles={selectHideArrowStyle}
                          debounceTimeout={300}
                          onChange={(selected) => {
                            handleSelect(selected);
                          }}
                        />
                        {mobileWidth && (
                          <Button color="" className="ml-2" onClick={toggleModal}>
                            <FontAwesomeIcon icon={faClose} size="xl" />
                          </Button>
                        )}
                      </div>
                    </Media>
                  </Media>
                </div>

                {Object.entries(chats)
                  ?.sort((a, b) => b[1].date - a[1].date)
                  .map((chat) => {
                    const lastMessage = chat[1]?.lastMessage;
                    if (!lastMessage) {
                      return null; // Skip rendering this list item if there is no last message
                    }

                    return (
                      <ListGroupItem action className={`border-0 ${selectedUser?.uid === chat[1].userInfo.uid ? "bg-light" : ""}`} key={chat[0]} onClick={() => setSelectedUser(chat[1].userInfo)}>
                        {chat[1]?.unread > 0 && (
                          <Badge color="success" className="float-right">
                            {chat[1]?.unread}
                          </Badge>
                        )}
                        <Media>
                          <img src={chat[1].userInfo.photoURL || placeholderImage} className="rounded-circle mr-1" alt="User Image" width="40" height="40" />
                          <Media body className="ml-3">
                            {chat[1].userInfo.displayName}
                            <p>{lastMessage.message}</p>
                          </Media>
                        </Media>
                      </ListGroupItem>
                    );
                  })}

                <hr className="d-block d-lg-none mt-1 mb-0" />
              </Col>
            ) : null}
            {!mobileWidth || selectedUser ? (
              <Col lg={7} xl={9}>
                {selectedUser ? (
                  <div className="py-2 px-4 border-bottom d-flex justify-content-between align-items-center">
                    <Media className="align-items-center py-1">
                      <div className="position-relative">
                        <img src={selectedUser?.photoURL || placeholderImage} className="rounded-circle mr-1" alt="Kathie Burton" width="40" height="40" />
                      </div>
                      <Media body className="pl-3">
                        <strong>{selectedUser?.displayName}</strong>
                      </Media>
                    </Media>

                    <Button color="" onClick={mobileWidth ? () => setSelectedUser(null) : toggleModal}>
                      <FontAwesomeIcon icon={faClose} size="xl" />
                    </Button>
                  </div>
                ) : null}

                {!selectedUser && (
                  <>
                    <div className="py-2 px-4 border-bottom d-flex justify-content-end align-items-center">
                      <Button color="" onClick={toggleModal}>
                        <FontAwesomeIcon icon={faClose} size="xl" />
                      </Button>
                    </div>
                    <div className="d-flex justify-content-center align-items-center h-full pb-5">
                      <h4>Select a user to start chat</h4>
                    </div>
                  </>
                )}

                <div className="position-relative">
                  <div className={`chat-messages p-4 ${mobileWidth ? "max-h-300" : "h-369"}`} ref={chatRef}>
                    {!selectedUser
                      ? null
                      : messages.map((message) => (
                          <ChatMessage
                            key={message.id}
                            position={message.senderId === profile?.user_id ? "right" : "left"}
                            name={message.senderId === profile?.user_id ? "You" : "Peter"}
                            avatar={message.senderId === profile?.user_id ? profile.href_user_image || placeholderImage : selectedUser?.photoURL || placeholderImage}
                            time={timestampToTime(message.date)}
                          >
                            {message.text}
                          </ChatMessage>
                        ))}
                  </div>
                </div>

                {selectedUser ? (
                  <div className="flex-grow-0 py-3 px-4 border-top">
                    <InputGroup>
                      <Input type="text" value={message} onChange={(e) => setMessage(e.target.value)} onKeyDown={(e) => e.key === "Enter" && sendMessage(e)} placeholder="Type your message" />
                      <InputGroupAddon addonType="append">
                        <Button color="primary" onClick={sendMessage}>
                          <FontAwesomeIcon icon={faPaperPlane} />
                        </Button>
                      </InputGroupAddon>
                    </InputGroup>
                  </div>
                ) : null}
              </Col>
            ) : null}
          </Row>
        </Card>
      </Modal>

      <FloatingActionButton count={totalUnread} onClick={toggleModal}>
        <FontAwesomeIcon icon={faMessage} size="2x" />
      </FloatingActionButton>
    </>
  );
};

export default Chat;
