import React, { memo } from "react";

const ContactLink = memo(({ children }) => {
  return (
    <a href={`tel:${children}`} className="custom-link">
      {children}
    </a>
  );
});

export default ContactLink;
