import React, { createContext, useContext, useState } from "react";

export const recoveryContext = createContext({});
export const useRecoveryContext = () => useContext(recoveryContext);

const RecoveryContextProvider = ({ children }) => {
  const [userType, setUserType] = useState(null)
  const [recoveredById, setRecoveredById] = useState({});
  const [recoveryCharges, setRecoveryCharges] = useState("");
  const [recoveryPaidDate, setRecoveryPaidDate] = useState("");
  const [recoveryPaidStatus, setRecoveryPaidStatus] = useState(false);
  const [naslAmount, setNaslAmount] = useState("");
  const [naslPaidDate, setNaslPaidDate] = useState("");
  const [naslPaidStatus, setNaslPaidStatus] = useState(false);
  const [recoveryDate, setRecoveryDate] = useState("");
  const [sendSms, setSendSms] = useState(false);
  const [recoveryInvoiceWith, setRecoveryInvoiceWith] = useState("");

  const [isAddNotActive, setIsAddNotActive] = useState(false);
  const [isAddAsReminderActive, setIsAddAsReminderActive] = useState(false);
  const [isSendEmailActive, setIsSendEmailActive] = useState(false);
  const [isSendSMSActive, setIsSendSMSActive] = useState(false);
  const [noteDescription, setNoteDescription] = useState("");
  const [addNoteTo, setAddNoteTo] = useState({ displayToDEO: false, displayToClient: false });
  const [addAsReminderDate, setAddAsReminderDate] = useState("");
  const [sendEmailTo, setSendEmailTo] = useState({ source: false, client: false });
  const [sendSMSTo, setSendSMSTo] = useState("");
  const [sendSMSMobile, setSendSMSMobile] = useState({ clientNumber: "", mobileNumber: "" });

  const values = {
    userType, 
    setUserType,
    recoveredById,
    setRecoveredById,
    recoveryCharges,
    setRecoveryCharges,
    recoveryPaidDate,
    setRecoveryPaidDate,
    recoveryPaidStatus,
    setRecoveryPaidStatus,
    naslAmount,
    setNaslAmount,
    naslPaidDate,
    setNaslPaidDate,
    naslPaidStatus,
    setNaslPaidStatus,
    recoveryDate,
    setRecoveryDate,
    sendSms,
    setSendSms,
    recoveryInvoiceWith,
    setRecoveryInvoiceWith,

    isAddNotActive, 
    setIsAddNotActive, 
    isAddAsReminderActive, 
    setIsAddAsReminderActive,
    isSendEmailActive, 
    setIsSendEmailActive,
    isSendSMSActive, 
    setIsSendSMSActive,
    noteDescription, 
    setNoteDescription,
    addNoteTo,
    setAddNoteTo,
    addAsReminderDate,
    setAddAsReminderDate,
    sendEmailTo,
    setSendEmailTo,
    sendSMSTo,
    setSendSMSTo,
    sendSMSMobile, 
    setSendSMSMobile,
  };

  return <recoveryContext.Provider value={values}>{children}</recoveryContext.Provider>;
};

export default RecoveryContextProvider;
