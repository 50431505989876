import React, { createContext, useContext, useState } from "react";

export const hireContext = createContext({});
export const useHireContext = () => useContext(hireContext);

const HireContextProvider = ({ children }) => {
  const [userType, setUserType] = useState(null)
  const [hireApproved, setHireApproved] = useState(null);
  const [hireCategory, setHireCategory] = useState(null);
  const [hireBookingDate, setHireBookingDate] = useState("");
  const [hireLeadSource, setHireLeadSource] = useState(null);
  const [hireCommenceDate, setHireCommenceDate] = useState("");
  const [hireRefNo, setHireRefNo] = useState("");
  const [hireSupplier, setHireSupplier] = useState(null);
  const [hireCompleteDate, setHireCompleteDate] = useState("");
  const [hireSubSupplier, setHireSubSupplier] = useState();
  const [outlay, setOutlay] = useState(null);
  const [sourceHirePaidDate, setSourceHirePaidDate] = useState("");
  const [sourceHirePaidAmount, setSourceHirePaidAmount] = useState("");
  const [naslHirePaidDateFE, setNaslHirePaidDateFE] = useState("");
  const [naslHirePaidAmountFe, setNaslHirePaidAmountFe] = useState("");
  const [naslHirePaidDateBE, setNaslHirePaidDateBE] = useState("");
  const [naslHirePaidAmountBe, setNaslHirePaidAmountBe] = useState("");
  const [hireAccessoriesPaymentDate, setHireAccessoriesPaymentDate] = useState("");
  const [accessoriesPaymentAmount, setAccessoriesPaymentAmount] = useState("");
  const [paServicingDate, setPaServicingDate] = useState("");
  const [paServicingAmount, setPaServicingAmount] = useState("");
  const [hireStatus, setHireStatus] = useState(null);
  const [rejectReason, setRejectReason] = useState("");
  const [hireChaseDate, setHireChaseDate] = useState("");
  const [hireTransferDate, setHireTranserDate] = useState("");
  const [naslAccessoriesPaymentDate, setNaslAccessoriesPaymentDate] = useState("");
  const [naslAccessoriesPaymentAmount, setNaslAccessoriesPaymentAmount] = useState("");
  const [priority, setPriority] = useState(null);
  
  const [sourceHirePaidStatus, setSourceHirePaidStatus] = useState(false);
  const [naslHirePaidStatusFE, setNaslHirePaidStatusFE] = useState(false);
  const [naslHirePaidStatusBE, setNaslHirePaidStatusBE] = useState(false);
  const [accessoryPamentStatus, setAccessoryPamentStatus] = useState(false);
  const [servicingPaymentStatus, setservicingPaymentStatus] = useState(false);
  const [naslAccessoryPamentStatus, setNaslAccessoryPamentStatus] = useState(false);
  
  const [isAddNotActive, setIsAddNotActive] = useState(false);
  const [isAddAsReminderActive, setIsAddAsReminderActive] = useState(false);
  const [isSendEmailActive, setIsSendEmailActive] = useState(false);
  const [isSendSMSActive, setIsSendSMSActive] = useState(false);
  const [noteDescription, setNoteDescription] = useState("");
  const [addNoteTo, setAddNoteTo] = useState({ displayToDEO: false, displayToClient: false });
  const [addAsReminderDate, setAddAsReminderDate] = useState("");
  const [sendEmailTo, setSendEmailTo] = useState({ source: false, client: false });
  const [sendSMSTo, setSendSMSTo] = useState("");
  const [sendSMSMobile, setSendSMSMobile] = useState({ clientNumber: "", mobileNumber: "" });

  const values = {
    userType, 
    setUserType,
    hireApproved,
    setHireApproved,
    hireCategory,
    setHireCategory,
    hireBookingDate,
    setHireBookingDate,
    hireLeadSource,
    setHireLeadSource,
    hireCommenceDate,
    setHireCommenceDate,
    hireRefNo,
    setHireRefNo,
    hireSupplier,
    setHireSupplier,
    hireCompleteDate,
    setHireCompleteDate,
    hireSubSupplier,
    setHireSubSupplier,
    outlay,
    setOutlay,
    sourceHirePaidDate,
    setSourceHirePaidDate,
    sourceHirePaidAmount,
    setSourceHirePaidAmount,
    naslHirePaidDateFE,
    setNaslHirePaidDateFE,
    naslHirePaidAmountFe,
    setNaslHirePaidAmountFe,
    naslHirePaidDateBE,
    setNaslHirePaidDateBE,
    naslHirePaidAmountBe,
    setNaslHirePaidAmountBe,
    hireAccessoriesPaymentDate,
    setHireAccessoriesPaymentDate,
    accessoriesPaymentAmount,
    setAccessoriesPaymentAmount,
    paServicingDate,
    setPaServicingDate,
    paServicingAmount,
    setPaServicingAmount,
    hireStatus,
    setHireStatus,
    rejectReason,
    setRejectReason,
    hireChaseDate,
    setHireChaseDate,
    hireTransferDate,
    setHireTranserDate,
    naslAccessoriesPaymentDate,
    setNaslAccessoriesPaymentDate,
    naslAccessoriesPaymentAmount,
    setNaslAccessoriesPaymentAmount,
    priority,
    setPriority,
    
    isAddNotActive, 
    setIsAddNotActive, 
    isAddAsReminderActive, 
    setIsAddAsReminderActive,
    isSendEmailActive, 
    setIsSendEmailActive,
    isSendSMSActive, 
    setIsSendSMSActive,
    
    noteDescription, 
    setNoteDescription,
    addNoteTo,
    setAddNoteTo,
    addAsReminderDate,
    setAddAsReminderDate,
    sendEmailTo,
    setSendEmailTo,
    sendSMSTo,
    setSendSMSTo,
    sendSMSMobile, 
    setSendSMSMobile,
    
    sourceHirePaidStatus,
    setSourceHirePaidStatus,
    naslHirePaidStatusFE,
    setNaslHirePaidStatusFE,
    naslHirePaidStatusBE,
    setNaslHirePaidStatusBE,
    accessoryPamentStatus,
    setAccessoryPamentStatus,
    servicingPaymentStatus,
    setservicingPaymentStatus,
    naslAccessoryPamentStatus,
    setNaslAccessoryPamentStatus,
  };

  return <hireContext.Provider value={values}>{children}</hireContext.Provider>;
};

export default HireContextProvider;
