import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { memo } from "react";
import ReactPaginate from "react-paginate";
import { Button, UncontrolledTooltip } from "reactstrap";
import { useHistory, useLocation } from "react-router-dom";

const Pagination = memo(({ paginationInfo, handlePageChange: handlePage, className }) => {
  const { currentPage, totalPages, totalRecords, limit } = paginationInfo;
  const startIndex = (currentPage - 1) * limit + 1;
  const endIndex = Math.min(currentPage * limit, totalRecords);

  const history = useHistory();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const page = parseInt(queryParams.get("page")) || 1;

  const handlePageChange = (selectedPage) => {
    queryParams.set("page", selectedPage.selected + 1);
    history.push(`${location.pathname}?${queryParams.toString()}`);
  };

  return (
    <div className={`d-flex justify-content-between align-items-center ${className || ""}`}>
      <h6 className="m-0 mx-4">
        Showing {totalRecords === 0 ? 0 : startIndex} to {endIndex} of {totalRecords} entries
      </h6>

      <ReactPaginate
        previousLabel={
          <Button color="primary" id="previousPage">
            <FontAwesomeIcon icon={faAngleLeft} />
            <UncontrolledTooltip target="previousPage">Previous</UncontrolledTooltip>
          </Button>
        }
        nextLabel={
          <Button color="primary" id="nextPage">
            <FontAwesomeIcon icon={faAngleRight} />
            <UncontrolledTooltip target="nextPage">Next</UncontrolledTooltip>
          </Button>
        }
        breakLabel={"..."}
        pageCount={totalPages}
        forcePage={page - 1}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePage || handlePageChange}
        containerClassName={"pagination m-3 justify-content-end"}
        activeClassName={"active"}
        previousClassName={currentPage === 0 ? "previous disabled" : "previous"}
        nextClassName={currentPage === totalPages ? "previous disabled" : "next"}
        disabledClassName="disabled"
      />
    </div>
  );
});

export default Pagination;
