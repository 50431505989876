import { faPen, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { memo, useEffect, useRef, useState } from "react";
import { Button, Card, CardBody, CardTitle, FormGroup, Input, Label, Modal, ModalBody, ModalHeader, Spinner, UncontrolledTooltip } from "reactstrap";
import useError from "../../hooks/useError";
import instance from "../../axiosInstance";
import { toDateAndTimeObj, toYYYYMMDD, formatDateTime } from "../../utils/forms";
import { toastr } from "react-redux-toastr";
import LoadingButton from "../UI/LoadingButton";
import CustomDate from "../UI/forms/CustomDate";

const NoteForm = memo(({ mode = "add", toggleModal = () => {}, setNotes }) => {
  const { handleError } = useError();
  const [note, setNote] = useState("");
  const [addAsReminder, setAddAsReminder] = useState(false);
  const [reminderDate, setReminderDate] = useState(null);
  const [reminderTime, setReminderTime] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [validationErrors, setValidationErrors] = useState({
    note: "",
    reminder_date: "",
  });

  const addNote = async () => {
    if (note.trim() === "") {
      setValidationErrors({
        ...validationErrors,
        note: "Note is required",
      });
      return;
    }

    if (addAsReminder && reminderDate === null) {
      setValidationErrors({
        ...validationErrors,
        reminder_date: "Reminder date is required",
      });
      return;
    }

    try {
      setIsSubmitting(true);
      const { data } = await instance.post("/user/addNote", {
        description: note,
        reminder_date: addAsReminder ? formatDateTime(reminderDate) : null,
      });
      toggleModal();
      toastr.success("Success", "Note Added Successfully");

      const newNote = data.data.note;

      setNotes((prevNotes) => [newNote, ...prevNotes]);
    } catch (error) {
      handleError(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <FormGroup>
        <Label for="note">Note</Label>
        <Input
          type="textarea"
          className={validationErrors.note ? "is-invalid" : ""}
          id="note"
          placeholder="Enter Note"
          value={note}
          onChange={(e) => {
            setNote(e.target.value);
            setValidationErrors({ ...validationErrors, note: "" });
          }}
        />
        <small className="text-danger">{validationErrors.note}</small>
      </FormGroup>
      <FormGroup className="d-flex">
        <Label className="mx-4">
          <Input type="checkbox" checked={addAsReminder} onChange={(e) => setAddAsReminder(e.target.checked)} />
          <span>Add Reminder</span>
        </Label>

        {addAsReminder && (
          <>
            <div className="d-flex flex-column">
                {/* <Label for="addAsReminderDate">Date & Time:</Label> */}
                <CustomDate
                  startDate={reminderDate}
                  setStartDate={setReminderDate}
                  id={"reminderDate"}
                  className="ml-2"
                  placeholder="Select Date & Time"
                  dateFormat="dd-MM-yyyy HH:mm"
                  timeFormat="HH:mm"
                  showTimeSelect
                  timeIntervals={10}
                />
            </div>
            

          </>
        )}
      </FormGroup>

      <div className="d-flex justify-content-end">
        <Button color="primary" outline onClick={toggleModal} className="mr-2">
          Cancle
        </Button>
        <LoadingButton onClick={addNote} isLoading={isSubmitting}>
          {mode === "add" ? (
            <>
              <FontAwesomeIcon icon={faPlus} /> <span>Add</span>
            </>
          ) : (
            <>
              <FontAwesomeIcon icon={faPen} /> <span>Update</span>
            </>
          )}
        </LoadingButton>
      </div>
    </>
  );
});

const NoteItem = memo(({ dateTime, note, id, setNotes, reminder }) => {
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const toggleEditModal = () => setEditModalIsOpen(!editModalIsOpen);

  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const toggleDeleteModal = () => setDeleteModalIsOpen(!deleteModalIsOpen);

  const { handleError } = useError();
  const [isDeleting, setIsDeleting] = useState(false);

  const deleteHandler = async () => {
    try {
      setIsDeleting(true);
      await instance.delete(`/user/deleteNote/${id}`);
      toastr.success("Success", "Note Deleted Successfully");
      setNotes((prevNotes) => prevNotes.filter((note) => note.user_note_id !== id));
    } catch (error) {
      handleError(error);
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <Card className="border mr-2 mb-2">
      <CardBody className="pb-0">
        <div className="d-flex justify-content-between">
          <div className="d-flex align-items-center">
            <CardTitle tag="h5">
              <span>{dateTime}</span>
            </CardTitle>
            {reminder ? <p className="ml-2">Reminder: {toYYYYMMDD(reminder, true)}</p> : null}
          </div>
          <span>
            {/* <Button color="primary" onClick={toggleEditModal} size="sm">
              <FontAwesomeIcon icon={faEdit} size="sm" />
            </Button> */}
            <Button color="danger" id={`view-${id}-note`} className="ml-2" onClick={toggleDeleteModal} size="sm">
              <FontAwesomeIcon icon={faTrash} size="sm" />
            </Button>
            <UncontrolledTooltip target={`view-${id}-note`}>Delete</UncontrolledTooltip>
          </span>
        </div>

        <p>{note}</p>
      </CardBody>

      <Modal isOpen={editModalIsOpen} toggle={toggleEditModal} centered>
        <ModalHeader toggle={toggleEditModal}>Edit Note</ModalHeader>
        <ModalBody>
          <NoteForm mode="edit" toggleModal={toggleEditModal} />
        </ModalBody>
      </Modal>

      <Modal isOpen={deleteModalIsOpen} toggle={toggleDeleteModal} centered>
        <ModalHeader toggle={toggleDeleteModal}>Delete Note</ModalHeader>
        <ModalBody>
          <h4 className="my-4">Are you sure you want to delete this note?</h4>

          <div className="d-flex justify-content-end">
            <Button color="primary" outline onClick={toggleDeleteModal} className="mr-2">
              Cancle
            </Button>
            <LoadingButton color="danger" onClick={deleteHandler} isLoading={isDeleting}>
              <FontAwesomeIcon icon={faTrash} /> Delete
            </LoadingButton>
          </div>
        </ModalBody>
      </Modal>
    </Card>
  );
});

const PersonalNotes = memo(() => {
  const [addModalIsOpen, setAddModalIsOpen] = useState(false);
  const toggleAddModal = () => setAddModalIsOpen(!addModalIsOpen);
  const [isLoading, setIsLoading] = useState(false);
  const { handleError } = useError();
  const [notes, setNotes] = useState([]);
  const [paginationInfo, setPaginationInfo] = useState({
    currentPage: 1,
    totalPages: 0,
    totalRecords: 0,
    limit: 10,
  });
  const [page, setPage] = useState(1);

  const notesContainerRef = useRef(null);

  const getNotes = async () => {
    try {
      setIsLoading(true);
      const { data } = await instance.get("/user/getNotes", {
        params: { page: page, limit: paginationInfo.limit },
      });
      setNotes((prevNotes) => [...prevNotes, ...data.data.data]);
      setPaginationInfo({
        currentPage: data.data.page,
        totalPages: data.data.total_pages,
        totalRecords: data.data.total_records,
        limit: data.data.limit,
      });
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getNotes();
  }, [page]);

  const handleScroll = () => {
    const container = notesContainerRef.current;
    if (container) {
      const { scrollTop, clientHeight, scrollHeight } = container;
      if (scrollTop + clientHeight >= scrollHeight) {
        // User has reached the bottom, load more notes
        if (paginationInfo.currentPage < paginationInfo.totalPages) {
          setPage((prevPage) => prevPage + 1);
          setPaginationInfo((prevPaginationInfo) => ({
            ...prevPaginationInfo,
            currentPage: prevPaginationInfo.currentPage + 1,
          }));
        }
      }
    }
  };

  useEffect(() => {
    const container = notesContainerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
      return () => {
        container.removeEventListener("scroll", handleScroll);
      };
    }
  }, [paginationInfo]);

  return (
    <Card>
      <CardBody>
        <div className="d-flex justify-content-between align-items-center py-2">
          <CardTitle tag="h5">Personal Notes</CardTitle>
          <Button color="primary" className="mr-2" onClick={toggleAddModal}>
            <FontAwesomeIcon icon={faPlus} /> Add Note
          </Button>
        </div>

        <div className="h-495 overflow-auto" ref={notesContainerRef}>
          {notes.map((note) => (
            <NoteItem key={note.user_note_id} id={note.user_note_id} dateTime={toDateAndTimeObj(note.created_at)} note={note.description} setNotes={setNotes} reminder={note.reminder_date} />
          ))}
          {isLoading && (
            <div className="p-4 d-flex justify-content-center align-items-center">
              <Spinner color="primary" />
            </div>
          )}
        </div>

        {!isLoading && !notes.length ? (
          <div className="p-4 d-flex justify-content-center align-items-center h-full">
            <h4>No Notes Found</h4>
          </div>
        ) : null}
      </CardBody>
      {/* <PaginationWithState paginationInfo={paginationInfo} page={page} setPage={setPage} hideInfo /> */}

      <Modal isOpen={addModalIsOpen} toggle={toggleAddModal} centered>
        <ModalHeader toggle={toggleAddModal}>Add Note</ModalHeader>
        <ModalBody>
          <NoteForm toggleModal={toggleAddModal} setNotes={setNotes} />
        </ModalBody>
      </Modal>
    </Card>
  );
});

export default PersonalNotes;
