import React from "react";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";
import { Redirect } from "react-router-dom";
import { Content, Footer, Main, Navbar, Sidebar, Wrapper } from "../components";

const Dashboard = ({ sidebar, children }) => {
  const isAuthenticated = localStorage.getItem("token");

  if (!isAuthenticated) {
    toastr.error("Error", "You need to login first");
    return <Redirect to="/auth/login" />;
  }

  return (
    <React.Fragment>
      <Wrapper>
        {!sidebar.isOnRight && <Sidebar />}
        <Main>
          <Navbar />
          <Content>{children}</Content>
          <Footer />
        </Main>
        {sidebar.isOnRight && <Sidebar />}
      </Wrapper>
    </React.Fragment>
  );
};

export default connect((store) => ({
  sidebar: store.sidebar,
}))(Dashboard);
