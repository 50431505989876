import React, { memo } from "react";
import { FormGroup, Label } from "reactstrap";
import RequiredLabel from "./RequiredLabel";
import { PatternFormat } from "react-number-format";
import { validatePhoneNumber } from "../../../utils/validations";

const MobileInput = memo(({ label, name, value, onChange, isInvalid = "", required = false, setError }) => {
  if (!setError) {
    throw new Error("Error handler and error key are required");
  }

  const [errorHandler, errorKey] = setError;

  return (
    <FormGroup>
      {required ? <RequiredLabel htmlFor={name}>{label}</RequiredLabel> : <Label for={name}>{label}</Label>}
      <PatternFormat
        format="07#########"
        name={name}
        id={name}
        value={value}
        autoComplete="off"
        allowEmptyFormatting
        mask="_"
        className={`form-control ${isInvalid ? "is-invalid" : ""}`}
        onChange={(e) => {
          const value = e.target.value;
          if (value === "07_________") {
            onChange("");
          } else {
            onChange(value);
            if (validatePhoneNumber(value) && isInvalid) {
              errorHandler((pre) => ({ ...pre, [errorKey]: "" }));
            }
          }
        }}
      />
      <small className="text-danger">{isInvalid}</small>
    </FormGroup>
  );
});

export default MobileInput;
