import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { toastr } from "react-redux-toastr";
import { Link, useParams } from "react-router-dom";
import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import instance from "../../../axiosInstance";
import { useUpdateFormContext } from "../../../context/administration/updateForm";
import { useRecoveryContext } from "../../../context/administration/updateForm/recovery";
import useError from "../../../hooks/useError";
import { toDateObj, toYYYYMMDD } from "../../../utils/forms";
import LoadingButton from "../../UI/LoadingButton";
import CustomDate from "../../UI/forms/CustomDate";
import Notes from "./Notes";
import ParamsLink from "../../UI/links/ParamsLink";
import { validatePhoneNumber } from "../../../utils/validations";
import { AsyncPaginate } from "react-select-async-paginate";
import { loadLeadSourceOptions } from "./Hire";

const Recovery = () => {
  const { formId } = useParams();
  const { formData, getNotes } = useUpdateFormContext();
  const [isUpdating, setIsUpdating] = useState(false);
  const { handleError } = useError();

  const {
    userType, 
    setUserType,
    recoveredById,
    setRecoveredById,
    recoveryCharges,
    setRecoveryCharges,
    recoveryPaidDate,
    setRecoveryPaidDate,
    recoveryPaidStatus,
    setRecoveryPaidStatus,
    naslAmount,
    setNaslAmount,
    naslPaidDate,
    setNaslPaidDate,
    naslPaidStatus,
    setNaslPaidStatus,
    recoveryDate,
    setRecoveryDate,
    sendSms,
    setSendSms,
    recoveryInvoiceWith,
    setRecoveryInvoiceWith,

    // -----note's values-------
    isAddNotActive,
    setIsAddNotActive,
    isAddAsReminderActive,
    setIsAddAsReminderActive,
    isSendEmailActive,
    setIsSendEmailActive,
    isSendSMSActive,
    setIsSendSMSActive,

    noteDescription,
    setNoteDescription,
    addNoteTo,
    setAddNoteTo,
    addAsReminderDate,
    setAddAsReminderDate,
    sendEmailTo,
    setSendEmailTo,
    sendSMSTo,
    setSendSMSTo,
    sendSMSMobile,
    setSendSMSMobile,
  } = useRecoveryContext();

  const [validationErrors, setValidationErrors] = useState({
    mobileNumber: "",
    clientNumber: "",
  });

  const clearNoteFields = () => {
    setIsAddNotActive(false);
    setIsAddAsReminderActive(false);
    setIsSendEmailActive(false);
    setIsSendSMSActive(false);
    setNoteDescription("");
    setAddNoteTo("");
    setAddAsReminderDate(null);
    setSendEmailTo("");
    setSendSMSTo("");
    setSendSMSMobile({
      clientNumber: "",
      mobileNumber: "",
    });
  };

  //Setting Data
  useEffect(() => {
    const setFields = () => {
      if (!formData || !formData.recovery) return;

      const { recovery } = formData;
      const { user } = formData;

      setUserType(user.user_type)

      setRecoveredById({ value: recovery.recovered_by_id, label: recovery.recovered_by });
      setRecoveryCharges(recovery.recovery_charges);
      setRecoveryPaidDate(toDateObj(recovery.recovery_paid_date));
      setRecoveryPaidStatus(recovery.recovery_paid_status === "Yes");
      setNaslAmount(recovery.nasl_amount);
      setNaslPaidDate(toDateObj(recovery.nasl_paid_date));
      setNaslPaidStatus(recovery.nasl_paid_status === "Yes");
      setRecoveryDate(toDateObj(recovery.recovery_date));
      setRecoveryInvoiceWith(recovery.recovery_invoice_with);
    };
    setFields();
  }, [formData]);

  //Updatting Data (put API)
  const updatingData = async () => {
    let isInvalid = false;

    if (!validatePhoneNumber(sendSMSMobile.clientNumber)) {
      setValidationErrors((prevErrors) => ({ ...prevErrors, clientNumber: "Invalid client number" }));
      isInvalid = true;
    } else {
      setValidationErrors((prevErrors) => ({ ...prevErrors, clientNumber: "" }));
    }

    if (!validatePhoneNumber(sendSMSMobile.mobileNumber)) {
      setValidationErrors((prevErrors) => ({ ...prevErrors, mobileNumber: "Invalid mobile number" }));
      isInvalid = true;
    } else {
      setValidationErrors((prevErrors) => ({ ...prevErrors, mobileNumber: "" }));
    }

    if (isInvalid) return;

    try {
      setIsUpdating(true);
      const data = {
        recovered_by_id: recoveredById.value,
        recovery_charges: recoveryCharges,
        recovery_paid_date: toYYYYMMDD(recoveryPaidDate),
        recovery_paid_status: recoveryPaidStatus ? "Yes" : "No",
        nasl_amount: naslAmount,
        nasl_paid_date: toYYYYMMDD(naslPaidDate),
        nasl_paid_status: naslPaidStatus ? "Yes" : "No",
        recovery_date: toYYYYMMDD(recoveryDate),
        send_sms: sendSms ? 1 : 0,
        recovery_invoice_with: recoveryInvoiceWith,
        note: {
          note_type: "Recovery",
          description: noteDescription,
          display_to_deo: addNoteTo.displayToDEO ? 1 : 0,
          display_to_client: addNoteTo.displayToClient ? 1 : 0,
          reminder_date: toYYYYMMDD(addAsReminderDate),
          send_email_source: sendEmailTo.source ? 1 : 0,
          send_email_client: sendEmailTo.client ? 1 : 0,
          send_sms_to: sendSMSTo,
          send_sms_mobile: sendSMSTo === "client" ? sendSMSMobile.clientNumber : sendSMSMobile.mobileNumber,
        },
      };

      await instance.put(`/case/updateRecovery/${formId}`, data);
      toastr.success("Success", "Recovery updated successfully");
      clearNoteFields();
      getNotes();
    } catch (error) {
      handleError(error);
    } finally {
      setIsUpdating(false);
    }
  };

  return (
    <Form>

      <div className="position-absolute text-center" style={{ top: '0.5%', right: '1.7%' }}>
        {recoveryPaidStatus == 1 ? (
          <h4 className="text-success">PAID</h4>
        ) : (
          <h4 className="text-danger">UNPAID</h4>
        )}
      </div>

      <Row>
        <Col md={4}>
          <FormGroup>
            <Label for="recoveredById">Recovered By</Label>
            <AsyncPaginate
              className="basic-single"
              classNamePrefix="select"
              isSearchable={true}
              name="recoveredById"
              id="recoveredById"
              loadOptions={loadLeadSourceOptions}
              additional={{
                page: 1,
              }}
              value={recoveredById}
              debounceTimeout={300}
              onChange={(selected) => setRecoveredById(selected)}
            />
          </FormGroup>
        </Col>

        {userType === "Super Admin" || userType === "Account" ? (
          <>
            <Col md={4}>
              <FormGroup>
                <Label for="recoveryCharges">Recovery Charges</Label>
                <Input type="number" id="recoveryCharges" value={recoveryCharges} onChange={(e) => setRecoveryCharges(e.target.value)} />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label for="recoveryPaidDate">Recovery Paid Date</Label>
                <Col md={6} className="p-0">
                  <CustomDate startDate={recoveryPaidDate} setStartDate={setRecoveryPaidDate} id={"recoveryPaidDate"} />
                </Col>
                <Label check className="ml-4">
                  <Input type="checkbox" checked={recoveryPaidStatus} onChange={(e) => setRecoveryPaidStatus(e.target.checked)} />
                  Recovery Paid Status
                </Label>
              </FormGroup>
            </Col>

            <Col md={4}>
              <FormGroup>
                <Label for="naslAmount">NASL Amount</Label>
                <Input type="number" id="naslAmount" value={naslAmount} onChange={(e) => setNaslAmount(e.target.value)} />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label for="naslPaidDate">NASL Paid Date</Label>
                <Col md={6} className="p-0">
                  <CustomDate startDate={naslPaidDate} setStartDate={setNaslPaidDate} id={"naslPaidDate"} />
                </Col>
                <Label check className="ml-4">
                  <Input type="checkbox" checked={naslPaidStatus} onChange={(e) => setNaslPaidStatus(e.target.checked)} />
                  NASL Paid Status
                </Label>
              </FormGroup>
            </Col>
          </>
        ) : (
          <>
            <Col md={4}>
              <FormGroup>
                <Label for="recoveryPaidDate">Recovery Paid Date</Label>
                <Col md={6} className="p-0">
                  <CustomDate startDate={recoveryPaidDate} id={"recoveryPaidDate"} disabled/>
                </Col>
              </FormGroup>
            </Col>
          </>
        )}
        <Col md={4}>
          <FormGroup>
            <Label for="recoveryDate">Recovery Date</Label>
            <Col md={6} className="p-0">
              <CustomDate startDate={recoveryDate} setStartDate={setRecoveryDate} id={"recoveryDate"} />
            </Col>
            <Label check className="ml-4">
              <Input type="checkbox" checked={sendSms} onChange={(e) => setSendSms(e.target.checked)} />
              Send SMS
            </Label>
          </FormGroup>
        </Col>

        <Col md={4}>
          <FormGroup>
            <Label for="RecoveryInvoiceWith">Recovery Invoice With</Label>
            <Input type="text" id="RecoveryInvoiceWith" value={recoveryInvoiceWith} onChange={(e) => setRecoveryInvoiceWith(e.target.value)} />
          </FormGroup>
        </Col>
      </Row>

      <Notes
        noteDescription={noteDescription}
        setNoteDescription={setNoteDescription}
        sendSMSTo={sendSMSTo}
        setSendSMSTo={setSendSMSTo}
        addAsReminderDate={addAsReminderDate}
        setAddAsReminderDate={setAddAsReminderDate}
        sendEmailTo={sendEmailTo}
        setSendEmailTo={setSendEmailTo}
        addNoteTo={addNoteTo}
        setAddNoteTo={setAddNoteTo}
        sendSMSMobile={sendSMSMobile}
        setSendSMSMobile={setSendSMSMobile}
        isAddNotActive={isAddNotActive}
        setIsAddNotActive={setIsAddNotActive}
        isAddAsReminderActive={isAddAsReminderActive}
        setIsAddAsReminderActive={setIsAddAsReminderActive}
        isSendEmailActive={isSendEmailActive}
        setIsSendEmailActive={setIsSendEmailActive}
        isSendSMSActive={isSendSMSActive}
        setIsSendSMSActive={setIsSendSMSActive}
        validationErrors={validationErrors}
        setValidationErrors={setValidationErrors}
      />

      <div className="d-flex justify-content-end">
        <ParamsLink to="/administration/cases" className="mr-2">
          <Button color="primary">Exit</Button>
        </ParamsLink>
        <LoadingButton onClick={updatingData} isLoading={isUpdating}>
          Update
        </LoadingButton>
      </div>
    </Form>
  );
};

export default Recovery;
