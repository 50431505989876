import React, { createContext, useContext, useState } from "react";

const profileContext = createContext({});
export const useProfileContext = () => useContext(profileContext);

const ProfileContextProivder = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);

  const [profile, setProfile] = useState(null);

  return <profileContext.Provider value={{ profile, setProfile, isLoading, setIsLoading }}>{children}</profileContext.Provider>;
};

export default ProfileContextProivder;
