// MapComponent.js
import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, Marker, useMapEvents } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';

// Import Leaflet marker images
import markerIcon2x from 'leaflet/dist/images/marker-icon-2x.png';
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';

const MapComponent = ({ setLatLon }) => {
    const [position, setPosition] = useState([51.505, -0.09]);

    const MapEvents = () => {
        useMapEvents({
            click(e) {
                setPosition([e.latlng.lat, e.latlng.lng]);
                setLatLon(`${e.latlng.lat}, ${e.latlng.lng}`);
            },
        });
        return null;
    };

    useEffect(() => {
        L.Marker.prototype.options.icon = L.icon({
            iconRetinaUrl: markerIcon2x,
            iconUrl: markerIcon,
            shadowUrl: markerShadow,
            iconSize: [25, 41],
            iconAnchor: [12, 41],
            popupAnchor: [1, -34],
            tooltipAnchor: [16, -28],
            shadowSize: [41, 41]
        });
    }, []);

    return (
        <>
            <MapContainer center={position} zoom={13} style={{ height: '400px', width: '100%' }}>
                <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                />
                <Marker position={position} />
                <MapEvents />
            </MapContainer>

            <Row className="mt-3 align-items-center">
              <Col md={2}>
                <Label for="latLon">LAT LON</Label>
              </Col>
              <Col md={6}>
                <Input id="latLon" value={position} disabled />
              </Col>
            </Row>

        </>
    );
};

export default MapComponent;
