import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCu8o71FPrCm43WdNIOkcq7OJxGcs1LUps",
  authDomain: "nasl-cfb88.firebaseapp.com",
  projectId: "nasl-cfb88",
  storageBucket: "nasl-cfb88.appspot.com",
  messagingSenderId: "609668706032",
  appId: "1:609668706032:web:b2e587b7f5be020bd8dc35",
  measurementId: "G-0749JE3QFB",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const db = getFirestore();
