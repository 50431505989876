import React from "react";
import { faBarsProgress, faBriefcase, faChartLine, faChartSimple, faFileCsv, faLock, faMoneyBill, faShieldAlt, faSignInAlt, faSliders, faUserTie } from "@fortawesome/free-solid-svg-icons";

// Auth
const AccountRecovery = React.lazy(() => import("../pages/auth/AccountRecovery"));
const Login = React.lazy(() => import("../pages/auth/Login"));
const Page404 = React.lazy(() => import("../pages/auth/Page404"));
const Page500 = React.lazy(() => import("../pages/auth/Page500"));

const AddNewCase = React.lazy(() => import("../pages/Administration/AddNewCase"));
const ClaimForm = React.lazy(() => import("../pages/Administration/ClaimForm"));
const ClaimFormProceed = React.lazy(() => import("../pages/Administration/ClaimFormProceed"));
const NewCases = React.lazy(() => import("../pages/Administration/NewCases"));
const SelfBilling = React.lazy(() => import("../pages/Administration/SelfBilling"));
const Try = React.lazy(() => import("../pages/Administration/Try"));
const UpdateForm = React.lazy(() => import("../pages/Administration/UpdateForm"));
const UpdateFormProceed = React.lazy(() => import("../pages/Administration/UpdateFormProceed"));
const Dashboard = React.lazy(() => import("../pages/Dashboard"));
const UploadData = React.lazy(() => import("../pages/Insurance/UploadData"));
const Profile = React.lazy(() => import("../pages/Profile"));
const AddIPGroup = React.lazy(() => import("../pages/Security/AddIPGroup"));
const AddRole = React.lazy(() => import("../pages/Security/AddRole"));
const AddUser = React.lazy(() => import("../pages/Security/AddUser"));
const EditIPGroup = React.lazy(() => import("../pages/Security/EditIPGroup"));
const EditRole = React.lazy(() => import("../pages/Security/EditRole"));
const EditUser = React.lazy(() => import("../pages/Security/EditUser"));
const IpGroups = React.lazy(() => import("../pages/Security/IPGroup"));
const Roles = React.lazy(() => import("../pages/Security/Roles"));
const UserProfile = React.lazy(() => import("../pages/Security/UserProfile"));
const Users = React.lazy(() => import("../pages/Security/Users"));
const ResetPassword = React.lazy(() => import("../pages/auth/ResetPassword"));
const SelfBillingView = React.lazy(() => import("../pages/Administration/SelfBillingView"));
const ViewCase = React.lazy(() => import("../pages/Administration/ViewCase"));
const NewInsurance = React.lazy(() => import("../pages/Administration/NewInsurance"));
const AddLeadSource = React.lazy(() => import("../pages/WorkProvider/AddLeadSource"));
const EditLeadSource = React.lazy(() => import("../pages/WorkProvider/EditLeadSource"));
const LeadCases = React.lazy(() => import("../pages/WorkProvider/LeadCases"));
const Cities = React.lazy(() => import("../pages/Setup/Cities"));
const AddCity = React.lazy(() => import("../pages/Setup/AddCity"));
const EditCity = React.lazy(() => import("../pages/Setup/EditCity"));
const Boroughs = React.lazy(() => import("../pages/Setup/Boroughs"));
const AddBorough = React.lazy(() => import("../pages/Setup/AddBorough"));
const EditBorough = React.lazy(() => import("../pages/Setup/EditBorough"));
const Languages = React.lazy(() => import("../pages/Setup/Languages"));
const AddLanguage = React.lazy(() => import("../pages/Setup/AddLanguage"));
const EditLanguage = React.lazy(() => import("../pages/Setup/EditLanguage"));
const PersonalInjuryReport = React.lazy(() => import("../pages/Reports/PersonalInjuryReport"));
const HireReport = React.lazy(() => import("../pages/Reports/HireReport"));
const RepairReport = React.lazy(() => import("../pages/Reports/RepairReport"));
const StorageReport = React.lazy(() => import("../pages/Reports/StorageReport"));
const RecoveryReport = React.lazy(() => import("../pages/Reports/RecoveryReport"));
const InspectionReport = React.lazy(() => import("../pages/Reports/InspectionReport"));
const InsuranceList = React.lazy(() => import("../pages/Insurance/InsuranceList"));
const UsersList = React.lazy(() => import("../pages/Reports/UsersList"));
const ViewInsurance = React.lazy(() => import("../pages/Insurance/ViewInsurance"));
const LeadSource = React.lazy(() => import("../pages/WorkProvider/LeadSource"));
const EditLeadCase = React.lazy(() => import("../pages/WorkProvider/EditLeadCase"));
const AccidentTemplates = React.lazy(() => import("../pages/Setup/AccidentTemplates"));
const AddAccidentTemplate = React.lazy(() => import("../pages/Setup/AddAccedentTemplate"));
const EditAccidentTemplate = React.lazy(() => import("../pages/Setup/EditAccedentTemplate"));
const AddSelfInvoice = React.lazy(() => import("../pages/Administration/AddSelfInvoice"));
const SalesReport = React.lazy(() => import("../pages/WorkProvider/SalesReport"));
const InsuranceDailyReport = React.lazy(() => import("../pages/Insurance/InsuranceDailyReport"));
const InsuranceHireReport = React.lazy(() => import("../pages/Insurance/InsuranceHireReport"));
const FinancialReview = React.lazy(() => import("../pages/FinancialAnalysis/FinancialReview.jsx"));
const FinancialAnalysisBDM = React.lazy(() => import("../pages/FinancialAnalysis/FinancialAnalysisBDM.jsx"));
const PaymentAccessoryReport = React.lazy(() => import("../pages/PaymentReports/AccessoryReport.jsx"));
const NotPaidReport = React.lazy(() => import("../pages/PaymentReports/NotPaidReport.jsx"));
const PaymentReport = React.lazy(() => import("../pages/PaymentReports/PaymentReport.jsx"));
const PaymentAuthorisationReport = React.lazy(() => import("../pages/PaymentReports/PaymentAuthorisation.jsx"));
const LeadSourceInformation = React.lazy(() => import("../pages/PaymentReports/LeadSourceInformation.jsx"));
const ChaseReport = React.lazy(() => import("../pages/Reports/ChaseReport.jsx"));
const RollbackReport = React.lazy(() => import("../pages/Reports/RollbackReport.jsx"));
const OutlayReport = React.lazy(() => import("../pages/Reports/OutlayReport.jsx"));
const DueDiligenceReport = React.lazy(() => import("../pages/Reports/DueDiligenceReport.jsx"));
const AccessoryReport = React.lazy(() => import("../pages/Reports/AccessoryReport.jsx"));
const SolicitorReport = React.lazy(() => import("../pages/ProgressReports/SolicitorReport.jsx"));
const HireProgressReport = React.lazy(() => import("../pages/ProgressReports/HireReport.jsx"));
const IntroducerCases = React.lazy(() => import("../pages/Reports/IntroducerCases.jsx"));

const dashboardRoute = {
  path: "/",
  name: "Dashboard",
  icon: faChartLine,
  component: Dashboard,
};

const profileRoute = {
  path: "/profile",
  name: null,
  component: Profile,
};

const securityRoutes = {
  path: "/security",
  name: "User Management",
  icon: faLock,
  children: [
    {
      path: "/security/roles",
      name: "Roles",
      component: Roles,
    },
    {
      path: "/security/roles/addRole",
      name: null,
      component: AddRole,
    },
    {
      path: "/security/roles/:id/edit",
      name: null,
      component: EditRole,
    },
    {
      path: "/security/users",
      name: "Users",
      component: Users,
    },
    {
      path: "/security/users/addUser",
      name: null,
      component: AddUser,
    },
    {
      path: "/security/users/:id",
      name: null,
      component: UserProfile,
    },
    {
      path: "/security/users/:id/edit",
      name: null,
      component: EditUser,
    },
    {
      path: "/security/IPGroups",
      name: "IP Groups",
      component: IpGroups,
    },
    {
      path: "/security/IPGroups/addIPGroup",
      name: null,
      component: AddIPGroup,
    },
    {
      path: "/security/IPGroups/:id/edit",
      name: null,
      component: EditIPGroup,
    },
  ],
};

const administrationRoutes = {
  path: "/administration",
  name: "Administration",
  icon: faUserTie,
  children: [
    {
      path: "/administration/addNewCase",
      name: "Add New Case",
      component: AddNewCase,
    },
    {
      path: "/administration/cases",
      name: "Cases List",
      component: NewCases,
    },
    {
      path: "/try",
      name: null,
      component: Try,
    },
    {
      path: "/administration/claimForm",
      name: null,
      component: ClaimForm,
    },
    {
      path: "/administration/cases/view/:id",
      name: null,
      component: ViewCase,
    },
    {
      path: "/administration/cases/:formId",
      name: null,
      component: ClaimFormProceed,
    },
    {
      path: "/administration/updateForm",
      name: null,
      component: UpdateForm,
    },
    {
      path: "/administration/cases/:formId/edit",
      name: null,
      component: UpdateFormProceed,
    },
    {
      path: "/administration/selfBilling",
      name: "Self Billing",
      component: SelfBilling,
    },
    {
      path: "/administration/addSelfInvoice",
      name: null,
      component: AddSelfInvoice,
    },
    {
      path: "/administration/selfBilling/:id",
      name: null,
      component: SelfBillingView,
    },
  ],
};

const reportsRoutes = {
  path: "/reports",
  name: "Reports",
  icon: faFileCsv,
  children: [
    {
      path: "/reports/chaseReport",
      name: "Chase Report",
      component: ChaseReport,
    },
    {
      path: "/reports/rollbackReport",
      name: "Rollback Report",
      component: RollbackReport,
    },
    {
      path: "/reports/outlayReport",
      name: "Outlay Report",
      component: OutlayReport,
    },
    {
      path: "/reports/dueDiligenceReport",
      name: "Due Diligence Report",
      component: DueDiligenceReport,
    },
    {
      path: "/reports/accessoryReport",
      name: "Accessories Report",
      component: AccessoryReport,
    },
    {
      path: "/reports/PIreport",
      name: "Personal Injury",
      component: PersonalInjuryReport,
    },
    {
      path: "/reports/hireReport",
      name: "Hire",
      component: HireReport,
    },
    {
      path: "/reports/repairReport",
      name: "Repair",
      component: RepairReport,
    },
    {
      path: "/reports/storageReport",
      name: "Storage",
      component: StorageReport,
    },
    {
      path: "/reports/recoveryReport",
      name: "Recovery",
      component: RecoveryReport,
    },
    // {
    //   path: "/reports/introducerCases",
    //   name: "Introducer Cases",
    //   component: IntroducerCases,
    // },

    // {
    //   path: "/reports/inspectionReport",
    //   name: "Inspection",
    //   component: InspectionReport,
    // },
    // {
    //   path: "/reports/usersList",
    //   name: "User's List",
    //   component: UsersList,
    // },
  ],
};

const insuranceRoutes = {
  path: "/insurance",
  name: "Insurance",
  icon: faShieldAlt,
  children: [
    {
      path: "/insurance/uploadData",
      name: "Upload Insurance Data",
      component: UploadData,
    },
    {
      path: "/insurance/list",
      name: "View Insurance Data",
      component: InsuranceList,
    },
    {
      path: "/insurance/newInsurance",
      name: "Add Insurance Case",
      component: NewInsurance,
    },
    {
      path: "/insurance/dailyReport",
      name: "Daily Report",
      component: InsuranceDailyReport,
    },
    {
      path: "/insurance/hireReport",
      name: "Hire Report",
      component: InsuranceHireReport,
    },
    {
      path: "/insurance/:id",
      name: null,
      component: ViewInsurance,
    },
  ],
};

const workProviderRoutes = {
  path: "/workProvider",
  name: "Work Provider",
  icon: faBriefcase,
  children: [
    {
      path: "/workProvider/leadsource",
      name: "Lead Source",
      component: LeadSource,
    },
    {
      path: "/workProvider/leadsource/add",
      name: null,
      component: AddLeadSource,
    },
    {
      path: "/workProvider/leadsource/:id/edit",
      name: null,
      component: EditLeadSource,
    },
    {
      path: "/workProvider/sourcecases",
      name: "Source Cases List",
      component: LeadCases,
    },
    {
      path: "/workProvider/sourceCase/:id/edit",
      name: null,
      component: EditLeadCase,
    },
    {
      path: "/workProvider/salesReport",
      name: "Sales Report",
      component: SalesReport,
    },
  ],
};

const financialAnalysisRoutes = {
  path: "/financialAnalysis",
  name: "Financial Analysis",
  icon: faChartSimple,
  children: [
    {
      path: "/financialAnalysis/financialReview",
      name: "Financial Review",
      component: FinancialReview,
    },
    {
      path: "/financialAnalysis/financialAnalysisBDM",
      name: "Financial Analysis BDM",
      component: FinancialAnalysisBDM,
    },
  ],
};

const paymentReportsRoutes = {
  path: "/paymentReports",
  name: "Payment Reports",
  icon: faMoneyBill,
  children: [
    {
      path: "/paymentReports/accessoryReport",
      name: "Accessory Payment",
      component: PaymentAccessoryReport,
    },
    {
      path: "/paymentReports/notPaidReport",
      name: "Not Paid Cases",
      component: NotPaidReport,
    },
    {
      path: "/paymentReports/paymentReport",
      name: "Payment Report",
      component: PaymentReport,
    },
    {
      path: "/paymentReports/paymentAuthorisationReport",
      name: "Payment Authorisation",
      component: PaymentAuthorisationReport,
    },
    {
      path: "/paymentReports/leadSourceInfo/:id",
      name: null,
      component: LeadSourceInformation,
    },
  ]
};

const progressReportsRoutes = {
  path: "/progressReports",
  name: "Progress Reports",
  icon: faBarsProgress,
  children: [
    {
      path: "/progressReports/solicitorReport",
      name: "Solicitor Progress",
      component: SolicitorReport,
    },
    {
      path: "/progressReports/hireReport",
      name: "Hire Progress",
      component: HireProgressReport,
    },
  ]
};

const setupRoutes = {
  path: "/setup",
  name: "Setup",
  icon: faSliders,
  children: [
    {
      path: "/setup/templates",
      name: "Accident Templates",
      component: AccidentTemplates,
    },
    {
      path: "/setup/templates/add",
      name: null,
      component: AddAccidentTemplate,
    },
    {
      path: "/setup/templates/:id",
      name: null,
      component: EditAccidentTemplate,
    },
    // -----city------
    {
      path: "/setup/cities",
      name: "Cities",
      component: Cities,
    },
    {
      path: "/setup/cities/addCity",
      name: null,
      component: AddCity,
    },
    {
      path: "/setup/cities/:id/edit",
      name: null,
      component: EditCity,
    },
    // -----borough------
    {
      path: "/setup/boroughs",
      name: "Boroughs",
      component: Boroughs,
    },
    {
      path: "/setup/boroughs/addBorough",
      name: null,
      component: AddBorough,
    },
    {
      path: "/setup/boroughs/:id/edit",
      name: null,
      component: EditBorough,
    },
    // -----language------
    {
      path: "/setup/languages",
      name: "Languages",
      component: Languages,
    },
    {
      path: "/setup/languages/addLanguage",
      name: null,
      component: AddLanguage,
    },
    {
      path: "/setup/languages/:id/edit",
      name: null,
      component: EditLanguage,
    },
  ],
};

const authRoutes = {
  path: "/auth",
  name: "Auth",
  icon: faSignInAlt,
  children: [
    {
      path: "/auth/login",
      name: "Login",
      component: Login,
    },
    {
      path: "/auth/account-recovery",
      name: "Account Recovery",
      component: AccountRecovery,
    },
    {
      path: "/auth/reset-password",
      name: "Reset Password",
      component: ResetPassword,
    },
    {
      path: "/auth/404",
      name: "404 Page",
      component: Page404,
    },
    {
      path: "/auth/500",
      name: "500 Page",
      component: Page500,
    },
  ],
};

// Dashboard specific routes
export const dashboard = [
  dashboardRoute,
  setupRoutes,
  profileRoute,
  securityRoutes,
  administrationRoutes,
  insuranceRoutes,
  reportsRoutes,
  workProviderRoutes,
  financialAnalysisRoutes,
  paymentReportsRoutes,
  progressReportsRoutes,
];

// Auth specific routes
export const page = [authRoutes];

// All routes
export default [dashboardRoute, setupRoutes, securityRoutes, administrationRoutes, insuranceRoutes, reportsRoutes, workProviderRoutes, financialAnalysisRoutes, paymentReportsRoutes, progressReportsRoutes];
