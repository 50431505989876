import React, { useEffect, useState } from "react";
import { Spinner, Table } from "reactstrap";
import { Link } from "react-router-dom";
import PaginationWithState from "../../UI/PaginationWithState";
import useError from "../../../hooks/useError";
import instance from "../../../axiosInstance";
import TableFeedback from "../../UI/TableFeedback";

const PendingPanelTable = ({ year, month, fileHandler }) => {
  const [paginationInfo, setPaginationInfo] = useState({
    currentPage: 1,
    totalPages: 0,
    totalRecords: 0,
    limit: 0,
  });
  const [page, setPage] = useState(1);
  const { handleError } = useError();
  const [loading, setLoading] = useState(true);

  const [newCases, setNewCases] = useState([]);

  const getNewCases = async () => {
    const date = new Date();
    const startMonth = month ? String(month.value).padStart(2, "0") : "01";
    const lastMonth = month ? String(month.value).padStart(2, "0") : "12";
    const firstDate = `${year?.value || date.getFullYear()}-${startMonth}-01`;
    const lastDate = `${year?.value || date.getFullYear()}-${lastMonth}-31`;

    try {
      setLoading(true);
      const res = await instance.get("/report/pi", {
        params: {
          page,
          limit: 5,
          pending_panel: 1,
          from_date: firstDate,
          to_date: lastDate,
          file_handler_id: fileHandler?.value || "",
        },
      });

      const data = res.data.data;

      setNewCases(data.data);

      setPaginationInfo({
        currentPage: data.page,
        totalPages: data.total_pages,
        totalRecords: data.total_records,
        limit: data.limit,
      });
    } catch (error) {
      console.log(error);
      handleError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getNewCases();
  }, [page, year, month, fileHandler]);

  return (
    <>
      <Table responsive>
        <thead>
          <tr>
            <th scope="col">Sr.</th>
            <th scope="col">ID</th>
            <th scope="col">Client Name</th>
            <th scope="col">Reference No</th>
            <th scope="col">Status</th>
          </tr>
        </thead>
        <tbody>
          {loading && (
            <div className="d-flex justify-content-center align-items-center position-relative">
              <div className="position-absolute" style={{ top: 92, left: 200 }}>
                <Spinner color="primary" />
              </div>
            </div>
          )}
          {newCases.length === 0 && !loading && <TableFeedback message="No Cases Found!" colSpan="5" />}
          {newCases.map((item) => (
            <tr key={item.case_id}>
              <td>{item.sr}</td>
              <td>
                <Link className="custom-link-underline" to={`/administration/cases/${item.case_id}/edit`}>
                  {item.case_id}
                </Link>
              </td>
              <td>{item.client_name || "-"}</td>
              <td>{item.solicitor_reference || "-"}</td>
              <td>
                <div className="d-flex align-items-center">
                  <span className="blink" />
                  <span>Pending</span>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <PaginationWithState paginationInfo={paginationInfo} page={page} setPage={setPage} hideInfo />
    </>
  );
};

export default PendingPanelTable;
