import React, { memo, useEffect, useState } from "react";
import Select from "react-select";
import { Card, CardBody, CardTitle, Col, Input, Label, ListGroup, ListGroupItem, Modal, ModalBody, ModalHeader, Row, Spinner, Table, UncontrolledTooltip } from "reactstrap";
import PaginationWithState from "../UI/PaginationWithState";
import useError from "../../hooks/useError";
import instance from "../../axiosInstance";
import { useDebounce } from "use-debounce";
import { NotesItem } from "./Notification";
import { toYYYYMMDD } from "../../utils/forms";

const itemsOptions = [
  {
    value: 10,
    label: 10,
  },
  {
    value: 25,
    label: 25,
  },
  {
    value: 50,
    label: 50,
  },
  {
    value: 100,
    label: 100,
  },
];

const Cases = memo(() => {
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [cases, setCases] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm] = useDebounce(searchTerm, 500);
  const [isLoading, setIsLoading] = useState(false);
  const [limit, setLimit] = useState(10);

  const { handleError } = useError();

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const toggleModal = () => setModalIsOpen(!modalIsOpen);
  const [notes, setNotes] = useState(null);
  const [isNotesFetching, setIsNotesFetching] = useState(false);

  const getNotes = async (id) => {
    try {
      setIsNotesFetching(true);
      const { data } = await instance.get(`/dashboard/deoCaseNotes/${id}`);
      console.log(data.data);
      setNotes(data.data);
    } catch (error) {
      handleError(error);
    } finally {
      setIsNotesFetching(false);
    }
  };

  const [paginationInfo, setPaginationInfo] = useState({
    currentPage: 1,
    totalPages: 0,
    totalRecords: 0,
    limit: 0,
  });
  const [page, setPage] = useState(1);

  const getCases = async () => {
    try {
      setIsLoading(true);
      const { data } = await instance.get("/case/lists", {
        params: {
          page,
          limit,
          search: searchTerm,
        },
      });
      setCases(data.data.data);
      setPaginationInfo({
        currentPage: data.data.page,
        totalPages: data.data.total_pages,
        totalRecords: data.data.total_records,
        limit: data.data.limit,
      });
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getCases();
  }, [page, debouncedSearchTerm, limit]);

  return (
    <Card>
      <CardBody>
        <CardTitle tag="h5">Cases</CardTitle>

        <Row>
          <Col sm="4">
            <Input type="text" placeholder="Search..." value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
          </Col>
          <Col sm="8" className="d-flex justify-content-end">
            <div className="w-86">
              <Select className="basic-single" classNamePrefix="select" options={itemsOptions} value={{ value: limit, label: limit }} onChange={(selectedOption) => setLimit(selectedOption.value)} />
            </div>
          </Col>
        </Row>

        {/* <Row className="mt-2">
          <Col sm="4">
            <Label>From Date</Label>
            <CustomDate startDate={fromDate} setStartDate={setFromDate} />
          </Col>
          <Col sm="4">
            <Label>To Date</Label>
            <CustomDate startDate={toDate} setStartDate={setToDate} />
          </Col>
          <Col sm="4">
            <Label>Filter By Source</Label>
            <Select className="basic-single" classNamePrefix="select" options={itemsOptions} value={{ value: 10, label: 10 }} />
          </Col>
        </Row> */}

        <ListGroup flush className="mt-2">
          {cases.map((caseItem) => (
            <ListGroupItem
              id={`view-${caseItem.case_id}-case`}
              key={caseItem.case_id}
              className="cursor-pointer"
              action
              onClick={() => {
                getNotes(caseItem.case_id);
                setModalIsOpen(true);
              }}
            >
              <UncontrolledTooltip target={`view-${caseItem.case_id}-case`}>View</UncontrolledTooltip>
              <div className="d-flex align-items-center justify-content-between">
                <span>{caseItem.client_name}</span>
                <span className="text-muted">NASL: {caseItem.case_id}</span>
              </div>
            </ListGroupItem>
          ))}
        </ListGroup>

        {!isLoading && !cases.length ? (
          <div className="p-4 d-flex justify-content-center align-items-center h-full">
            <h4>No Cases Found</h4>
          </div>
        ) : null}
      </CardBody>
      <PaginationWithState paginationInfo={paginationInfo} page={page} setPage={setPage} hideInfo />

      <Modal isOpen={modalIsOpen} toggle={toggleModal} centered>
        <ModalHeader toggle={toggleModal}>Details</ModalHeader>
        <ModalBody>
          {isNotesFetching && (
            <div className="d-flex justify-content-center py-5">
              <Spinner color="primary" />
            </div>
          )}

          {notes && (
            <>
              <div className="d-flex justify-content-between">
                <h6>
                  NASL-{notes?.case_id} {notes?.title} {notes?.first_name} {notes?.middle_name} {notes?.last_name}
                </h6>

                <h6>{notes?.vehicle_registration_no}</h6>
              </div>

              <Table responsive>
                <thead>
                  <tr>
                    <th scope="col">Type</th>
                    <th scope="col">Status</th>
                    <th scope="col">Payment</th>
                    <th scope="col">Status</th>
                  </tr>
                </thead>

                <tbody>
                  {notes?.serviceStatus?.map((service) => (
                    <tr key={service.service_type}>
                      <td>{service.service_type}</td>
                      <td>{service.current_status}</td>
                      <td>{toYYYYMMDD(service.source_paid_date, true) || "-"}</td>
                      <td>{service.source_paid_status === "Yes" || service.source_paid_status === "1" ? "Paid" : "Unpaid"}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>

              <h6>Notes</h6>

              <ListGroup flush>
                {notes?.notes?.map((note) => (
                  <NotesItem key={note.case_note_id} description={note.description} updatedAt={note.updated_at} />
                ))}
              </ListGroup>
            </>
          )}
        </ModalBody>
      </Modal>
    </Card>
  );
});

export default Cases;
