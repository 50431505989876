import React from "react";
import { Button, Badge } from "reactstrap";

const FloatingActionButton = ({ count, children, onClick }) => {
  return (
    <Button color="primary" className="fixed bottom-20 right-20 rounded-circle p-2" style={{ zIndex: "999" }} onClick={onClick}>
      {children}
      {count > 0 && (
        <div className="absolute top--5 right--10">
          <Badge color="danger" pill>
            {count}
          </Badge>
        </div>
      )}
    </Button>
  );
};

export default FloatingActionButton;
