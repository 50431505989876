import React, { memo } from "react";
import { Card, CardBody, CardTitle, Col, ListGroup, Row } from "reactstrap";
import Notification from "./Notification";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-solid-svg-icons";

const Notifications = memo(({ notifications = [] }) => {
  return (
    <Card>
      <CardBody>
        <Row>
          <Col className="mt-0">
            <CardTitle tag="h5">Notifications</CardTitle>
          </Col>

          <Col className="col-auto">
            <div className="avatar">
              <div className="avatar-title rounded-circle bg-primary-dark">
                <FontAwesomeIcon icon={faBell} size="lg" />
              </div>
            </div>
          </Col>
        </Row>

        <div className="h-306 overflow-auto mt-2">
          {notifications.length === 0 && (
            <div className="p-4 d-flex justify-content-center align-items-center h-full">
              <h4>
                <FontAwesomeIcon icon={faBell} size="lg" /> No Notifications!
              </h4>
            </div>
          )}
          <ListGroup flush className="mr-2">
            {notifications.map((notification) => (
              <Notification
                key={notification.case_id}
                id={notification.case_id}
                title={notification.title}
                firstname={notification.first_name}
                middlename={notification.middle_name}
                lastname={notification.last_name}
              />
            ))}
          </ListGroup>
        </div>
      </CardBody>
    </Card>
  );
});

export default Notifications;
