import React, { createContext, useContext, useState } from "react";

export const roleContext = createContext({});
export const useRoleContext = () => useContext(roleContext);

const RoleContextProvider = ({ children }) => {
  const [permissionsGroup, setPermissionsGroup] = useState({});

  const values = {
    permissionsGroup,
    setPermissionsGroup,
  };

  return <roleContext.Provider value={values}>{children}</roleContext.Provider>;
};

export default RoleContextProvider;
