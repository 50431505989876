import { faArchive, faBell, faBriefcaseMedical, faCarSide, faRoute, faSearch, faUserFriends, faUserTie, faWrench } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Pie } from "react-chartjs-2";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { AsyncPaginate } from "react-select-async-paginate";
import { Card, CardBody, CardTitle, Col, Container, Row } from "reactstrap";
import instance from "../../axiosInstance";
import { useProfileContext } from "../../context/profile";
import useError from "../../hooks/useError";
import Header from "../UI/Header";
import HeaderSubtitle from "../UI/HeaderSubtitle";
import HeaderTitle from "../UI/HeaderTitle";
import { months, years } from "../UI/forms/CustomDate";
import PendingHireTable from "./Tables/PendingHireTable";
import PendingLeadSourceTable from "./Tables/PendingLeadSourceTable";
import PendingLeadSourceTableForHire from "./Tables/PendingLeadSourceTableForHire";
import PendingPanelTable from "./Tables/PendingPanelTable";
import ReactApexChart from 'react-apexcharts';

export const loadFileHandlerOptions = async (search, loadedOptions, { page }) => {
  try {
    const res = await instance.get("/lookups/fileHandlers", {
      params: { search, page, limit: 10 },
    });

    const { data } = res.data;
    const options = data.data.map((role) => ({
      value: role.user_id,
      label: role.full_name,
    }));

    return {
      options,
      hasMore: page < data.total_pages,
      additional: {
        page: page + 1,
      },
    };
  } catch (error) {
    return {
      options: [],
      hasMore: false,
    };
  }
};

const CaseCard = ({ title, value, onClick, icon }) => {
  return (
    <Card className="cursor-pointer px-4 pt-2" onClick={onClick}>
      <div
        className="d-flex justify-content-center mb-2"
        style={
          {
            // marginRight: -4,
          }
        }
      >
        <FontAwesomeIcon icon={icon} size="lg" />
      </div>
      <CardTitle className="d-flex align-items-center justify-content-between">
        <span>{title}</span> <span>{value || 0}</span>
      </CardTitle>
    </Card>
  );
};

export const selectHideArrowStyle = {
  dropdownIndicator: (base) => ({
    ...base,
    display: "none",
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: "none",
  }),
};

const AdminDashboard = ({ theme }) => {
  const { handleError } = useError();
  const history = useHistory();
  const { profile } = useProfileContext();
  const [dashboardInfo, setDashboardInfo] = useState(null);
  const [chartOptions, setChartOptions] = useState({});
  const [chartSeries, setChartSeries] = useState([]);
  const date = new Date();

  const selectedYear = sessionStorage.getItem("admin-selectedYear");
  const selectedMonth = sessionStorage.getItem("admin-selectedMonth");
  const selectedFileHandler = sessionStorage.getItem("admin-selectedFileHandler");
  const [month, setMonth] = useState(selectedMonth ? { value: +selectedMonth, label: months[+selectedMonth - 1] } : { value: date.getMonth() + 1, label: months[date.getMonth()] });
  const [year, setYear] = useState(selectedYear ? { value: selectedYear, label: selectedYear } : { value: date.getFullYear(), label: date.getFullYear() });
  const [fileHandler, setFileHandler] = useState(selectedFileHandler ? JSON.parse(selectedFileHandler) : null);

  const getTotal = () => {
    let total = 0;

    total += dashboardInfo?.data_total?.total_pi ? +dashboardInfo?.data_total?.total_pi : 0;
    total += dashboardInfo?.data_total?.total_hire ? +dashboardInfo?.data_total?.total_hire : 0;
    total += dashboardInfo?.data_total?.total_repair ? +dashboardInfo?.data_total?.total_repair : 0;
    total += dashboardInfo?.data_total?.total_storage ? +dashboardInfo?.data_total?.total_storage : 0;
    total += dashboardInfo?.data_total?.total_recovery ? +dashboardInfo?.data_total?.total_recovery : 0;
    total += dashboardInfo?.data_total?.total_inspection ? +dashboardInfo?.data_total?.total_inspection : 0;

    return total;
  };

  const data = {
    labels: dashboardInfo?.data_current?.map((d) => d.label) || ["Generated", "Rejected", "Transfers", "Pack", "Bikes"],
    datasets: [
      {
        data: dashboardInfo?.data_current?.map((d) => d.total) || [],
        backgroundColor: ["#153d77", theme.primary, theme.warning, theme.danger, "#E8EAED"],
        borderColor: "transparent",
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    legend: {
      position: "left",
      labels: {
        usePointStyle: true,
      },
    },
    tooltips: {
      intersect: false,
    },
  };

  useEffect(() => {
    (async function () {
      try {
        const { data } = await instance.get("/dashboard/admin", {
          params: {
            current_year: year?.value || null,
            current_month: month?.value || null,
            file_handler_id: fileHandler?.value || "",
          },
        });
        setDashboardInfo(data.data);
      } catch (error) {
        handleError(error);
      }
    })();
  }, [fileHandler?.value, month?.value, year?.value]);

  const handleCaseClick = (caseName) => {
    const startMonth = month ? String(month.value).padStart(2, "0") : "01";
    const lastMonth = month ? String(month.value).padStart(2, "0") : "12";
    const firstDate = `${year?.value || date.getFullYear()}-${startMonth}-01`;
    const lastDate = `${year?.value || date.getFullYear()}-${lastMonth}-31`;

    history.push(`/reports/${caseName}?first_date=${firstDate}&last_date=${lastDate}${fileHandler ? `&file_handler=${JSON.stringify([fileHandler.value, fileHandler.label])}` : ""}`);
  };


 
  useEffect(() => {
    if (dashboardInfo) {
        const colors = ['#153d77', theme.primary, theme.warning, theme.danger, '#02580F', '#58024C', '#34495e'];

        const options = {
            chart: {
                type: 'bar',
                height: 200
            },
            plotOptions: {
                bar: {
                    horizontal: true,
                    distributed: true, // This ensures that the colors array is used
                }
            },
            colors: colors,
            dataLabels: {
                enabled: false
            },
            xaxis: {
                categories: [
                    'PI', 'Hire', 'Repair', 'Storage', 'Recovery', 'Inspection', 'Outlay'
                ]
            }
        };

        const series = [{
            name: 'Paid Cases',
            data: [
                dashboardInfo?.total_today?.total_pi_today || 0,
                dashboardInfo?.total_today?.total_hire_today || 0,
                dashboardInfo?.total_today?.total_repair_today || 0,
                dashboardInfo?.total_today?.total_storage_today || 0,
                dashboardInfo?.total_today?.total_recovery_today || 0,
                dashboardInfo?.total_today?.total_inspection_today || 0,
                dashboardInfo?.total_today?.total_outlay_today || 0
            ]
        }];

        setChartOptions(options);
        setChartSeries(series);
    }
}, [dashboardInfo]);


  return (
    <Container fluid>
      <Header>
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <HeaderTitle>Welcome, {profile?.full_name}</HeaderTitle>
            <HeaderSubtitle>
              <span className="fs-18">Total Cases:</span> <span className="fs-22">{getTotal()}</span>
            </HeaderSubtitle>
          </div>
          <div className="d-flex">
            <AsyncPaginate
              className="basic-single w-180 mr-3"
              classNamePrefix="select"
              isSearchable={true}
              placeholder="File Handler"
              name="fileHandler"
              id="fileHandler"
              loadOptions={loadFileHandlerOptions}
              value={fileHandler}
              isClearable
              additional={{
                page: 1,
              }}
              debounceTimeout={300}
              onChange={(selected) => {
                setFileHandler(selected);
                selected ? sessionStorage.setItem("admin-selectedFileHandler", JSON.stringify(selected)) : sessionStorage.removeItem("admin-selectedFileHandler");
              }}
            />
            <Select
              className="basic-single w-120 mr-3"
              classNamePrefix="select"
              placeholder="Month"
              isClearable
              styles={selectHideArrowStyle}
              value={month}
              onChange={(selectedOption) => {
                setMonth(selectedOption);
                selectedOption ? sessionStorage.setItem("admin-selectedMonth", selectedOption.value) : sessionStorage.removeItem("admin-selectedMonth");
              }}
              options={months.map((month, index) => ({ value: index + 1, label: month }))}
            />
            <Select
              className="basic-single w-120"
              classNamePrefix="select"
              placeholder="Year"
              styles={selectHideArrowStyle}
              value={year}
              onChange={(selectedOption) => {
                setYear(selectedOption);
                selectedOption ? sessionStorage.setItem("admin-selectedYear", selectedOption.value) : sessionStorage.removeItem("admin-selectedYear");
              }}
              options={years.map((year) => ({ value: year, label: year }))}
            />
          </div>
        </div>
      </Header>

      <div className="w-100">
        <Row>
          <Col sm="2">
            <CaseCard title="PI" value={dashboardInfo?.data_total?.total_pi} onClick={() => handleCaseClick("PIreport")} icon={faBriefcaseMedical} />
          </Col>
          <Col sm="2">
            <CaseCard title="Hire" value={dashboardInfo?.data_total?.total_hire} onClick={() => handleCaseClick("hireReport")} icon={faCarSide} />
          </Col>
          <Col sm="2">
            <CaseCard title="Repair" value={dashboardInfo?.data_total?.total_repair} onClick={() => handleCaseClick("repairReport")} icon={faWrench} />
          </Col>
          <Col sm="2">
            <CaseCard title="Storage" value={dashboardInfo?.data_total?.total_storage} onClick={() => handleCaseClick("storageReport")} icon={faArchive} />
          </Col>
          <Col sm="2">
            <CaseCard title="Recovery" value={dashboardInfo?.data_total?.total_recovery} onClick={() => handleCaseClick("recoveryReport")} icon={faRoute} />
          </Col>
          <Col sm="2">
            <CaseCard title="Inspection" value={dashboardInfo?.data_total?.total_inspection} icon={faSearch} />
          </Col>
        </Row>
      </div>

      <div className="w-100">
        <Card>
          <CardBody>
            <h4 className="fs-22">Daily Statistics</h4>
            <Row className="align-items-center">
              <Col sm="6">
                <div>
                  <Pie data={data} options={options} />
                </div>
              </Col>
              <Col sm="6">
                <div>
                  <ReactApexChart
                    options={chartOptions}
                    series={chartSeries}
                    type="bar"
                    height={200}
                  />
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>

      <div className="w-100">
        <Card>
          <CardBody>
            <div className="d-flex justify-content-between align-items-center pb-2">
              <h4 className="fs-22">Action required for PI cases. </h4>
              <FontAwesomeIcon icon={faBell} size="2x" />
            </div>
            <Row>
              <Col sm="6">
                <Card className="border">
                  <CardBody>
                    <div className="d-flex align-items-center">
                      <h5 className="my-2 mr-2">Pending Lead Source</h5>
                      <FontAwesomeIcon icon={faUserTie} size="lg" />
                    </div>
                    <PendingLeadSourceTable year={year} month={month} fileHandler={fileHandler} />
                  </CardBody>
                </Card>
              </Col>
              <Col sm="6">
                <Card className="border">
                  <CardBody>
                    <div className="d-flex align-items-center">
                      <h5 className="my-2 mr-2">Pending Panel</h5>
                      <FontAwesomeIcon icon={faUserFriends} size="lg" />
                    </div>
                    <PendingPanelTable year={year} month={month} fileHandler={fileHandler} />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>

      <div className="w-100">
        <Card>
          <CardBody>
            <div className="d-flex justify-content-between align-items-center pb-2">
              <h4 className="fs-22">Action required for Hire cases.</h4>
              <FontAwesomeIcon icon={faBell} size="2x" />
            </div>
            <Row>
              <Col sm="6">
                <Card className="border">
                  <CardBody>
                    <div className="d-flex align-items-center">
                      <h5 className="my-2 mr-2">Pending Lead Source</h5>
                      <FontAwesomeIcon icon={faUserTie} size="lg" />
                    </div>
                    <PendingLeadSourceTableForHire year={year} month={month} fileHandler={fileHandler} />
                  </CardBody>
                </Card>
              </Col>
              <Col sm="6">
                <Card className="border">
                  <CardBody>
                    <div className="d-flex align-items-center">
                      <h5 className="my-2 mr-2">Pending Hire</h5>
                      <FontAwesomeIcon icon={faCarSide} size="lg" />
                    </div>
                    <PendingHireTable year={year} month={month} fileHandler={fileHandler} />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    </Container>
  );
};

export default connect((store) => ({
  theme: store.theme.currentTheme,
}))(AdminDashboard);
